import './StandardLoader.scss';

import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

const StandardLoader = () => (
    <div className="admin-standard-loader">
        <FadeLoader color={'#3e4555'} sizeUnit={'px'} size={150} loading={true} />
    </div>
);

export default StandardLoader;
