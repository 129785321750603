export const isValueOrderId = value => {
    if (!value || value.length < 9 || !doesStringOnlyContainNumbers(value)) {
        return false;
    }
    return true;
};

export const doesStringOnlyContainNumbers = value => {
    if (typeof value !== 'string') {
        return false;
    }

    if (value.indexOf('.') >= 0 || value.indexOf('e') >= 0 || value.indexOf('-') >= 0) {
        return false;
    }

    return !isNaN(hasOnlyDigits(value)) && isFinite(value);
};

const hasOnlyDigits = val => {
    return /^\d+$/.test(val);
};
