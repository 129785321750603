import { Container } from '@material-ui/core';
import React, { useCallback, useEffect, useReducer, useState } from 'react';

import StandardLoader from '../../layouts/admin/StandardLoader';
import { getDevices } from '../../services/device.service';
import DevicesView from './components/DevicesView';
import { useStyles } from './Devices.style';

const defaultState = {
    loading: false,
    devices: [],
};

const devicesReducer = (state = defaultState, { type, payload }) => {
    switch (type) {
        case 'FETCH_DEVICES_REQUEST':
            return { ...state, loading: true };
        case 'FETCH_DEVICES_SUCCESS':
            return { ...state, devices: payload, loading: false };
        case 'FETCH_DEVICES_FAILURE':
            return { ...state, devices: [], loading: false };
        default:
            return state;
    }
};

const Devices = () => {
    const classes = useStyles();

    const [state, dispatch] = useReducer(devicesReducer, defaultState);
    const { loading, devices } = state;
    const [isSubscribed, setIsSubscribed] = useState(false);

    const fetchDevices = useCallback(() => {
        dispatch({ type: 'FETCH_DEVICES_REQUEST' });
        getDevices()
            .then(response => {
                if (!isSubscribed) {
                    return;
                }
                dispatch({
                    type: 'FETCH_DEVICES_SUCCESS',
                    payload: response,
                });
            })
            .catch(e => {
                console.log(e);
                if (!isSubscribed) {
                    return;
                }
                dispatch({ type: 'FETCH_DEVICES_FAILURE' });
            });
    }, [isSubscribed]);

    useEffect(() => {
        setIsSubscribed(true);
        fetchDevices();
        return () => {
            setIsSubscribed(false);
        };
    }, [fetchDevices]);

    return (
        <Container maxWidth="xl" className={classes.container}>
            {loading ? (
                <StandardLoader />
            ) : (
                <DevicesView devices={devices} onRefresh={fetchDevices} />
            )}
        </Container>
    );
};

export default Devices;
