import './DeliveryPageItem.scss';

import getSymbolFromCurrency from 'currency-symbol-map';
import React, { Component, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';

import reasonCodes from '../../../data/reasonCodes';
import refreshIcon from '../../../icons/Refresh.png';
import { getUserPermissions } from '../../../utils';
import { formatDateShort } from '../../../utils/time';
import { calculateRemainingDeliveryRefundTotal } from './calculations';

class DeliveryPageItem extends Component {
    state = {
        deliveryRefunds: [],
    };

    componentDidMount() {
        const { order } = this.props;

        if (order.refunds) {
            const deliveryRefunds = this.getOrderDeliveryRefunds();
            this.setDeliveryRefundsState(deliveryRefunds);
        }
    }

    setDeliveryRefundsState(subject) {
        const deliveryRefunds = [...subject];
        this.setState({ deliveryRefunds });
    }

    getOrderDeliveryRefunds() {
        const {
            order: { items, refunds },
        } = this.props;
        // get delivery refunds from parent order
        const parentOrderRefunds = refunds.filter(({ type }) => type === 'partialdelivery');
        // get delivery refunds from facia orders
        const faciaOrderRefunds = Object.values(items)
            .map(({ delivery }) => delivery)
            // filter out any without a delivery refund
            .filter(Boolean)
            .map(({ refund }) => refund)
            // filter out any duplicates from parentOrderRefunds
            .filter(
                ({ createdAt }) => !parentOrderRefunds.some(({ created }) => created === createdAt),
            )
            // map createdAt to created to match parentOrderRefunds
            .map(({ createdAt: created, type = 'partialdelivery', ...rest }) => ({
                created,
                type,
                ...rest,
            }));
        return [...parentOrderRefunds, ...faciaOrderRefunds];
    }

    isDeliveryRefundDisabled() {
        const { order, newDeliveryRefund, user } = this.props;

        // If there's no cost remaining
        if (newDeliveryRefund === '0.00') {
            return true;
        }

        // If the user cannot refund orders
        const { canRefundOrders } = getUserPermissions(user);
        if (!canRefundOrders) {
            return true;
        }

        // If all of the facia order statuses are rejected
        return Object.values(order.items).every(({ status }) => status === 'rejected');
    }

    displayDeliveryRefundType(type) {
        let result = null;

        switch (type) {
            case 'partialdelivery':
                result = 'Partial';
                break;
            default:
                break;
        }

        return result;
    }

    render() {
        const { deliveryRefunds } = this.state;
        const {
            setDeliveryRefundReason,
            onDeliveryRefundRefresh,
            setNewDeliveryRefund,
            deliveryRefundReason,
            newDeliveryRefund,
            order,
            validateAmendments,
        } = this.props;

        const isDeliveryRefundDisabled = this.isDeliveryRefundDisabled();

        return (
            <Fragment>
                <div className="row order-item-header">
                    <div className="col-md-12">
                        <p>
                            <span className="lead order-title">Delivery Refunds</span>
                        </p>
                    </div>
                </div>
                <div className="row order-item-table delivery-page-item">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>Amount</td>
                                    <td>Remaining</td>
                                    <td>Type</td>
                                    <td>Performed by</td>
                                    <td>Reason</td>
                                    <td>When</td>
                                </tr>
                            </thead>
                            <tbody>
                                {deliveryRefunds.map(
                                    ({ reasonCode, created, price, type, user = {} }, index) => {
                                        let reasonDesc;
                                        const tmp = reasonCodes.filter(
                                            ({ code }) => reasonCode === code,
                                        );
                                        if (tmp.length) {
                                            reasonDesc = tmp[0].description;
                                        }
                                        const deliveryType = this.displayDeliveryRefundType(type);
                                        const remainingTotal =
                                            calculateRemainingDeliveryRefundTotal(index, order);
                                        const { firstName, lastName } = user;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <CurrencyFormat
                                                        className=""
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        thousandSeparator={true}
                                                        displayType="text"
                                                        value={price / 100}
                                                        prefix={getSymbolFromCurrency(
                                                            order.totals.currency || 'GBP',
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    <CurrencyFormat
                                                        className=""
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        thousandSeparator={true}
                                                        displayType="text"
                                                        value={remainingTotal / 100}
                                                        prefix={getSymbolFromCurrency(
                                                            order.totals.currency || 'GBP',
                                                        )}
                                                    />
                                                </td>
                                                <td>{deliveryType}</td>
                                                <td>{`${firstName || ''} ${lastName || ''}`}</td>
                                                <td>{reasonDesc}</td>
                                                <td>{formatDateShort(created)}</td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colSpan={6}>
                                        <div className="form-group row">
                                            <label htmlFor="" className="col-sm-2 col-form-label">
                                                Issue partial:
                                            </label>
                                            <div className="col-sm-auto">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text"
                                                            id="basic-addon1"
                                                        >
                                                            £
                                                        </span>
                                                    </div>
                                                    <input
                                                        disabled={isDeliveryRefundDisabled}
                                                        onChange={e =>
                                                            setNewDeliveryRefund(e.target.value)
                                                        }
                                                        onBlur={() => validateAmendments()}
                                                        value={
                                                            isDeliveryRefundDisabled
                                                                ? '0.00'
                                                                : newDeliveryRefund
                                                        }
                                                        className="form-control new-delivery-refund-input"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="input-group">
                                                    <select
                                                        disabled={isDeliveryRefundDisabled}
                                                        onChange={e =>
                                                            setDeliveryRefundReason(e.target.value)
                                                        }
                                                        value={deliveryRefundReason}
                                                        className="form-control delivery-refund-reason-input"
                                                        name="refund_reason"
                                                    >
                                                        <option value="">
                                                            Reason to refund delivery
                                                        </option>
                                                        {reasonCodes.map(
                                                            ({ code, description }) => {
                                                                if (code === '06') {
                                                                    return null;
                                                                }

                                                                return (
                                                                    <option key={code}>
                                                                        {description}
                                                                    </option>
                                                                );
                                                            },
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="input-group">
                                                    <button
                                                        className="btn btn-default refresh-icon-container"
                                                        onClick={onDeliveryRefundRefresh}
                                                    >
                                                        <img src={refreshIcon} alt="Clear search" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default DeliveryPageItem;
