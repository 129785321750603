import deliveryGreen from './icons/menu/delivery-green.png';
import delivery from './icons/menu/delivery.png';
import devicesGreen from './icons/menu/devices-green.png';
import devices from './icons/menu/devices.png';
import homeGreen from './icons/menu/home-green.svg';
import home from './icons/menu/home.png';
import orderGreen from './icons/menu/order-green.png';
import order from './icons/menu/order.png';
import searchGreen from './icons/menu/search-green.png';
import search from './icons/menu/search.png';
import usersGreen from './icons/menu/users-green.svg';
import users from './icons/menu/users.png';

export const menuItems = [
    {
        title: 'Home',
        url: '/',
        menuIcon: home,
        dashboardIcon: homeGreen,
    },
    {
        icon: 'mail',
        title: 'Orders and Returns',
        url: '/orders',
        permission: 'orders--list',
        menuIcon: order,
        dashboardIcon: orderGreen,
    },
    {
        title: 'Search Orders',
        url: '/orders/search',
        permission: 'orders--list',
        menuIcon: search,
        dashboardIcon: searchGreen,
    },
    {
        title: 'Delivery Methods',
        url: '/delivery',
        permission: 'deliveryoptions--list',
        menuIcon: delivery,
        dashboardIcon: deliveryGreen,
    },
    {
        title: 'Payment Methods',
        url: '/payment',
        permission: 'paymentoptions--list',
    },
    {
        title: 'Users',
        url: '/users',
        permission: 'users--list',
        menuIcon: users,
        dashboardIcon: usersGreen,
    },
    {
        title: 'Devices',
        url: '/devices',
        permission: 'devices--list',
        menuIcon: devices,
        dashboardIcon: devicesGreen,
    },
    {
        icon: 'person',
        title: 'Logout',
        url: '/logout',
        permission: 'users--list',
    },
];
