import authInstance from './services/Auth/authorization';

export function encodeQueryData(data) {
    const ret = [];
    for (let d in data) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.length > 0 ? '?' + ret.join('&') : '';
}

export function encodeDateQuery(name, startDate, endDate) {
    const params = [];
    if (null !== startDate && null !== endDate) {
        params[name + '[bt]'] = startDate.unix() + '-' + endDate.unix();
    } else if (null !== startDate && null === endDate) {
        params[name + '[gt]'] = startDate.unix();
    } else if (null === startDate && null !== endDate) {
        params[name + '[lt]'] = endDate.unix();
    }
    return params;
}

export function getEnv() {
    if (process.env.REACT_APP_STAGE === 'production') {
        return 'prod';
    }
    if (process.env.REACT_APP_STAGE === 'staging') {
        return 'staging';
    }
    return 'develop';
}

export function getMicroservicesDomain() {
    switch (getEnv()) {
        case 'prod':
            return 'https://admin.api.jdplcapp.com';
        case 'staging':
            return 'https://uat.admin.api.jdplcapp.com';
        case 'develop':
        default:
            return 'https://dev.admin.api.jdplcapp.com';
    }
}

/**
 * Get all values from a nested object
 */
export const getAllNestedValues = obj => {
    if (!obj) {
        return [];
    }
    let result = [];
    for (let val of Object.values(obj)) {
        if (typeof val === 'object') {
            result.push(...getAllNestedValues(val));
        } else {
            result.push(val);
        }
    }
    return result;
};

export const handleUnauthorizedError = error => {
    const is401Error =
        error && error.message && error.message === 'Request failed with status code 401';
    if (is401Error) {
        authInstance.logout();
        window.location.href = '/';
    }
};

export const handleNotFoundError = error => {
    const is404Error =
        error && error.message && error.message === 'Request failed with status code 404';
    if (is404Error) {
        throw new Error('Resource not found');
    }
};

export const getUserPermissions = user => {
    const { permissions } = user;
    return {
        canCreateDeliveryOptions: permissions.includes('deliveryoptions--create'),
        canCreateDevices: permissions.includes('devices--add'),
        canCreateUsers: permissions.includes('users--create'),
        canDeleteDeliveryOptions: permissions.includes('deliveryoptions--delete'),
        canDeleteDevices: permissions.includes('devices--delete'),
        canDeleteUsers: permissions.includes('users--delete'),
        canExchangeOrders: permissions.includes('orders--exchange'),
        canListDeliveryOptions: permissions.includes('deliveryoptions--list'),
        canListDevices: permissions.includes('devices--list'),
        canListOrders: permissions.includes('orders--list'),
        canListUsers: permissions.includes('users--list'),
        canRefundOrders: permissions.includes('orders--refund'),
        canReturnOrders: permissions.includes('orders--return'),
        canUpdateDeliveryOptions: permissions.includes('deliveryoptions--update'),
        canUpdateDevices: permissions.includes('devices--edit'),
        canUpdateUsers: permissions.includes('users--update'),
        canListPaymentOptions: permissions.includes('paymentoptions--list'),
        canUpdatePaymentOptions: permissions.includes('paymentoptions--update'),
        canEditSelf: permissions.includes('users--editSelf'),
    };
};

/**
 * Get a value from an nested object using a string
 *
 * @see https://stackoverflow.com/a/22129960
 *
 * @param {string} path
 * @param {*} obj
 * @param {*} separator The separator to split on
 *
 * @example
 * const myObject = { foo: { bar: 'baz' } }
 * resolve('foo.bar', myObject) // 'baz'
 * resolve('foo.bar.bill.ben', myObject) // undefined
 */
export function resolve(path, obj, separator = '.') {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

export const uniqueArray = arr => [...new Set(arr)];
export const alphabeticalSort = (a, b) => a.localeCompare(b);
export const numericalSort = (a, b) =>
    String(a).localeCompare(String(b), undefined, { numeric: true });
