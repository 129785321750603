import { Box } from '@material-ui/core';
import React from 'react';

const DevicesHeader = ({ leftItems, rightItems }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" mx={6} my={4}>
            <Box display="flex" alignItems="center">
                {leftItems}
            </Box>
            <Box>{rightItems}</Box>
        </Box>
    );
};

export default DevicesHeader;
