//@ts-check
import { FormControl, FormHelperText, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';

import { resolve } from '../../../utils';

const useStyles = makeStyles(() => ({
    label: {
        color: '#3e4555',
        fontFamily: 'Gotham',
        fontSize: 14,
        fontWeight: 'bold',
    },
    select: {
        marginTop: 2,
        fontFamily: 'Gotham SSm A',
        fontSize: 14,
        fontWeight: 400,
        color: '#3e4555',
    },
}));

const FormSelect = ({
    name,
    label,
    options,
    fullWidth = true,
    placeholder = 'Select an option',
    ...props
}) => {
    const classes = useStyles();
    const form = useFormikContext();

    const value = resolve(name, form.values);
    const hasBeenTouched = !!resolve(name, form.touched);
    const hasError = !!resolve(name, form.errors);

    const getHelperText = () => {
        if (hasBeenTouched && hasError) {
            return resolve(name, form.errors);
        }
        return ' ';
    };

    return (
        <FormControl variant="outlined" fullWidth={fullWidth} size="small">
            <label htmlFor={name} className={classes.label}>
                {label}
            </label>
            <Select
                className={classes.select}
                name={name}
                id={name}
                value={value}
                error={hasBeenTouched && hasError}
                onChange={e => {
                    e.persist();
                    form.handleChange(e);
                    form.setFieldTouched(name, true, false);
                }}
                {...props}
            >
                <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>
                {options.map(option => {
                    return (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
            <FormHelperText>{getHelperText()}</FormHelperText>
        </FormControl>
    );
};
export default FormSelect;
