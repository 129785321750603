import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@rmwc/button';
import { TextField, TextFieldHelperText as HelperText } from '@rmwc/textfield';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { authActions } from '../../redux/actions/auth';
import authInstance from '../../services/Auth/authorization';

const RequireNewPassword = props => {
    const [loginDetails, setLoginDetails] = useState({
        password: '',
    });

    const [errorMessage, setErrorMessage] = useState(false);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setLoginDetails({ ...loginDetails, [name]: value });
    };

    const change = event => {
        event.preventDefault();
        const user = props.currentUser;
        const { password } = loginDetails;

        authInstance
            .login(user.email, password, true, user.session)
            .then(async user => {
                props.setStateSignedIn(user);
            })
            .catch(err => setErrorMessage(err.message));
    };

    return (
        <>
            <div>
                {errorMessage && <MuiAlert severity="error">{errorMessage}</MuiAlert>}

                <div className="login-wrapper">
                    <img src="/images/logo-large-en.png" alt="JD Marketplace" />
                    <div className="login-paper">
                        <form className="login-form" onSubmit={change}>
                            <TextField
                                label="New Password"
                                helpText={<HelperText>Enter your password</HelperText>}
                                outlined={true}
                                autoFocus
                                name="password"
                                type="password"
                                onChange={handleInputChange}
                                value={loginDetails.password}
                            />
                            <div>
                                <label>Password Rules</label>
                                <ul>
                                    <li>Password minimum length - 16 characters</li>
                                    <li>Contains at least 1 number</li>
                                    <li>Contains at least 1 special character</li>
                                    <li>Contains at least 1 uppercase letter</li>
                                    <li>Contains at least 1 lowercase letter</li>
                                </ul>
                            </div>
                            <Button
                                type="submit"
                                raised
                                className="login-button mdc-theme--primary-bg mdc-theme--on-primary"
                            >
                                Sign in
                            </Button>
                        </form>
                        <div className="forgot-wrapper">
                            <button
                                className="link-button"
                                onClick={() => props.setStateNotSignedIn()}
                            >
                                Back to Sign In
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setStateNotSignedIn: () => dispatch(authActions.setStateNotSignedIn()),
        setStateSignedIn: user => dispatch(authActions.setStateSignedIn(user)),
        removeCurrentUser: user => dispatch(authActions.removeCurrentUser(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequireNewPassword);
