import React from 'react';

import microservices from '../../services/microservices';
import { handleUnauthorizedError } from '../../utils';
import { layoutActions } from './layouts';
import { orderConstants } from './types';

export const orderActions = {
    refundOrderDeliveryMethod,
    updateOrderItemAmendment,
    getOrderDeliveryOption,
    processOrderAmendment,
    setAmendingOrderItem,
    setAmendingOrder,
    changeVendor,
    addOrderNote,
    cancelOrder,
    orderAction,
    getOrder,
};

/************************************************
 * !! Fix in ver 2 !!
 * order.items[facia].lines used to be optional
 * depending on if an item has been amended
 * but the API changed and now lines is always
 * present.  Unfortunately this breaks everything
 * so because of time constraints have added
 * hack function to convert the response back to
 * previous API data structure.  ¯\_(ツ)_/¯
 ************************************************/
export function hackOrderReturn(order) {
    Object.values(order.items).forEach(faciaOrder => {
        faciaOrder.items.forEach(item => {
            let hasAmends = false;
            if (item.lines) {
                item.originalLines = item.lines;
                Object.values(item.lines).forEach(line => {
                    if (line.amends && line.amends.length > 0) {
                        hasAmends = true;
                    }
                });
                if (!hasAmends) {
                    delete item.lines;
                } else {
                    console.log(`Order ${order.ID} has amends (beware)`);
                }
            }
        });
    });
}

function getOrder(id) {
    return async dispatch => {
        dispatch(request(id));
        try {
            const response = await microservices.order.get(`/admin/${id}`, {
                optionalHeaders: { 'X-Cache-Enabled': 'false' },
            });
            const order = response.data;

            // TODO - remove this when we fix orders
            hackOrderReturn(order);

            dispatch(success(order));
        } catch (error) {
            handleUnauthorizedError(error);
            failure(error);
        }
    };

    function request(id) {
        return { type: orderConstants.GET_ORDER_REQUEST, id };
    }
    function success(order) {
        return { type: orderConstants.GET_ORDER_SUCCESS, order };
    }
    function failure(error) {
        return { type: orderConstants.GET_ORDER_FAIL, error };
    }
}

function changeVendor(vendor) {
    return { type: orderConstants.CHANGE_VENDOR, vendor };
}
function setAmendingOrder(amending) {
    return { type: orderConstants.SET_AMENDING_ORDER, amending };
}
function setAmendingOrderItem(item, amendType, value) {
    return {
        type: orderConstants.SET_AMENDING_ORDER_ITEM,
        item,
        amendType,
        value,
    };
}
function updateOrderItemAmendment(amendment) {
    return { type: orderConstants.UPDATE_ORDER_ITEM_AMENDMENT, amendment };
}

function processOrderAmendment(orderAmendment) {
    return async dispatch => {
        dispatch(request(orderAmendment));

        try {
            const response = await microservices.order.post(
                `/admin/${orderAmendment.groupOrderID}/suborder/${orderAmendment.vendorOrderID}/actions`,
                {
                    items: orderAmendment.items,
                    user: orderAmendment.user,
                },
            );

            dispatch(success(orderAmendment, response.data, dispatch));
        } catch (error) {
            handleUnauthorizedError(error);
            if (error.response && error.response.data && error.response.data.error) {
                failure(error.response.data.error, dispatch);
            } else if (error == 'Error: Request failed with status code 500') {
                failure('Something went wrong', dispatch);
            }
        }
    };

    function request(orderAmendment) {
        return {
            type: orderConstants.PROCESS_ORDER_AMENDMENT_REQUEST,
            orderAmendment,
        };
    }
    async function success(orderAmendment, response, dispatch) {
        // Refresh order if refund made ok.
        await dispatch(getOrder(orderAmendment.groupOrderID));
        dispatch(
            layoutActions.displaySnackbar({
                title: <strong>Success</strong>,
                body: 'Order has been amended',
                icon: 'check',
                timeout: 5000,
            }),
        );

        return {
            type: orderConstants.PROCESS_ORDER_AMENDMENT_SUCCESS,
            orderAmendment,
            response,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error amending order', error));
        return { type: orderConstants.PROCESS_ORDER_AMENDMENT_FAIL, error };
    }
}

function cancelOrder(groupOrderID) {
    return async dispatch => {
        dispatch(request(groupOrderID));
        try {
            const response = await microservices.order.post(`/admin/${groupOrderID}/cancel`, {});
            dispatch(success(groupOrderID, response.data, dispatch));
        } catch (error) {
            handleUnauthorizedError(error);
            if (error.response && error.response.data && error.response.data.error) {
                failure(error.response.data.error, dispatch);
            }
        }
    };
    function request(groupOrderID) {
        return {
            type: orderConstants.CANCEL_ORDER_REQUEST,
            groupOrderID,
        };
    }

    function success(groupOrderID, result, dispatch) {
        // Refresh order if order cancelled ok.
        dispatch(getOrder(groupOrderID));

        return {
            type: orderConstants.CANCEL_ORDER_SUCCESS,
            groupOrderID,
            result,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error amending order', error));
        return { type: orderConstants.CANCEL_ORDER_FAIL, error };
    }
}

function addOrderNote(orderAmendment) {
    return async dispatch => {
        dispatch(request(orderAmendment));
        try {
            const response = await microservices.order.post(
                `/admin/${orderAmendment.groupOrderID}/notes`,
                orderAmendment.note,
            );

            dispatch(success(orderAmendment, response.data, dispatch));
        } catch (error) {
            handleUnauthorizedError(error);
            if (error.response && error.response.data && error.response.data.error) {
                failure(error.response.data.error, dispatch);
            }
        }
    };

    function request(orderAmendment) {
        return {
            type: orderConstants.ADD_ORDER_NOTE_REQUEST,
            orderAmendment,
        };
    }
    function success(orderAmendment, response, dispatch) {
        dispatch(
            layoutActions.displaySnackbar({
                title: <strong>Success</strong>,
                body: 'Order note successfully created',
                icon: 'check',
                timeout: 5000,
            }),
        );

        return {
            type: orderConstants.ADD_ORDER_NOTE_SUCCESS,
            orderAmendment,
            response,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error amending order', error));
        return { type: orderConstants.ADD_ORDER_NOTE_FAIL, error };
    }
}

function refundOrderDeliveryMethod(orderAmendment) {
    return async dispatch => {
        dispatch(request(orderAmendment));
        try {
            const response = await microservices.order.put(
                `/admin/${orderAmendment.groupOrderID}`,
                {
                    action: 'deliveryrefund',
                    carrierCode: orderAmendment.carrierCode,
                    user: orderAmendment.user,
                },
            );
            dispatch(success(orderAmendment, response.data, dispatch));
        } catch (error) {
            handleUnauthorizedError(error);
            if (error.response && error.response.data && error.response.data.error) {
                failure(error.response.data.error, dispatch);
            }
        }
    };

    function request(orderAmendment) {
        return {
            type: orderConstants.REFUND_ORDER_DELIVERY_METHOD_REQUEST,
            orderAmendment,
        };
    }
    async function success(orderAmendment, response, dispatch) {
        // Refresh order if refund made ok.
        await dispatch(getOrder(orderAmendment.groupOrderID));
        dispatch(
            layoutActions.displaySnackbar({
                title: <strong>Success</strong>,
                body: 'Delivery refund successful',
                icon: 'check',
                timeout: 5000,
            }),
        );

        return {
            type: orderConstants.REFUND_ORDER_DELIVERY_METHOD_SUCCESS,
            orderAmendment,
            response,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error amending order', error));
        return {
            type: orderConstants.REFUND_ORDER_DELIVERY_METHOD_FAIL,
            error,
        };
    }
}

function getOrderDeliveryOption(orderAmendment) {
    return async dispatch => {
        dispatch(request(orderAmendment));
        try {
            const response = await microservices.order.get(
                `/deliveryoption/${orderAmendment.deliveryOptionID}`,
                {
                    action: 'deliveryrefund',
                    carrierCode: orderAmendment.carrierCode,
                    user: orderAmendment.user,
                },
                { optionalHeaders: { 'X-Cache-Enabled': 'false' } },
            );

            dispatch(success(orderAmendment, response.data, dispatch));
        } catch (error) {
            handleUnauthorizedError(error);
            if (error.response && error.response.data && error.response.data.error) {
                failure(error.response.data.error, dispatch);
            }
        }
    };

    function request(orderAmendment) {
        return {
            type: orderConstants.GET_ORDER_DELIVERY_OPTION_REQUEST,
            orderAmendment,
        };
    }
    async function success(orderAmendment, response) {
        // Refresh order if refund made ok.
        // await dispatch(getOrder(orderAmendment.groupOrderID));
        // dispatch(
        //     layoutActions.displaySnackbar({
        //         title: <strong>Success</strong>,
        //         body: 'Order note successfully created',
        //         icon: 'check',
        //         timeout: 5000
        //     })
        // );

        return {
            type: orderConstants.GET_ORDER_DELIVERY_OPTION_SUCCESS,
            orderAmendment,
            response,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error amending order', error));
        return {
            type: orderConstants.GET_ORDER_DELIVERY_OPTION_FAIL,
            error,
        };
    }
}

function orderAction(orderAmendment) {
    return async dispatch => {
        dispatch(request(orderAmendment));
        try {
            const body = {
                reasonCode: orderAmendment.reasonCode,
                action: orderAmendment.action,
                user: orderAmendment.user,
            };

            switch (orderAmendment.action) {
                case 'refundreturn':
                case 'refund':
                    body.deliveryOptionID = orderAmendment.deliveryOptionID;
                    break;
                case 'partialdeliveryrefund':
                    body.price = orderAmendment.price;
                    break;
                default:
                    return;
            }

            const response = await microservices.order.post(
                `/admin/schedule/${orderAmendment.groupOrderID}/actions`,
                body,
            );
            dispatch(success(orderAmendment, response.data, dispatch));
        } catch (error) {
            handleUnauthorizedError(error);
            if (error.response && error.response.data && error.response.data.error) {
                failure(error.response.data.error, dispatch);
            }
        }
    };

    function request(orderAmendment) {
        return {
            type: orderConstants.ORDER_ACTION_REQUEST,
            orderAmendment,
        };
    }
    async function success(orderAmendment, response, dispatch) {
        // Refresh order if refund made ok.
        await dispatch(getOrder(orderAmendment.groupOrderID));
        dispatch(
            layoutActions.displaySnackbar({
                title: <strong>Success</strong>,
                body: 'Success',
                icon: 'check',
                timeout: 5000,
            }),
        );

        return {
            type: orderConstants.ORDER_ACTION_SUCCESS,
            orderAmendment,
            response,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error amending order', error));
        return {
            type: orderConstants.ORDER_ACTION_FAIL,
            error,
        };
    }
}
