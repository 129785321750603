import axios from 'axios';

import env from '../../config/env';
import { getMicroservicesDomain } from '../../utils';
import microservices from '../microservices';
import AuthSession from './authSession'; // Import the AuthSession class

class Auth {
    constructor() {
        this.session = new AuthSession(); // Create a new AuthSession instance
        if (this.session.checkForActiveSession()) {
            const { accessToken, idToken, refreshToken, ID } = this.getCurrentSession();
            this.session.setSession(accessToken, idToken, refreshToken, ID);
        }
    }

    async login(username, password, newPassword, newPasswordSession) {
        if (!username || !password) {
            throw new Error('Missing username or password');
        }
        const url = getMicroservicesDomain();
        const defaultHeaders = {
            'X-Api-Key': env.API_KEY,
        };

        let response;

        try {
            response = await axios({
                method: 'post',
                url: `${url}/auth`,
                data: {
                    email: username,
                    password,
                    newPassword,
                    newPasswordSession,
                },
                headers: defaultHeaders,
            });
        } catch (error) {
            if (error.response) {
                throw new Error(error.response.data.error);
            } else {
                console.error('error on login request:', error);
                throw new Error(error);
            }
        }

        const {
            accessToken,
            idToken,
            refreshToken,
            user,
            ChallengeName,
            ChallengeParameters,
            session,
        } = response.data;

        if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
            return { ...user, ChallengeName, ChallengeParameters, session };
        }

        if (!accessToken || !idToken || !user) {
            throw new Error('Failed to retrieve a token or user');
        }

        this.session.setSession(accessToken, idToken, refreshToken, user.ID);
        return user;
    }

    async logout(username) {
        if (username) {
            try {
                await microservices.post(`/logout`, { username: username });
            } catch (error) {
                console.error('Error upon logging out');
            }
        }

        this.session.clearSession();
        return Promise.resolve();
    }

    async getAccessToken() {
        const isTokenValid = this.isTokenValid();

        if (!isTokenValid) {
            await this.refreshUser();
        }

        return this.session.getAccessToken();
    }

    async getIdToken() {
        const isTokenValid = this.isTokenValid();

        if (!isTokenValid) {
            await this.refreshUser();
        }

        return this.session.getIdToken();
    }

    getRefreshToken() {
        return this.session.getRefreshToken();
    }

    isTokenValid() {
        return this.session.isValid();
    }

    haveActiveSession() {
        return this.session.checkForActiveSession();
    }

    getCurrentSession() {
        return this.session.getCurrentSession();
    }

    getUserID() {
        return this.session.getUserID();
    }

    async refreshUser() {
        const url = getMicroservicesDomain();
        const defaultHeaders = {
            'X-Api-Key': env.API_KEY,
        };

        let response;
        try {
            response = await axios({
                method: 'post',
                url: `${url}/refresh`,
                data: {
                    refreshToken: this.getRefreshToken(),
                },
                headers: defaultHeaders,
            });
            this.session.setSession(
                response.data.accessToken,
                response.data.idToken,
                this.getRefreshToken(),
                this.getUserID(),
            );
        } catch (error) {
            if (error.response) {
                throw new Error(error.response.data.error);
            } else {
                console.error('error on refresh request:', error);
                throw new Error(error);
            }
        }
    }
}

const authInstance = new Auth(); // Create the instance

export default authInstance; // Export the instance directly
