import { push } from 'connected-react-router';

import microservices from '../../services/microservices';
import { handleUnauthorizedError } from '../../utils';
import { errorActions } from './errors';
import { layoutActions } from './layouts';
import { paymentOptionsConstants } from './types';

export const paymentOptionsActions = {
    updateState,
    getPaymentOptions,
    getPaymentOption,
    createPaymentOption,
    updatePaymentOption,
    removePaymentOption,
    toggleProperty,
};

function updateState(name, value) {
    return { type: paymentOptionsConstants.UPDATE_STATE, name, value };
}

function getPaymentOptions() {
    return dispatch => {
        dispatch(request());

        microservices.paymentMethods
            .get('/', { optionalHeaders: { 'X-Cache-Enabled': 'false' } })
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(error.toString(), dispatch));
            });
    };

    function request() {
        return { type: paymentOptionsConstants.LIST_REQUEST };
    }
    function success(paymentOptions) {
        return { type: paymentOptionsConstants.LIST_SUCCESS, paymentOptions };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error retrieving payment options', error));
        return { type: paymentOptionsConstants.LIST_FAIL, error };
    }
}

function getPaymentOption(paymentType) {
    return dispatch => {
        dispatch(request(paymentType));
        dispatch(errorActions.clear());

        microservices.paymentMethods
            .get(`/${paymentType}`)
            .then(response => dispatch(success(response.data)))
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(error.toString(), dispatch));
            });
    };

    function request(paymentType) {
        return { type: paymentOptionsConstants.GET_REQUEST, paymentType };
    }
    function success(paymentOption) {
        return { type: paymentOptionsConstants.GET_SUCCESS, paymentOption };
    }
    function failure(error, dispatch) {
        dispatch(push(`/payment`));
        dispatch(layoutActions.displayErrorDialog('Error getting payment option', error));
        return { type: paymentOptionsConstants.GET_FAIL, error };
    }
}

function createPaymentOption(paymentOption) {
    return dispatch => {
        dispatch(request(paymentOption));
        dispatch(errorActions.clear());

        microservices.paymentMethods
            .post('', { entity: { ...paymentOption } })
            .then(response => {
                dispatch(success(response.data));
                dispatch(push('/payment'));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(error.toString(), dispatch));
            });
    };

    function request() {
        return { type: paymentOptionsConstants.CREATE_REQUEST };
    }
    function success(paymentOptions) {
        return { type: paymentOptionsConstants.CREATE_SUCCESS, paymentOptions };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error retrieving payment options', error));
        return { type: paymentOptionsConstants.CREATE_FAIL, error };
    }
}

function updatePaymentOption(paymentType, entity) {
    return dispatch => {
        dispatch(request(paymentType));
        dispatch(errorActions.clear());

        microservices.paymentMethods
            .patch(`/${paymentType}`, { entity })
            .then(responseData => {
                const response = responseData.data;
                const updatedEntity = { paymentType, updated: response.updated };
                Object.keys(entity).forEach(key => {
                    updatedEntity[key] = response[key];
                });
                dispatch(success(updatedEntity));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(error.toString(), dispatch));
            });
    };

    function request(paymentType) {
        return {
            type: paymentOptionsConstants.SAVE_REQUEST,
            paymentType,
        };
    }
    function success(paymentOption) {
        return {
            type: paymentOptionsConstants.SAVE_SUCCESS,
            paymentOption,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error updating payment option', error));
        return { type: paymentOptionsConstants.SAVE_FAIL, error };
    }
}

function toggleProperty(paymentType, entity) {
    return dispatch => {
        dispatch(request(paymentType));
        dispatch(errorActions.clear());

        microservices.paymentMethods
            .patch(`/${paymentType}`, { entity })
            .then(responseData => {
                const response = responseData.data;
                const updatedEntity = { paymentType, updated: response.updated };
                Object.keys(entity).forEach(key => {
                    updatedEntity[key] = response[key];
                });
                dispatch(success(updatedEntity));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(error.toString(), dispatch));
            });
    };

    function request(paymentType) {
        return {
            type: paymentOptionsConstants.TOGGLE_ACTIVE_REQUEST,
            paymentType,
        };
    }
    function success(paymentOption) {
        return {
            type: paymentOptionsConstants.TOGGLE_ACTIVE_SUCCESS,
            paymentOption,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error updating payment option', error));
        return { type: paymentOptionsConstants.TOGGLE_ACTIVE_FAIL, error };
    }
}

function removePaymentOption(paymentOption) {
    return dispatch => {
        dispatch(request(paymentOption));
        dispatch(errorActions.clear());

        microservices.paymentMethods
            .delete(`/${paymentOption.paymentType}`)
            .then(() => {
                dispatch(success(dispatch));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(error.toString(), dispatch));
            });
    };

    function request(paymentOption) {
        return {
            type: paymentOptionsConstants.DELETE_REQUEST,
            paymentOption,
        };
    }
    function success(dispatch) {
        dispatch(push('/payment'));
        return { type: paymentOptionsConstants.DELETE_SUCCESS };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error deleting payment option', error));
        return { type: paymentOptionsConstants.DELETE_FAIL, error };
    }
}
