import { format } from 'date-fns';

export const formatDateDefault = date => {
    return format(date, 'dd MMM yyyy HH:mm');
};

export const formatDateShort = date => {
    return format(date, 'dd/MM/yyyy HH:mm');
};

export const convertTimeToSeconds = time => {
    if (time === '00:00' || time === undefined) {
        return 0;
    }
    let timeSplit = time.split(':');

    return +timeSplit[0] * 60 * 60 + +timeSplit[1] * 60;
};

export const convertTimeFromSeconds = seconds => {
    if (seconds === 0 || seconds === undefined) {
        return new Date(0, 0, 0, 0).toISOString().substr(11, 5);
    }
    setTimeout(() => {}, 1); // Doesn't seem to work without this setTimeout

    return new Date(seconds * 1000).toISOString().substr(11, 5);
};
