import { ordersConstants } from '../actions/types';

const initialState = {
    orders: {},
    order: null,
};

export function ordersReducer(state = initialState, action) {
    switch (action.type) {
        case ordersConstants.GET_ORDERS_SUCCESS:
        case ordersConstants.SEARCH_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.orders,
            };

        case ordersConstants.GET_ORDER_SUCCESS:
            return {
                ...state,
                order: action.order,
            };

        default:
            return state;
    }
}
