import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = props => {
    const { component, user, isLoggedIn, ...routeProps } = props;
    const Component = component;
    const pagePermission = props.permission;
    const isAccessible = checkAccess(user, isLoggedIn, pagePermission);

    if (!user) {
        return null;
    }

    return (
        <Route
            {...routeProps}
            render={props => {
                if (isAccessible) {
                    return <Component {...props} />;
                }

                return <Redirect to={{ pathname: '/login' }} />;
            }}
        />
    );
};

const checkAccess = (user, isLoggedIn, pagePermission) => {
    if (
        user &&
        Object.keys(user).length &&
        user.ID &&
        isLoggedIn === 'signedIn' &&
        (pagePermission ? user.permissions.includes(pagePermission) : true)
    ) {
        return true;
    }

    return false;
};

const mapStateToProps = state => {
    return {
        user: state.auth.currentUser,
        isLoggedIn: state.auth.authState,
    };
};

export default connect(mapStateToProps)(ProtectedRoute);
