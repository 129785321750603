import React, { Fragment } from 'react';

import DeliveryPageItem from './DeliveryPageItem';
import OrderPageItem from './OrderPageItem';

const OrderPageItemTable = ({
    onRefundReturnActionChange,
    setDeliveryRefundReason,
    onDeliveryRefundRefresh,
    getRefundEdit,
    getRefundShow,
    setNewDeliveryRefund,
    deliveryRefundReason,
    newDeliveryRefund,
    faciaDisplayText,
    selectedFacia,
    order,
    user,
    validateAmendments,
}) => {
    return (
        <Fragment>
            {Object.keys(order.items).map((itemBrand, index) => {
                // only display valid brand orders - more useful for redundant dev records
                if (typeof faciaDisplayText[itemBrand] !== 'undefined') {
                    if (selectedFacia !== '' && selectedFacia !== itemBrand) {
                        return null;
                    } else {
                        return (
                            <Fragment key={index}>
                                <OrderPageItem
                                    onRefundReturnActionChange={onRefundReturnActionChange}
                                    getRefundEdit={getRefundEdit}
                                    getRefundShow={getRefundShow}
                                    faciaDisplayText={faciaDisplayText}
                                    item={order.items[itemBrand]}
                                    itemBrand={itemBrand}
                                    order={order}
                                />
                            </Fragment>
                        );
                    }
                } else {
                    return null;
                }
            })}
            <DeliveryPageItem
                setDeliveryRefundReason={setDeliveryRefundReason}
                onDeliveryRefundRefresh={onDeliveryRefundRefresh}
                setNewDeliveryRefund={setNewDeliveryRefund}
                deliveryRefundReason={deliveryRefundReason}
                newDeliveryRefund={newDeliveryRefund}
                validateAmendments={validateAmendments}
                order={order}
                user={user}
            />
        </Fragment>
    );
};

export default OrderPageItemTable;
