/** Set to true to use development API when the app is built */
import { getMicroservicesDomain } from '../utils';

const config = {
    baseURL: getMicroservicesDomain(),
    resources: {
        admin: '/admin',
        delivery: '/delivery',
        order: '/orders',
        product: '/products',
        store: '/stores',
        user: '/user',
        paymentMethods: '/payment-methods',
    },
};

export default config;
