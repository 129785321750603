//@ts-check
import { Box, makeStyles, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';

import { resolve } from '../../../utils';

const useStyles = makeStyles(() => ({
    label: {
        color: '#3e4555',
        fontFamily: 'Gotham',
        fontSize: 14,
        fontWeight: 'bold',
    },
    textField: {
        borderRadius: 5,
    },
    input: {
        fontFamily: 'Gotham SSm A',
        fontSize: 14,
        fontWeight: 400,
        color: '#3e4555',
    },
}));

const FormTextField = ({ name = '', label = '', defaultHelperText = ' ', ...props }) => {
    const form = useFormikContext();
    const classes = useStyles();

    const hasBeenTouched = !!resolve(name, form.touched);
    const hasError = !!resolve(name, form.errors);
    const value = resolve(name, form.values);

    const getHelperText = () => {
        if (hasBeenTouched && hasError) {
            return resolve(name, form.errors);
        }
        return defaultHelperText;
    };

    return (
        <Box>
            {label && (
                <label htmlFor={name} className={classes.label}>
                    {label}
                </label>
            )}
            <TextField
                className={classes.textField}
                id={name}
                name={name}
                helperText={getHelperText()}
                error={hasBeenTouched && hasError}
                placeholder={label}
                value={value}
                onChange={e => {
                    e.persist();
                    form.handleChange(e);
                    form.setFieldTouched(name, true, false);
                }}
                fullWidth
                variant="outlined"
                size="small"
                inputProps={{ className: classes.input }}
                {...props}
            />
        </Box>
    );
};

export default FormTextField;
