import messages from '../../layouts/admin/messages';
import { layoutConstants } from './types';

export const layoutActions = {
    toggleMenu,
    displayErrorDialog,
    displaySnackbar,
};

function toggleMenu(open) {
    return { type: layoutConstants.TOGGLE_DRAWER, open };
}
function displayErrorDialog(title, message) {
    messages.dialogQueue.alert({
        title: title,
        body: message,
    });
    return { type: layoutConstants.DISPLAY_ERROR_DIALOG, message };
}
function displaySnackbar(message) {
    messages.snackbarQueue.notify(message);
    return { type: layoutConstants.DISPLAY_SNACKBAR, message };
}
