import { errorConstants } from '../actions/types';

const initialState = {
    errors: [],
};

export function errorReducer(state = initialState, action) {
    switch (action.type) {
        case errorConstants.PUSH: {
            const newErrors = [...state.errors];

            newErrors.push(action.error);

            return {
                ...state,
                errors: newErrors,
            };
        }
        case errorConstants.CLEAR: {
            return {
                ...state,
                errors: [],
            };
        }
        default: {
            return state;
        }
    }
}
