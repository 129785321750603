import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { authReducer } from './auth';
import { deliveryOptionsReducer } from './deliveryOptions';
import { errorReducer } from './errors';
import { layoutsReducer } from './layouts';
import { loadingReducer } from './loading';
import { orderReducer } from './order';
import { ordersReducer } from './orders';
import { paymentOptionsReducer } from './paymentOptions';
import { usersReducer } from './users';
import { utilsReducer } from './utils';

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        utils: utilsReducer,
        layouts: layoutsReducer,
        errors: errorReducer,
        orders: ordersReducer,
        order: orderReducer,
        loading: loadingReducer,
        users: usersReducer,
        deliveryOptions: deliveryOptionsReducer,
        paymentOptions: paymentOptionsReducer,
    });

export default rootReducer;
