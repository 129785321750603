import { push } from 'connected-react-router';

import authInstance from '../../services/Auth/authorization';
import microservices from '../../services/microservices';
import { authConstants } from './types';

export const authActions = {
    loadSignedInUser,
    removeCurrentUser,
    getCurrentUser,
    setStateNotSignedIn,
    setStateSignedIn,
    setUserForgotPassword,
    setUserRequiresNewPassword,
};

function loadSignedInUser() {
    return dispatch => {
        const isSignedIn = window.localStorage.getItem('signedIn');

        if (isSignedIn) {
            authorizeCurrentUser()
                .then(user => {
                    if (user.user) {
                        dispatch(success(user.user));
                    } else {
                        dispatch(push('/login'));
                        dispatch(failure());
                    }
                })
                .catch(() => {
                    dispatch(push('/login'));
                    dispatch(failure());
                });
        } else {
            dispatch(push('/login'));
            dispatch(failure());
        }
    };
    function success(user) {
        return { type: authConstants.LOAD_SIGNED_IN_USER, user };
    }
    function failure() {
        return { type: authConstants.SET_USER_NOT_SIGNED_IN_SUCCESS };
    }
}

function getCurrentUser() {
    return dispatch => {
        dispatch(request());

        authorizeCurrentUser()
            .then(({ user }) => dispatch(success(user)))
            .catch(error => dispatch(failure(error)));
    };

    function request() {
        return { type: authConstants.GET_CURRENT_USER_REQUEST };
    }
    function success(user) {
        return { type: authConstants.GET_CURRENT_USER_SUCCESS, user };
    }
    function failure(error) {
        return { type: authConstants.GET_CURRENT_USER_FAIL, error };
    }
}

function removeCurrentUser(username) {
    return async dispatch => {
        dispatch(request());

        try {
            await authInstance.logout(username);
            dispatch(success());
            dispatch(push('/login'));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request() {
        return { type: authConstants.DELETE_CURRENT_USER_REQUEST };
    }
    function success() {
        return { type: authConstants.DELETE_CURRENT_USER_SUCCESS };
    }
    function failure(error) {
        return { type: authConstants.DELETE_CURRENT_USER_FAIL, error };
    }
}

function setStateNotSignedIn() {
    return dispatch => {
        dispatch(success());
        dispatch(push('/login'));
    };

    function success() {
        return { type: authConstants.SET_USER_NOT_SIGNED_IN_SUCCESS };
    }
}

function setStateSignedIn(user) {
    return dispatch => {
        dispatch(success(user));
        dispatch(push('/'));
    };

    function success(user) {
        return { type: authConstants.SET_CURRENT_USER_SIGNED_IN_SUCCESS, user };
    }
}

function setUserForgotPassword() {
    return dispatch => {
        dispatch(success());
    };

    function success() {
        return { type: authConstants.SET_USER_FORGOT_PASSWORD };
    }
}

function setUserRequiresNewPassword(user) {
    return dispatch => {
        dispatch(success(user));
        dispatch(push('/requireNewPassword'));
    };

    function success(user) {
        return { type: authConstants.SET_USER_REQUIRES_NEW_PASSWORD, user };
    }
}

export const authorizeCurrentUser = async () => {
    const currentSession = authInstance.getCurrentSession();
    const id = currentSession.ID;

    if (!currentSession.accessToken) {
        ('access token not found returning');
        return;
    }

    let user;
    try {
        const response = await microservices.user.get(`/${id}`, {});
        user = response.data.data[0];
    } catch (error) {
        console.error('Error on authorising current user', error);
        throw error;
    }

    if (!user || !user.enabled) {
        authInstance.logout();
        window.location.href = '/';
        throw new Error('User unauthorized');
    }
    return { currentSession, user };
};
