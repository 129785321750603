import microservices from '../../services/microservices';
import { utilConstants } from './types';

export const utilsActions = {
    getChannels,
    getFascias,
};

function getChannels() {
    return dispatch => {
        dispatch(request());

        microservices.admin
            .get(`/utils?retrieve=channels`)
            .then(response => dispatch(success(response.data.data)))
            .catch(error => dispatch(failure(error.error)));
    };

    function request() {
        return { type: utilConstants.GET_CHANNELS_REQUEST };
    }
    function success(channels) {
        return { type: utilConstants.GET_CHANNELS_SUCCESS, channels };
    }
    function failure(error) {
        return { type: utilConstants.GET_CHANNELS_FAIL, error };
    }
}

function getFascias() {
    return dispatch => {
        dispatch(request());

        microservices.admin
            .get(`/utils?retrieve=stores`)
            .then(response => dispatch(success(response.data.data)))
            .catch(error => dispatch(failure(error.error)));
    };

    function request() {
        return { type: utilConstants.GET_FASCIAS_REQUEST };
    }
    function success(fascias) {
        return { type: utilConstants.GET_FASCIAS_SUCCESS, fascias };
    }
    function failure(error) {
        return { type: utilConstants.GET_FASCIAS_FAIL, error };
    }
}
