import { Button } from '@rmwc/button';
import { Card, CardActionButtons, CardActions } from '@rmwc/card';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../layouts/admin/Loader';
import { usersActions } from '../../redux/actions/users';
import { createLoadingSelector } from '../../redux/selectors/loader';
import UserForm from './UserForm';

class NewUser extends React.Component {
    constructor(props) {
        super(props);
        const { resetUser } = props;
        resetUser();
    }

    render() {
        const { user, createUser, userChanged, errors, loading } = this.props;
        return true === loading ? (
            <Loader />
        ) : (
            <div className="padded">
                <Card>
                    <UserForm user={user} userChanged={userChanged} emailDisabled={false} />
                    {errors.map((error, index) => (
                        <span key={'error-' + index} className="error-message">
                            {error.message}
                        </span>
                    ))}
                    <CardActions>
                        <CardActionButtons>
                            <Button raised={true} onClick={() => createUser(user)}>
                                Create
                            </Button>
                            <Link to="/users">
                                <Button>Cancel</Button>
                            </Link>
                        </CardActionButtons>
                    </CardActions>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const loadingSelector = createLoadingSelector(['(Users) CREATE', '(Users) RESET_STATE']);
    return {
        user: state.users.user,
        loading: loadingSelector(state),
        errors: state.errors.errors,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetUser: () => {
            dispatch(usersActions.resetUser());
        },
        userChanged: (id, value) => {
            dispatch(usersActions.updateState(id, value));
        },
        createUser: user => {
            dispatch(usersActions.createUser(user));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
