import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { authActions } from '../../redux/actions/auth';

const Logout = props => {
    const { removeCurrentUser, location, currentUser, push } = props;

    useEffect(() => {
        if (
            typeof location.state !== 'undefined' &&
            typeof location.state.logout !== 'undefined' &&
            location.state.logout === true
        ) {
            removeCurrentUser(currentUser.cognitoUsername);
        } else {
            push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div />;
};

function mapStateToProps(state) {
    return {
        currentUser: state.auth.currentUser,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        push: route => dispatch(push(route)),
        removeCurrentUser: history => dispatch(authActions.removeCurrentUser(history)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout));
