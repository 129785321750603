import { Button } from '@rmwc/button';
import React from 'react';

import * as styles from './Header.module.scss';

const Header = ({ onReturnClick, openDeleteModal, onCancelClick, onSaveClick, changeMade }) => (
    <header className="col-md-12">
        <div className="float-left">
            <button
                className={`btn btn-default inline-block ${styles.returnBtn}`}
                onClick={onReturnClick}
            >
                Go back
            </button>
        </div>
        <div className="float-right">
            <Button
                className={`right-spacing-btn ${styles.cancelPaymentoptionsBtn}`}
                onClick={onCancelClick}
                disabled={!changeMade}
                raised={true}
            >
                Cancel
            </Button>

            <Button
                className={`right-spacing-btn ${styles.deletePaymentoptionsBtn}`}
                onClick={() => openDeleteModal(true)}
                raised={true}
            >
                Delete
            </Button>

            <Button
                className={`${styles.savePaymentoptionsBtn}`}
                onClick={onSaveClick}
                disabled={!changeMade}
                raised={true}
            >
                Save Changes
            </Button>
        </div>
    </header>
);

export default Header;
