import React, { Fragment } from 'react';

import { lookupStatus } from '../../data/statusConfig';
import { getOrderPaymentStatus } from '../../utils/orders';

const getOrderStatusLine = items => {
    let status;

    for (let itemBrand in items) {
        if (!items[itemBrand].status) {
            continue;
        }
        status = items[itemBrand].status;
    }

    const { statusName, className, bkgColor } = lookupStatus(status);
    const { circle, statusBtn } = styles.second;
    return (
        <button className={`btn btn-${className}`} style={statusBtn}>
            <div className="float-left">
                <div style={{ ...circle, ...styles.bkgColor[bkgColor] }} />
                {statusName.toUpperCase()}
            </div>
        </button>
    );
};

const getVendorOrderStatusBtn = (orderStatus, lineStatus) => {
    if (!orderStatus && !lineStatus) {
        return null;
    }

    const { circle, statusBtn } = styles.second;

    const status = lineStatus || orderStatus;
    const { statusName, className, bkgColor } = lookupStatus(status);

    return (
        <button className={`btn btn-${className}`} style={statusBtn}>
            <div className="float-left">
                <div style={{ ...circle, ...styles.bkgColor[bkgColor] }} />
                {statusName.toUpperCase()}
            </div>
        </button>
    );
};

const getOrderStatusBtn = (items, payment, lineObject = {}, styleType = 1) => {
    let status = null;

    if (lineObject === null || Object.keys(lineObject).length < 1) {
        status = getOrderPaymentStatus(items, payment);
    } else {
        const statusInputsNeeded = lineObject.lines.filter(
            ({ type }) => type === 'return' || type === 'refund',
        ).length;

        if (!statusInputsNeeded) {
            status = {
                type: 'AMEND',
                text: lineObject.lines.type,
            };
        } else {
            const uniqueIdentifier = Math.round(Math.random() * 1000000);

            const returnActionPresent = isActionPresent('return', lineObject.lines);
            const refundActionPresent = isActionPresent('refund', lineObject.lines);

            const returnInput = getCheckboxInput(
                'return',
                returnActionPresent,
                uniqueIdentifier,
                lineObject.onInputChange,
                lineObject.lines[0].line,
            );
            const refundInput = getCheckboxInput(
                'refund',
                refundActionPresent,
                uniqueIdentifier,
                lineObject.onInputChange,
                lineObject.lines[0].line,
            );

            return (
                <Fragment>
                    {returnInput}
                    {refundInput}
                </Fragment>
            );
        }
    }

    if (styleType === 1) {
        return firstStyleType(status);
    } else if (styleType === 2) {
        return secondStyleType(status);
    }
};

const isActionPresent = (action, lines) => {
    let result = lines.filter(({ type }) => type === action).length > 0;

    return result;
};

const getCheckboxInput = (action, checked, uniqueIdentifier, onInputChange, lineNumber) => (
    <div>
        {checked ? (
            <div className="form-control action-checkbox-container">
                <input
                    type="checkbox"
                    name={`action-checkbox-${uniqueIdentifier}`}
                    className={
                        !checked
                            ? `form-group action-checkbox action-checkbox-active`
                            : `form-group action-checkbox`
                    }
                    checked={true}
                    disabled={true}
                />
                <label className="action-checkbox-label">
                    {action.charAt(0).toUpperCase() + action.slice(1)}
                </label>
            </div>
        ) : (
            <div className="form-control action-checkbox-container">
                <input
                    type="checkbox"
                    name={`action-checkbox-${uniqueIdentifier}`}
                    className={
                        !checked
                            ? `form-group action-checkbox action-checkbox-active`
                            : `form-group action-checkbox`
                    }
                    data-checked={false}
                    defaultValue={checked}
                    onChange={e => onInputChange(e)}
                    disabled={checked}
                />
                <input type="hidden" name="line-number" value={lineNumber} />
                <label className="action-checkbox-label">
                    {action.charAt(0).toUpperCase() + action.slice(1)}
                </label>
            </div>
        )}
    </div>
);

const firstStyleType = status => {
    let className = '',
        bkgColor = {};

    const { statusBtn } = styles.first;
    const { aquaMarine, blue, dustyMarine } = styles.bkgColor;

    if (typeof status === 'object') {
        switch (status.type) {
            case 'PAYMENT':
                switch (status.text) {
                    case 'paid':
                        className = 'success';
                        bkgColor = aquaMarine;
                        break;
                    case 'pending':
                        className = 'warning';
                        bkgColor = blue;
                        break;
                    case 'failed':
                    case 'rejected':
                    case 'cancelled':
                    case 'cancelling':
                        className = 'danger';
                        bkgColor = dustyMarine;
                        break;
                    default:
                        break;
                }
                break;
            case 'AMEND':
                switch (status.text) {
                    case 'refunded':
                    case 'refund':
                        className = 'danger';
                        bkgColor = dustyMarine;
                        break;
                    case 'returned':
                    case 'return':
                        className = 'primary';
                        bkgColor = dustyMarine;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }

    if (0 < className.length) {
        return (
            <button className={`btn btn-${className}`} style={{ ...statusBtn, ...bkgColor }}>
                {status.text.toUpperCase()}
            </button>
        );
    } else {
        return (
            <button
                className={`btn btn-default`}
                style={{
                    ...statusBtn,
                    ...bkgColor,
                    backgroundColor: 'lightgrey',
                }}
            >
                Other
            </button>
        );
    }
};

const secondStyleType = status => {
    let className = '',
        bkgColor = {};

    const { circle, statusBtn } = styles.second;
    const { aquaMarine, blue, dustyMarine } = styles.bkgColor;

    if (typeof status === 'object') {
        switch (status.type) {
            case 'PAYMENT':
                switch (status.text) {
                    case 'paid':
                        className = 'success';
                        bkgColor = aquaMarine;
                        break;
                    case 'pending':
                        className = 'warning';
                        bkgColor = blue;
                        break;
                    case 'failed':
                    case 'rejected':
                    case 'cancelled':
                    case 'cancelling':
                        className = 'danger';
                        bkgColor = dustyMarine;
                        break;
                    default:
                        break;
                }
                break;
            case 'AMEND':
                switch (status.text) {
                    case 'refunded':
                    case 'refund':
                        className = 'danger';
                        bkgColor = dustyMarine;
                        break;
                    case 'returned':
                    case 'return':
                        className = 'primary';
                        bkgColor = dustyMarine;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }

    if (0 < className.length) {
        return (
            <button
                className={`btn btn-${className}`}
                style={{ ...statusBtn, pointerEvents: 'none' }}
            >
                <div className="float-left">
                    <div style={{ ...circle, ...bkgColor }} />
                    {status.text.toUpperCase()}
                </div>
            </button>
        );
    } else {
        return (
            <button className={`btn btn-default`} style={statusBtn}>
                <div className="float-left">
                    <div
                        style={{
                            ...circle,
                            ...bkgColor,
                            backgroundColor: 'lightgrey',
                        }}
                    />
                    Other
                </div>
            </button>
        );
    }
};

const styles = {
    bkgColor: {
        aquaMarine: {
            backgroundColor: '#4bd4b0',
        },
        blue: {
            backgroundColor: '#65abe9',
        },
        dustyMarine: {
            backgroundColor: '#ff6633',
        },
        mediumOrchid: {
            backgroundColor: '#BA55D3',
        },
    },
    first: {
        statusBtn: {
            width: 117,
            height: 35,
            fontSize: 11,
            fontWeight: 'bold',
            color: 'white',
            border: 'none',
        },
    },
    second: {
        statusBtn: {
            height: 20,
            borderRadius: 15,
            fontSize: 10,
            letterSpacing: 2,
            color: 'black',
            border: 'none',
            backgroundColor: '#f7f7f7',
            lineHeight: 1,
            fontWeight: 'bold',
        },
        circle: {
            width: 10,
            height: 10,
            borderRadius: 10,
            float: 'left',
            clear: 'left',
            marginRight: 7,
            marginLeft: -7,
        },
    },
};

export { getOrderStatusBtn, getVendorOrderStatusBtn, getOrderStatusLine };
