import env from '../config/env';
import authInstance from '../services/Auth/authorization';

export const getDefaultHeaders = async () => {
    const accessToken = await authInstance.getAccessToken();
    if (!accessToken) {
        throw new Error('Access Token-Invalid');
    }
    const userID = authInstance.getUserID();
    const defaultHeaders = {
        Authorization: accessToken,
        'X-Api-Key': env.API_KEY,
        UserID: userID,
    };

    return defaultHeaders;
};
