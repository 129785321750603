import './Loader.scss';

import React from 'react';

const Loader = () => (
    <div className="admin-loader">
        <img src="/images/logo.png" alt="JD Sports Logo" />
        <p>Loading</p>
    </div>
);

export default Loader;
