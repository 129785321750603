import { Button } from '@rmwc/button';
import { Card, CardActionButtons, CardActionIcons, CardActions } from '@rmwc/card';
import { Icon as MaterialIcon } from '@rmwc/icon';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../layouts/admin/Loader';
import { usersActions } from '../../redux/actions/users';
import { createLoadingSelector } from '../../redux/selectors/loader';
import UserForm from './UserForm';

const EditUser = ({
    currentUser,
    getUser,
    match,
    user,
    saveUser,
    resetUser,
    errors,
    userChanged,
    remove,
    loading,
}) => {
    useEffect(() => getUser(match.params.id), [getUser, match.params.id]);

    const userCannotEditSelf = !currentUser.permissions.includes('users--editSelf');
    const isCurrentUsersForm = currentUser.email === user.email;
    const cannotEditSelf = userCannotEditSelf && isCurrentUsersForm;

    return loading === true ? (
        <Loader />
    ) : (
        <div className="padded">
            <Card>
                <UserForm
                    user={user}
                    userChanged={userChanged}
                    emailDisabled={true}
                    loading={loading}
                    cannotEditSelf={cannotEditSelf}
                />
                {errors.map((error, key) => (
                    <span key={key} className="error-message">
                        {error.error}
                    </span>
                ))}

                {!cannotEditSelf ? (
                    <CardActions>
                        <CardActionButtons>
                            <Button onClick={() => saveUser(user)} raised={true} disabled={loading}>
                                Save
                            </Button>
                            <Link to="/users">
                                <Button>Cancel</Button>
                            </Link>
                            <Button onClick={() => resetUser(user)} disabled={loading}>
                                Reset User
                            </Button>
                        </CardActionButtons>
                        {currentUser.permissions.includes('users--delete') ? (
                            <CardActionIcons>
                                <MaterialIcon icon="delete" onClick={() => remove(user)} />
                            </CardActionIcons>
                        ) : (
                            ''
                        )}
                    </CardActions>
                ) : (
                    ''
                )}
            </Card>
        </div>
    );
};

const mapStateToProps = state => {
    const loadingSelector = createLoadingSelector([
        '(Users) GET',
        '(Users) SAVE',
        '(Users) DELETE',
        '(Users) TOGGLE_ENABLED',
    ]);
    return {
        currentUser: state.auth.currentUser,
        user: state.users.user,
        loading: loadingSelector(state),
        errors: state.errors.errors,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUser: username => {
            dispatch(usersActions.getUser(username));
        },
        userChanged: (id, value) => {
            dispatch(usersActions.updateState(id, value));
        },
        saveUser: user => {
            dispatch(usersActions.updateUser(user));
        },
        resetUser: user => {
            dispatch(usersActions.createUser(user, true));
        },
        remove: user => {
            confirmAlert({
                title: 'Delete user',
                message: `Are you sure you want to delete the user ${user.firstName} ${user.lastName}`,
                customUI: ({ title, message, onClose }) => (
                    <div className="react-confirm-alert">
                        <div className="react-confirm-alert-body">
                            <h1>{title}</h1>
                            {message}
                            <div className="react-confirm-alert-button-group">
                                <Button
                                    raised
                                    onClick={() => {
                                        dispatch(usersActions.remove(user));
                                        onClose();
                                    }}
                                >
                                    Delete
                                </Button>
                                <Button onClick={onClose}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                ),
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
