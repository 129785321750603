import { authConstants } from '../actions/types';

const initialState = {
    currentUser: null,
    authState: 'signIn',
};

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case authConstants.SET_USER_FORGOT_PASSWORD:
            return {
                ...state,
                currentUser: null,
                authState: 'forgotPassword',
            };
        case authConstants.SET_CURRENT_USER_SIGNED_IN_SUCCESS:
        case authConstants.GET_CURRENT_USER_SUCCESS:
        case authConstants.LOAD_SIGNED_IN_USER:
            return {
                ...state,
                currentUser: action.user,
                authState: 'signedIn',
            };
        case authConstants.SET_USER_NOT_SIGNED_IN_SUCCESS:
        case authConstants.DELETE_CURRENT_USER_SUCCESS:
            return {
                ...state,
                currentUser: null,
                authState: 'signIn',
            };
        case authConstants.SET_USER_REQUIRES_NEW_PASSWORD:
            return {
                ...state,
                currentUser: action.user,
                authState: 'requireNewPassword',
            };
        default: {
            return state;
        }
    }
}
