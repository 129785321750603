import { push } from 'connected-react-router';

import microservices from '../../services/microservices';
import { getDefaultHeaders } from '../../utils/http';
import { authorizeCurrentUser } from './auth';
import { errorActions } from './errors';
import { usersConstants } from './types';

const getUser = id => {
    function request(id) {
        return { type: usersConstants.GET_REQUEST, id };
    }
    function success(user) {
        return { type: usersConstants.GET_SUCCESS, user };
    }
    function failure(error) {
        return { type: usersConstants.GET_FAIL, error };
    }

    return async dispatch => {
        dispatch(request(id));
        dispatch(errorActions.clear());
        try {
            const response = await microservices.user.get(`/${id}`, {});
            const data = response.data.data;

            if (!data || !data.data || !data.data[0]) {
                dispatch(failure('Data not found'));
            }
            dispatch(success(response.data.data[0]));
        } catch (error) {
            dispatch(failure(error));
        }
    };
};

const getUsers = () => {
    function request() {
        return { type: usersConstants.LIST_REQUEST };
    }
    function success(users) {
        return { type: usersConstants.LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: usersConstants.LIST_FAIL, error };
    }

    return async dispatch => {
        dispatch(request());
        try {
            const response = await microservices.user.get(``, {});

            const data = response.data.data;
            if (!data) {
                dispatch(failure('Data not found'));
            }
            dispatch(success(response.data.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };
};

const toggleEnabled = (user, enabled) => {
    function request(user) {
        return { type: usersConstants.TOGGLE_ENABLED_REQUEST, user };
    }
    function success(user, enabled) {
        return { type: usersConstants.TOGGLE_ENABLED_SUCCESS, user, enabled };
    }
    function failure(error) {
        return { type: usersConstants.TOGGLE_ENABLED_FAIL, error };
    }

    return async dispatch => {
        dispatch(request(user));
        dispatch(errorActions.clear());

        try {
            await microservices.user.put(`/${user.ID}/toggle`, { enabled });

            dispatch(success(user, enabled));
        } catch (error) {
            dispatch(failure(error));
        }
    };
};

const remove = user => {
    function request(user) {
        return { type: usersConstants.DELETE_REQUEST, user };
    }
    function success() {
        return { type: usersConstants.DELETE_SUCCESS };
    }
    function failure(error) {
        return { type: usersConstants.DELETE_FAIL, error };
    }
    return async dispatch => {
        dispatch(request(user));
        dispatch(errorActions.clear());

        try {
            await microservices.user.delete(`/${user.ID}`, {});

            dispatch(success());
            dispatch(push('/users'));
        } catch (error) {
            dispatch(failure(error));
        }
    };
};

const createUser = (user, resetUser) => {
    function request(data) {
        return { type: usersConstants.CREATE_REQUEST, data };
    }
    function success() {
        return { type: usersConstants.CREATE_SUCCESS };
    }
    function failure(error) {
        return { type: usersConstants.CREATE_FAIL, error };
    }

    return async dispatch => {
        dispatch(request(user));
        dispatch(errorActions.clear());

        const payload = Object.assign({}, user);
        if (resetUser === true) {
            payload.resetUser = true;
        }

        try {
            await microservices.user.post('', payload);

            dispatch(success());
            dispatch(push('/users'));
        } catch (error) {
            dispatch(failure(error));
            dispatch(errorActions.push(error.response.data));
        }
    };
};

export const usersActions = {
    updateState,
    getUsers,
    createUser,
    getUser,
    updateUser,
    remove,
    resetUser,
    toggleEnabled,
    resetPassword,
};

function updateState(name, value) {
    return { type: usersConstants.UPDATE_STATE, name, value };
}

function resetUser() {
    return { type: usersConstants.RESET_USER };
}

const headers = {};

function updateUser(user, updateLastLogin) {
    function request(user) {
        return { type: usersConstants.SAVE_REQUEST, user };
    }
    function success(user) {
        return { type: usersConstants.SAVE_SUCCESS, user };
    }
    function failure(error) {
        return { type: usersConstants.SAVE_FAIL, error };
    }

    return async dispatch => {
        dispatch(request(user));
        dispatch(errorActions.clear());

        const userData = {
            email: user.email,
            enabled: user.enabled,
            firstName: user.firstName,
            lastName: user.lastName,
            lastLogin: user.lastLogin,
            permissions: user.permissions,
        };

        await microservices.user
            .put(`/${user.ID}`, userData)
            .then(response => {
                dispatch(success(response.data.data));
                updateLastLogin ? dispatch(push('/')) : dispatch(push('/users'));
            })
            .catch(error => {
                dispatch(failure(error));
                dispatch(errorActions.push(error.response.data));
            });
    };
}

function resetPassword(user) {
    return dispatch => {
        authorizeCurrentUser().then(() => {
            dispatch(request(user));
            dispatch(errorActions.clear());

            getDefaultHeaders().then(header => {
                headers.headers = header;
            });

            microservices.user
                .put(`${user.id}/reset`)
                .then(() => {
                    dispatch(success(user));
                })
                .catch(error => dispatch(failure(error)));
        });
    };

    function request(user) {
        return { type: usersConstants.RESET_PASSWORD_REQUEST, user };
    }
    function success(user) {
        return { type: usersConstants.RESET_PASSWORD_SUCCESS, user };
    }
    function failure(error) {
        return { type: usersConstants.RESET_PASSWORD_FAIL, error };
    }
}
