import { push, replace } from 'connected-react-router';

import networkGroups from '../../config/networkGroups';
import microservices from '../../services/microservices';
import { handleUnauthorizedError } from '../../utils';
import { errorActions } from './errors';
import { layoutActions } from './layouts';
import { deliveryOptionsConstants } from './types';

export const deliveryOptionsActions = {
    updateState,
    getDeliveryOptions,
    createDeliveryOption,
    getDeliveryOption,
    updateDeliveryOption,
    removeDeliveryOption,
    toggleActive,
    resetDeliveryOption,
    duplicateDeliveryOption,
};

function getErrorMessage(error) {
    if (error.response && error.response.data) {
        return error.response.data.error;
    }
    if (error.message == 'Network Error') {
        return 'The supplied data is invalid. Please check the form fields and try again';
    }
    if (error.message) {
        return error.message;
    }

    return error.message ?? 'Something went wrong! Please check the form fields and try again';
}

function updateState(name, value) {
    return { type: deliveryOptionsConstants.UPDATE_STATE, name, value };
}

function resetDeliveryOption() {
    return { type: deliveryOptionsConstants.RESET_DELIVERY_OPTION };
}

function getDeliveryOptions() {
    return dispatch => {
        dispatch(request());

        microservices.delivery
            .get('/admin', { optionalHeaders: { 'X-Cache-Enabled': 'false' } })
            .then(response => {
                const deliveryData = response.data.data.map(x => {
                    x.price = x.price / 100;
                    return x;
                });
                return Promise.resolve(deliveryData);
            })
            .then(response => dispatch(success(response)))
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(getErrorMessage(error), dispatch));
            });
    };

    function request() {
        return { type: deliveryOptionsConstants.LIST_REQUEST };
    }
    function success(deliveryOptions) {
        return { type: deliveryOptionsConstants.LIST_SUCCESS, deliveryOptions };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error retrieving delivery options', error));
        return { type: deliveryOptionsConstants.LIST_FAIL, error };
    }
}

export function mapUIDeliveryOptionToPlatformModel(deliveryOption) {
    const mappedDeliveryOption = { ...deliveryOption };
    mappedDeliveryOption.networkGroups = [];
    networkGroups.forEach(({ id }) => {
        const uiField = 'anatwine-' + id;
        if (deliveryOption[uiField]) {
            mappedDeliveryOption.networkGroups.push(id);
        }
        delete mappedDeliveryOption[uiField];
    });
    mappedDeliveryOption.price = Number(deliveryOption.price) * 100;
    delete mappedDeliveryOption.ID;
    delete mappedDeliveryOption.settings;
    if (mappedDeliveryOption.cutOffTime === '') {
        mappedDeliveryOption.cutOffTime = 0;
    }
    if (mappedDeliveryOption.postcodeRestricted === '') {
        mappedDeliveryOption.postcodeRestricted = false;
    }

    return mappedDeliveryOption;
}

function createDeliveryOption(deliveryOption) {
    return dispatch => {
        dispatch(request(deliveryOption));
        dispatch(errorActions.clear());
        const mappedDeliveryOption = mapUIDeliveryOptionToPlatformModel(deliveryOption);
        const service = {
            channel: deliveryOption.channel,
        };

        const bodyData = { ...mappedDeliveryOption };
        delete bodyData.channel;

        microservices.delivery
            .post(`/admin`, bodyData, { optionalHeaders: service })
            .then(response => {
                dispatch(success(response.data, dispatch));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(getErrorMessage(error), dispatch));
                dispatch(errorActions.push(error.error));
            });
    };

    function request(data) {
        return { type: deliveryOptionsConstants.CREATE_REQUEST, data };
    }
    function success(deliveryOption, dispatch) {
        dispatch(push(`/delivery/${deliveryOption.ID}`));
        return {
            type: deliveryOptionsConstants.CREATE_SUCCESS,
            deliveryOption,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error creating delivery', error));
        return { type: deliveryOptionsConstants.CREATE_FAIL, error };
    }
}

export function mapPlatformDeliveryOptionToUIModel(deliveryOption) {
    const mappedDeliveryOption = { ...deliveryOption };
    networkGroups.forEach(networkGroup => {
        const uiField = 'anatwine-' + networkGroup.id;
        mappedDeliveryOption[uiField] = (deliveryOption.networkGroups || []).includes(
            networkGroup.id,
        );
    });
    delete mappedDeliveryOption.networkGroups;
    delete mappedDeliveryOption.settings;
    mappedDeliveryOption.price = deliveryOption.price / 100;
    return mappedDeliveryOption;
}

function getDeliveryOption(id) {
    return dispatch => {
        dispatch(request(id));
        dispatch(errorActions.clear());

        microservices.delivery
            .get(`/admin/${id}`, { optionalHeaders: { 'X-Cache-Enabled': 'false' } })
            .then(response => mapPlatformDeliveryOptionToUIModel(response.data))
            .then(response => dispatch(success(response, dispatch)))
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(getErrorMessage(error), dispatch));
            });
    };

    function request(id) {
        return { type: deliveryOptionsConstants.GET_REQUEST, id };
    }
    function success(deliveryOption) {
        return { type: deliveryOptionsConstants.GET_SUCCESS, deliveryOption };
    }
    function failure(error, dispatch) {
        dispatch(push(`/delivery`));
        dispatch(layoutActions.displayErrorDialog('Error getting delivery option', error));
        return { type: deliveryOptionsConstants.GET_FAIL, error };
    }
}

function updateDeliveryOption(deliveryOption) {
    return dispatch => {
        dispatch(request(deliveryOption));
        dispatch(errorActions.clear());
        const mappedDeliveryOption = mapUIDeliveryOptionToPlatformModel(deliveryOption);
        const bodyData = { ...mappedDeliveryOption };

        microservices.delivery
            .put(`/admin/${deliveryOption.ID}`, bodyData)
            .then(response => {
                dispatch(success(response.data, dispatch));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(getErrorMessage(error), dispatch));
                dispatch(errorActions.push(error.error));
            });
    };

    function request(deliveryOption) {
        return { type: deliveryOptionsConstants.SAVE_REQUEST, deliveryOption };
    }
    function success(deliveryOption, dispatch) {
        dispatch(getDeliveryOption(deliveryOption.ID));
        return { type: deliveryOptionsConstants.SAVE_SUCCESS, deliveryOption };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error updating delivery option', error));
        return { type: deliveryOptionsConstants.SAVE_FAIL, error };
    }
}

function removeDeliveryOption(deliveryOption) {
    return dispatch => {
        dispatch(request(deliveryOption));
        dispatch(errorActions.clear());

        microservices.delivery
            .delete(`/admin/${deliveryOption.ID}`)
            .then(() => {
                dispatch(success(dispatch));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(getErrorMessage(error), dispatch));
            });
    };

    function request(deliveryOption) {
        return {
            type: deliveryOptionsConstants.DELETE_REQUEST,
            deliveryOption,
        };
    }
    function success(dispatch) {
        dispatch(push('/delivery'));
        return { type: deliveryOptionsConstants.DELETE_SUCCESS };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error deleting delivery option', error));
        return { type: deliveryOptionsConstants.DELETE_FAIL, error };
    }
}

function toggleActive({ ID }) {
    return dispatch => {
        dispatch(request(ID));
        dispatch(errorActions.clear());

        microservices.delivery
            .put(`/admin/${ID}/toggleactive`)
            .then(response => {
                dispatch(success(response.data, dispatch));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(getErrorMessage(error), dispatch));
            });
    };

    function request(ID) {
        return {
            type: deliveryOptionsConstants.TOGGLE_ACTIVE_REQUEST,
            ID,
        };
    }
    function success(deliveryOption, dispatch) {
        dispatch(getDeliveryOptions());
        return {
            type: deliveryOptionsConstants.TOGGLE_ACTIVE_SUCCESS,
            deliveryOption,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error toggling delivery option', error));
        return { type: deliveryOptionsConstants.TOGGLE_ACTIVE_FAIL, error };
    }
}

function duplicateDeliveryOption({ ID }) {
    return dispatch => {
        dispatch(request(ID));
        dispatch(errorActions.clear());

        microservices.delivery
            .post(`/admin/${ID}/duplicate`)
            .then(response => {
                dispatch(success(response.data, dispatch));
            })
            .catch(error => {
                handleUnauthorizedError(error);
                dispatch(failure(getErrorMessage(error), dispatch));
            });
    };

    function request(ID) {
        return {
            type: deliveryOptionsConstants.DUPLICATE_REQUEST,
            ID,
        };
    }
    function success(deliveryOption, dispatch) {
        dispatch(getDeliveryOption(deliveryOption.ID));
        dispatch(replace(`/delivery/${deliveryOption.ID}`));
        return {
            type: deliveryOptionsConstants.DUPLICATE_SUCCESS,
            deliveryOption,
        };
    }
    function failure(error, dispatch) {
        dispatch(layoutActions.displayErrorDialog('Error duplicating delivery option', error));
        return {
            type: deliveryOptionsConstants.DUPLICATE_FAIL,
            error,
        };
    }
}
