import { filterItems } from './items';

export const getOrderPaymentStatus = (items, payment) => {
    let paymentStatus = '';
    // for redundant old data
    if (typeof payment.status === 'undefined') {
        return {
            type: 'PAYMENT',
            text: 'paid',
        };
    }
    // check payment status
    switch (payment.status) {
        case 'accepted':
        case 'pending':
        case 'failed':
        case 'rejected':
        case 'cancelled':
        case 'cancelling':
            paymentStatus = payment.status;
            break;
        case 'pre-payment':
        case 'uncaptured':
            paymentStatus = 'pending';
            break;
        default:
            break;
    }

    if (0 < paymentStatus.length && paymentStatus !== 'accepted') {
        return {
            type: 'PAYMENT',
            text: paymentStatus,
        };
    } else {
        // check other status recursively per item if payment fine
        const itemCount = Object.keys(items).length;
        const filteredRefundItemsCount = filterItems(items, 'refunded');

        if (filteredRefundItemsCount === itemCount) {
            return {
                type: 'AMEND',
                text: 'refunded',
            };
        }

        const filteredReturnItemsCount = filterItems(items, 'returned');

        if (filteredReturnItemsCount === itemCount) {
            return {
                type: 'AMEND',
                text: 'returned',
            };
        }

        if (0 < paymentStatus.length && paymentStatus === 'accepted') {
            return {
                type: 'PAYMENT',
                text: 'paid',
            };
        }
    }
};
