import { isCacheEnabled } from './cacheHelper';
import { addAuthHeaders } from './microservices';

/**
 * Function that runs before the request is made
 */
export const requestInterceptor = async request => {
    request = await addAuthHeaders(request);

    // cache is disabled, or has been requested to be skipped.
    // This is likely not needed, but I decided to carry this over just on the off chance we want to do caching in the future.
    if (!isCacheEnabled(request)) {
        // if bypass cache is specified, ensure the downstream service does not hit the cache.
        if (request.params?.$cache?.bypass) {
            if (!request.headers) {
                request.headers = {};
            }
            request.headers['X-Cache-Enabled'] = 'false';
        }
    }

    return request;
};
