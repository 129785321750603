import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { connect } from 'react-redux';

import Header from '../../components/payment-option/Header';
import PaymentOptionEdit from '../../components/payment-option/PaymentOptionEdit';
import ActionConfirmModal from '../../layouts/admin/ActionConfirmModal';
import Loader from '../../layouts/admin/Loader';
import { paymentOptionsActions } from '../../redux/actions/paymentOptions';
import { createLoadingSelector } from '../../redux/selectors/loader';
import * as styles from './index.module.scss';

const PaymentOption = props => {
    const [state, setState] = useState({
        openDeleteModal: false,
        changeMade: false,
        isPaymentOptionLoaded: false,
    });

    const { paymentOption, removePaymentOption, updatePaymentOption, loading } = props;

    useEffect(() => {
        props.getPaymentOption(props.match.params.paymentType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setEditPaymentOption = useCallback(() => {
        setState(currentState => ({
            ...currentState,
            editPaymentOption: { ...props.paymentOption },
            isPaymentOptionLoaded: true,
        }));
    }, [props.paymentOption]);

    useEffect(() => {
        if (props.paymentOption.paymentType) {
            setEditPaymentOption();
        }
    }, [setEditPaymentOption, props.paymentOption.paymentType]);

    const showOpenDeleteModal = show => {
        setState({ ...state, openDeleteModal: show });
    };

    const onGoBackClick = () => {
        window.location.href = '/payment';
    };

    const onCancelClick = () => {
        const { editPaymentOption } = state;
        for (const key in editPaymentOption) {
            editPaymentOption[key] = paymentOption[key];
        }
        setState({ ...state, changeMade: false, editPaymentOption });
    };

    const onDeleteClick = () => {
        setState({ ...state, openDeleteModal: false });
        removePaymentOption(paymentOption);
    };

    const onSaveClick = () => {
        const { editPaymentOption } = state;
        updatePaymentOption(paymentOption.paymentType, {
            isAnatwine: editPaymentOption.isAnatwine,
            isEnabled: editPaymentOption.isEnabled,
            name: editPaymentOption.name,
        });
        setState({ ...state, changeMade: false, editPaymentOption });
    };

    const onEntityChange = event => {
        const { editPaymentOption } = state;
        let changed = false;
        for (const key in event.formData) {
            if (event.formData[key] !== paymentOption[key]) {
                changed = true;
            }
        }

        if (!event.formData.name || event.formData.name.trim() === '') {
            changed = false;
        }
        if (changed) {
            for (const key in event.formData) {
                editPaymentOption[key] = event.formData[key];
            }
        }
        setState({ ...state, editPaymentOption, changeMade: changed });
    };

    if (true === loading || !state.isPaymentOptionLoaded) {
        return <Loader />;
    }

    return (
        <div className={`container-fluid ${styles.paymentOptionContainer}`}>
            <div className="row">
                <Header
                    onReturnClick={onGoBackClick}
                    openDeleteModal={showOpenDeleteModal}
                    onCancelClick={onCancelClick}
                    onSaveClick={onSaveClick}
                    changeMade={state.changeMade}
                />
            </div>
            <br />
            <PaymentOptionEdit
                onEntityChange={onEntityChange}
                onReturnClick={onGoBackClick}
                editPaymentOption={state.editPaymentOption}
            />

            <ActionConfirmModal
                onActionConfirmed={onDeleteClick}
                setModalOpen={showOpenDeleteModal}
                modalOpen={state.openDeleteModal}
                title={'Delete Payment Option'}
            />
        </div>
    );
};

function mapStateToProps(state) {
    const loadingSelector = createLoadingSelector(['(PaymentOptions) GET']);
    return {
        paymentOption: state.paymentOptions.paymentOption,
        currentUser: state.auth.currentUser,
        loading: loadingSelector(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getPaymentOption: paymentType =>
            dispatch(paymentOptionsActions.getPaymentOption(paymentType)),
        updatePaymentOption: (paymentType, paymentOption) =>
            dispatch(paymentOptionsActions.updatePaymentOption(paymentType, paymentOption)),
        removePaymentOption: paymentOption =>
            dispatch(paymentOptionsActions.removePaymentOption(paymentOption)),
        toggleProperty: (paymentType, entity) =>
            dispatch(paymentOptionsActions.updatePaymentOption(paymentType, entity)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOption);
