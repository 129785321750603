class AuthSession {
    constructor() {
        this.sessionData = {
            idToken: '',
            accessToken: '',
            refreshToken: '',
            expirationTime: 0,
            lastSessionCheck: 0,
        };
    }

    checkForActiveSession() {
        const sessionDataString = localStorage.getItem('authSession');

        if (!sessionDataString) {
            return false;
        }

        this.sessionData = JSON.parse(sessionDataString);
        return this.isValid();
    }

    parseJwt(token) {
        return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    }

    setSession(accessToken, idToken, refreshToken, userID) {
        const expirationTime = Date.now() + 5 * 60 * 1000;

        this.sessionData.accessToken = accessToken;
        this.sessionData.ID = userID;
        this.sessionData.idToken = idToken;
        this.sessionData.refreshToken = refreshToken;
        this.sessionData.expirationTime = expirationTime;
        this.sessionData.lastSessionCheck = Date.now();
        localStorage.setItem('authSession', JSON.stringify(this.sessionData));
        localStorage.setItem('signedIn', JSON.stringify(true));
    }

    getIdToken() {
        return this.sessionData.idToken;
    }

    getRefreshToken() {
        return this.sessionData.refreshToken;
    }

    getAccessToken() {
        return this.sessionData.accessToken;
    }

    isValid() {
        if (!this.sessionData.accessToken || !this.sessionData.idToken) {
            return false;
        }

        return this.sessionData.expirationTime > Date.now();
    }

    getCurrentSession() {
        return {
            accessToken: this.sessionData.accessToken,
            ID: this.sessionData.ID,
            idToken: this.sessionData.idToken,
            refreshToken: this.sessionData.refreshToken,
        };
    }

    getUserID() {
        return this.sessionData.ID;
    }

    clearSession() {
        this.sessionData = {
            idToken: '',
            accessToken: '',
            refreshToken: '',
            expirationTime: 0,
            lastSessionCheck: 0,
        };
        localStorage.removeItem('authSession');
        localStorage.removeItem('signedIn');
    }
}

export default AuthSession;
