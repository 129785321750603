import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Loader from '../../../layouts/admin/Loader';
import { ordersActions } from '../../../redux/actions/orders';
import { createLoadingSelector } from '../../../redux/selectors/loader';
import { isValueOrderId } from '../../../utils/isValueOrderId';
import OrdersTable from './OrdersTable';

class OrderListPage extends Component {
    sortByOptions = [
        'Name Ascending',
        'Name Descending',
        // "Store Ascending",
        // "Store Descending",
        'Created Ascending',
        'Created Descending',
        // "Total Ascending",
        // "Total Descending"
    ];

    state = {
        page: 1,
        rowsPerPage: 20,
        channel: '',
        search: '',
        sortBy: '',
        advancedSearch: {
            sku: '',
            plu: '',
            firstName: '',
            lastName: '',
            email: '',
            postcode: '',
            dateFrom: '',
            dateTo: '',
        },
        prevpath: '',
    };

    async componentDidMount() {
        const { location } = this.props.history;

        if (typeof location.search !== 'undefined') {
            await this.handlePreState();
            this.loadOrders();
        } else {
            this.loadOrders();
        }
    }

    componentDidUpdate() {
        const { orders, history } = this.props;

        if (typeof orders.data !== 'undefined' && orders.data.length === 1) {
            // redirect to order page with id
            const order = orders.data[0];

            if (isValueOrderId(this.state.search)) {
                // reset params & reload orders so if user wants to click orders link
                // when on another page they won't continue to get redirected to an order
                // previously searched
                this.setState(
                    {
                        page: 1,
                        rowsPerPage: 20,
                        search: '',
                        sortBy: '',
                        channel: '',
                    },
                    () => {
                        this.loadOrders();
                        history.push(`/orders/${order.ID}`);
                    },
                );
            }
        }
    }

    asyncSetState = state => {
        return new Promise(resolve => {
            this.setState(state, () => {
                resolve();
            });
        });
    };

    handlePreState = async () => {
        // extract params from location
        const { location } = this.props.history;

        let params = new URLSearchParams(location.search);

        if (params.get('search')) {
            await this.setSearchState(params.get('search'));
        }

        if (params.get('rowsPerPage')) {
            await this.setRowsPerPageState(params.get('rowsPerPage'));
        }

        if (params.get('page')) {
            await this.setPageState(params.get('page'));
        }

        if (location.state && location.state.prevpath == '/orders/search') {
            location.state.advancedSearch
                ? this.setAdvancedSearchState(location.state.advancedSearch)
                : this.setSearchState(location.state.search);
        }
    };

    loadOrders = () => {
        const { history } = this.props;
        const { page, rowsPerPage, search, advancedSearch, channel } = this.state;

        const obj = { page, rowsPerPage, search, channel };

        let filteredState = Object.keys(obj)
            .filter(k => obj[k] !== null && obj[k] !== '')
            .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
        const searchString = new URLSearchParams(filteredState).toString();

        history.push({
            pathname: '/orders',
            search: searchString,
        });

        const sortBy = this.translateSortByOption();

        let advancedSearchSet = false;

        for (let searchKey in advancedSearch) {
            if (1 > advancedSearch[searchKey].length) {
                advancedSearchSet = true;
                break;
            }
        }

        setTimeout(async () => {
            this.props.dispatch(
                await ordersActions.getOrders(
                    page,
                    rowsPerPage,
                    channel,
                    search,
                    sortBy,
                    advancedSearchSet ? advancedSearch : {},
                ),
            );
        }, 1);
    };

    translateSortByOption = () => {
        const { sortBy } = this.state;

        let translation = '';

        switch (sortBy) {
            case 'Name Ascending':
                translation = 'name_asc';
                break;
            case 'Name Descending':
                translation = 'name_desc';
                break;
            case 'Store Ascending':
                translation = 'store_asc';
                break;
            case 'Store Descending':
                translation = 'store_desc';
                break;
            case 'Created Ascending':
                translation = 'created_asc';
                break;
            case 'Created Descending':
                translation = 'created_desc';
                break;
            case 'Total Ascending':
                translation = 'total_asc';
                break;
            case 'Total Descending':
                translation = 'total_desc';
                break;
            default:
                break;
        }

        return translation;
    };

    setSortByState = async sortBy => {
        await this.asyncSetState({ sortBy });
    };

    setPageState = async page => {
        await this.asyncSetState({ page });
    };

    setRowsPerPageState = async rowsPerPage => {
        await this.asyncSetState({ rowsPerPage });
    };

    setChannelByState = async channel => {
        await this.asyncSetState({ channel });
    };

    setSearchState = async search => {
        await this.asyncSetState({ search });
    };

    setAdvancedSearchState = async subject => {
        const advancedSearch = Object.assign({}, subject);
        await this.asyncSetState({ advancedSearch });
    };

    setPrevpathState = async prevpath => {
        await this.asyncSetState({ prevpath });
    };

    renderOrdersTable = () => {
        const { orders } = this.props;
        const { rowsPerPage, search, page, sortBy, advancedSearch, prevpath, channel } = this.state;
        return (
            <OrdersTable
                updateRowsPerPage={this.setRowsPerPageState.bind(this)}
                updateChannel={this.setChannelByState.bind(this)}
                updateAdvancedSearch={this.setAdvancedSearchState.bind(this)}
                sortByOptions={this.sortByOptions}
                updateSortBy={this.setSortByState.bind(this)}
                updateSearch={this.setSearchState.bind(this)}
                advancedSearch={advancedSearch}
                updatePage={this.setPageState.bind(this)}
                reloadOrders={this.loadOrders.bind(this)}
                rowsPerPage={rowsPerPage}
                prevpath={prevpath}
                sortBy={sortBy}
                search={search}
                orders={orders}
                page={page}
                channel={channel}
            />
        );
    };

    render() {
        const { orders } = this.props;

        return !Object.keys(orders).length ? <Loader /> : this.renderOrdersTable();
    }
}

function mapStateToProps(state) {
    const loadingSelector = createLoadingSelector([
        '(Orders) SEARCH_ORDERS',
        '(Orders) GET_ORDERS',
        'GET_USER',
    ]);
    return {
        orders: state.orders.orders,
        loading: loadingSelector(state),
    };
}

export default connect(mapStateToProps)(withRouter(OrderListPage));
