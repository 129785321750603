import axios from 'axios';

import config from '../config/api';
import { getDefaultHeaders } from '../utils/http';
import { requestInterceptor } from './interceptors';
const { baseURL, resources } = config;

/**
 * Function that adds the auth headers to the request
 */
export const addAuthHeaders = async request => {
    const defaultHeaders = await getDefaultHeaders();

    if (request.optionalHeaders) {
        request.headers = { ...defaultHeaders, ...request.optionalHeaders };
    } else {
        request.headers = { ...defaultHeaders };
    }
    return request;
};

const baseConfig = {
    baseURL,
    headers: {},
};

/**
 * Axios wrapper which has pre-set headers and baseURL for our microservices
 *
 * Allows you to get axios methods, but also provides an instance for each microservice
 * @example
 * microservices.get('/orders')
 * microservices.put('/baskets')
 * microservices.orders.get('/')
 * microservices.baskets.post('/')
 */
const microservices = axios.create(baseConfig);
microservices.interceptors.request.use(requestInterceptor);

for (const [name, path] of Object.entries(resources)) {
    microservices[name] = axios.create({
        ...baseConfig,
        baseURL: `${baseURL}${path}`,
    });
    microservices[name].interceptors.request.use(requestInterceptor);
}

export default microservices;
