import { Button } from '@rmwc/button';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PaymentOptionsTable from '../../components/payment-options/PaymentOptionsTable';
import Loader from '../../layouts/admin/Loader';
import { paymentOptionsActions } from '../../redux/actions/paymentOptions';
import { createLoadingSelector } from '../../redux/selectors/loader';
import * as styles from './index.module.scss';

const PaymentOptionsPage = props => {
    const { getPaymentOptions } = props;
    useEffect(() => {
        getPaymentOptions();
    }, [getPaymentOptions]);

    const onReturnClick = () => {
        window.location.href = '/';
    };

    if (props.loading === true || !props.paymentOptions) {
        return <Loader />;
    }

    return (
        <>
            <div className={`container-fluid ${styles.paymentoptionsListHeader}`}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="float-left">
                            <button
                                data-testid="payment-options-goback-btn"
                                className={`btn btn-default ${styles.returnBtn}`}
                                onClick={e => onReturnClick(e)}
                            >
                                Go back
                            </button>
                            <Link to="/payment/new">
                                <Button
                                    className={`${styles.leftSpacingBtn} ${styles.createPaymentoptionsBtn}`}
                                    raised={true}
                                >
                                    Create payment option
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`container-fluid ${styles.paymentoptionsListTable}`}>
                <div className="row">
                    <div className={`col-md-12 ${styles.paymentoptionsListCore}`}>
                        <PaymentOptionsTable
                            paymentOptions={props.paymentOptions}
                            currentUser={props.currentUser}
                            history={props.history}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    const loadingSelector = createLoadingSelector(['(PaymentOptions) LIST', 'GET_PAYMENT_OPTIONS']);
    return {
        paymentOptions: state.paymentOptions.paymentOptions,
        currentUser: state.auth.currentUser,
        loading: loadingSelector(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getPaymentOptions: () => dispatch(paymentOptionsActions.getPaymentOptions()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOptionsPage);
