import { orderConstants } from '../actions/types';

const initialState = {
    order: null,
    currentVendor: null,
    vendorOrder: null,
    amendingOrder: false,
    orderAmendment: null,
    groupOrderID: null,
    vendorOrderID: null,
};

export function orderReducer(state = initialState, action) {
    switch (action.type) {
        case orderConstants.GET_ORDER_REQUEST: {
            return initialState;
        }
        case orderConstants.GET_ORDER_SUCCESS: {
            const vendors = Object.keys(action.order.items);
            const refreshedOrder = state.order && action.order.ID === state.order.ID;
            const vendor = refreshedOrder ? state.currentVendor : vendors[0];
            return {
                ...state,
                order: action.order,
                currentVendor: vendor,
                vendorOrder: action.order.items[vendor],
            };
        }
        case orderConstants.CHANGE_VENDOR: {
            return {
                ...state,
                currentVendor: action.vendor,
                vendorOrder: state.order.items[action.vendor],
                amendingOrder: false,
                orderAmendment: null,
            };
        }
        case orderConstants.SET_AMENDING_ORDER: {
            const orderAmendment =
                action.amending === false
                    ? null
                    : {
                          groupOrderID: state.order.ID,
                          vendorOrderID: state.vendorOrder.orderNumber,
                          items: [],
                          note: '',
                      };
            return {
                ...state,
                amendingOrder: action.amending,
                orderAmendment: orderAmendment,
            };
        }
        case orderConstants.SET_AMENDING_ORDER_ITEM: {
            const amendItems = setAmendingOrderItem(action, state.orderAmendment.items);
            return {
                ...state,
                orderAmendment: {
                    ...state.orderAmendment,
                    items: amendItems,
                },
            };
        }
        case orderConstants.UPDATE_ORDER_ITEM_AMENDMENT: {
            const updatedItems = [...state.orderAmendment.items].map(amend => {
                const skuFieldToCheck = 'sku';
                const skuMatch = action.amendment[skuFieldToCheck] === amend[skuFieldToCheck];
                const typeMatch = action.amendment.type === amend.type;
                // If the sku and type are correct return the new amendment
                return skuMatch && typeMatch ? action.amendment : amend;
            });
            return {
                ...state,
                orderAmendment: {
                    ...state.orderAmendment,
                    items: updatedItems,
                },
            };
        }
        case orderConstants.PROCESS_ORDER_AMENDMENT_SUCCESS:
        case orderConstants.ADD_NOTE_ORDER_SUCCESS:
        case orderConstants.REFUND_ORDER_DELIVERY_METHOD_SUCCESS:
        case orderConstants.GET_ORDER_DELIVERY_OPTION_SUCCESS:
        case orderConstants.ORDER_ACTION_SUCCESS: {
            return {
                ...state,
                amendingOrder: false,
                orderAmendment: null,
            };
        }
        default: {
            return state;
        }
    }
}

function setAmendingOrderItem(action, items) {
    const newItems = [...items];

    // Remove old amend from array
    const skuFieldToCheck = action.amendType === 'exchange' ? 'returnSku' : 'sku';
    const removeIndex = newItems.findIndex(
        amend => amend.type === action.amendType && amend[skuFieldToCheck] === action.item.ID,
    );
    if (-1 !== removeIndex) {
        newItems.splice(removeIndex, 1);
    }

    // Add new amend if set to true
    if (action.value === true) {
        const amend = {
            type: action.amendType,
            quantity: 0,
            reasonCode: '',
        };

        switch (action.amendType) {
            case 'return':
            case 'refund':
                amend.sku = action.item.ID;
                break;
            default:
        }

        newItems.push(amend);
    }

    return newItems;
}
