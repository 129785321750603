const statuses = {
    new: {
        statusName: 'new',
        className: 'primary',
        bkgColor: 'blue',
    },
    orderPicked: {
        statusName: 'picked',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    partiallyDispatched: {
        statusName: 'partially fulfilled',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    partiallyFulfilled: {
        statusName: 'partially fulfilled',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    dispatched: {
        statusName: 'dispatched',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    accepted: {
        statusName: 'accepted',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    fulfilled: {
        statusName: 'fulfilled',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    awaitingPick: {
        statusName: 'awaiting pick',
        className: 'primary',
        bkgColor: 'blue',
    },
    storeDelivered: {
        statusName: 'delivered',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    storeCollected: {
        statusName: 'collected',
        className: 'primary',
        bkgColor: 'aquaMarine',
    },
    storeDispatched: {
        statusName: 'dispatched',
        className: 'primary',
        bkgColor: 'blue',
    },
    storeExpired: {
        statusName: 'expired',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    cancelled: {
        statusName: 'cancelled',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    rejected: {
        statusName: 'rejected',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    customerReturn: {
        statusName: 'customer return',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    systemRejected: {
        statusName: 'system rejected',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    'refund-return': {
        statusName: 'refunded',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    refund: {
        statusName: 'refunded',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    return: {
        statusName: 'returned',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
    returned: {
        statusName: 'returned',
        className: 'danger',
        bkgColor: 'dustyMarine',
    },
};

export default statuses;

export function lookupStatus(status) {
    if (statuses[status]) {
        return statuses[status];
    }

    return {
        statusName: status,
        className: 'primary',
        bkgColor: 'mediumOrchid',
    };
}
