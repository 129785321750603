import './App.scss';
import '../src/pages/auth/Login.scss';

import DateUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Provider } from 'react-redux';

import RoutesContainer from './auth/components/RoutesContainer';
import store from './redux/store';
import Routes from './Routes';
import { ThemeProvider } from './theme';

const App = () => {
    return (
        <ThemeProvider>
            <MuiPickersUtilsProvider utils={DateUtils}>
                <main className="full-height">
                    <Provider store={store}>
                        <RoutesContainer>
                            <Routes store={store} />
                        </RoutesContainer>
                    </Provider>
                </main>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

export default App;
