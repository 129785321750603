import './403.scss';

import { Icon as MaterialIcon } from '@rmwc/icon';
import React from 'react';

const Page403 = () => {
    return (
        <div className="page-403">
            <h1>403</h1>
            <MaterialIcon icon="lock" />
            <p>Access Denied</p>
        </div>
    );
};

export default Page403;
