export const selectPageNumber = (pageId, numericPage, pageCount) => {
    let changeMade = false,
        newPage = null;

    switch (pageId) {
        case 'firstPage':
            newPage = 1;
            changeMade = true;
            break;
        case 'prevPage':
            if (numericPage !== 1) {
                newPage = numericPage - 1;
                changeMade = true;
            }
            break;
        case 'nextPage':
            if (numericPage !== pageCount) {
                newPage = numericPage + 1;
                changeMade = true;
            }
            break;
        case 'lastPage':
            if (numericPage !== pageCount) {
                newPage = pageCount;
                changeMade = true;
            }
            break;
        default:
            break;
    }

    return { newPage, changeMade };
};
