import MaterialTable from '@material-table/core';
import React from 'react';

const DeliveryOptionsTable = ({ currentUser, deliveryOptions, toggleActive, history }) => {
    const actions = [];

    if (currentUser.permissions.includes('deliveryoptions--update')) {
        actions.push({
            icon: 'edit',
            tooltip: 'Edit Delivery Method',
            onClick: (e, deliveryOption) => history.replace(`/delivery/${deliveryOption.ID}`),
        });
    }

    const getDeliveryOptionByID = id =>
        deliveryOptions.filter(({ ID: deliveryOptionID }) => deliveryOptionID === id);

    const toggleDeliveryOptionActiveStatus = ({ ID }) => {
        const foundDelivery = getDeliveryOptionByID(ID);
        if (!foundDelivery.length) {
            return;
        }
        toggleActive(foundDelivery.pop());
    };

    const __renderStatusBtn = ({ ID, active }) => {
        let className = '',
            displayText = '',
            bkgColor = '';

        const styles = {
            bkgColorAquaMarine: {
                backgroundColor: '#4bd4b0',
            },
            bkgColorDustyMarine: {
                backgroundColor: '#ff6633',
            },
            bkgColorBlue: {
                backgroundColor: '#65abe9',
            },
            statusBtn: {
                width: 168,
                height: 20,
                borderRadius: 15,
                fontSize: 10,
                letterSpacing: 2,
                color: '#fff',
                border: 'none',
                backgroundColor: '#535a6b',
                lineHeight: 1,
                fontWeight: 'bold',
            },
            circle: {
                width: 10,
                height: 10,
                borderRadius: 10,
                float: 'left',
                clear: 'left',
                marginRight: 7,
                marginLeft: -7,
            },
        };

        if (active === undefined || active === false) {
            bkgColor = styles.bkgColorDustyMarine;
            displayText = 'inactive';
            className = 'danger';
        } else {
            bkgColor = styles.bkgColorAquaMarine;
            displayText = 'active';
            className = 'primary';
        }

        return (
            <button
                className={`btn btn-${className}`}
                style={styles.statusBtn}
                onClick={() => toggleDeliveryOptionActiveStatus({ ID })}
            >
                <div className="float-left">
                    <div style={{ ...styles.circle, ...bkgColor }} />
                    {displayText.toUpperCase()}
                </div>
            </button>
        );
    };

    return (
        <div className="table-wrapper">
            <MaterialTable
                title=""
                columns={[
                    {
                        title: 'FASCIA',
                        field: 'fascia',
                        searchable: true,
                        defaultGroupOrder: 0,
                    },
                    {
                        title: 'ID',
                        field: 'ID',
                        hidden: true,
                    },
                    {
                        title: 'CARRIER CODE',
                        field: 'carrierCode',
                        searchable: true,
                    },
                    {
                        title: 'NAME',
                        field: 'name',
                        searchable: true,
                    },
                    {
                        title: 'PRICE',
                        field: 'price',
                        searchable: true,
                        render: ({ price }) => `£${price}`,
                    },
                    {
                        title: 'CHECKOUT DELIVERY PROMISE',
                        field: 'description',
                        searchable: true,
                    },
                    {
                        title: 'PDP DELIVERY PROMISE',
                        field: 'pdpPromise',
                        searchable: true,
                    },
                    {
                        title: 'ANATWINE STATUS',
                        field: 'networkGroups',
                        render: ({ networkGroups }) =>
                            networkGroups && networkGroups.length ? 'On' : 'Off',
                        sorting: true,
                    },
                    {
                        title: 'POSTCODE RESTRICTED',
                        field: 'postcodeRestricted',
                        render: ({ postcodeRestricted }) => (postcodeRestricted ? 'Yes' : 'No'),
                        sorting: true,
                    },
                    {
                        title: 'STATUS',
                        field: 'status',
                        render: deliveryOption => __renderStatusBtn(deliveryOption),
                        sorting: false,
                    },
                ]}
                data={deliveryOptions}
                actions={actions}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    maxColumnSort: 1,
                    idSynonym: 'ID',
                }}
            />
        </div>
    );
};

export default DeliveryOptionsTable;
