import { paymentOptionsConstants } from '../actions/types';

const initialState = {
    paymentOptions: [],
    paymentOption: {
        updated: 0,
        created: 0,
        isAnatwine: false,
        updatedBy: '',
        isEnabled: false,
        name: '',
        paymentType: '',
    },
};

export function paymentOptionsReducer(state = initialState, action) {
    switch (action.type) {
        case paymentOptionsConstants.RESET_PAYMENT_OPTION:
            return {
                paymentOption: initialState.paymentOption,
            };

        case paymentOptionsConstants.LIST_SUCCESS:
            return {
                ...state,
                paymentOptions: action.paymentOptions,
            };

        case paymentOptionsConstants.DUPLICATE_SUCCESS:
        case paymentOptionsConstants.SAVE_SUCCESS:
        case paymentOptionsConstants.GET_SUCCESS: {
            delete action.paymentOption.created;
            delete action.paymentOption.updated;
            return {
                ...state,
                paymentOption: action.paymentOption,
            };
        }
        case paymentOptionsConstants.TOGGLE_ACTIVE_SUCCESS: {
            const paymentOptions = state.paymentOptions.map(option => {
                if (option.paymentType === action.paymentOption.paymentType) {
                    return { ...option, ...action.paymentOption };
                }
                return option;
            });
            return {
                ...state,
                paymentOptions,
            };
        }
        case paymentOptionsConstants.UPDATE_STATE: {
            return {
                ...state,
                paymentOption: {
                    ...state.paymentOption,
                    [action.name]: action.value,
                },
            };
        }
        default: {
            return state;
        }
    }
}
