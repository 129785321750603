import { Button } from '@rmwc/button';
import React from 'react';

const Header = ({
    openDuplicateModal,
    onReturnClick,
    openDeleteModal,
    onCancelClick,
    onSaveClick,
    changeMade,
    showDuplicate = true,
}) => (
    <header className="col-md-12">
        <div className="float-left">
            <button
                className="btn btn-default inline-block return-btn"
                onClick={e => onReturnClick(e)}
            >
                Go back
            </button>

            {showDuplicate && (
                <Button
                    className="left-spacing-btn duplicate-deliveryoptions-btn"
                    onClick={() => openDuplicateModal(true)}
                    raised={true}
                >
                    Duplicate
                </Button>
            )}
        </div>
        <div className="float-right">
            <Button
                className="right-spacing-btn cancel-deliveryoptions-btn"
                onClick={e => onCancelClick(e)}
                disabled={!changeMade}
                raised={true}
            >
                Cancel
            </Button>

            <Button
                className="right-spacing-btn delete-deliveryoptions-btn"
                onClick={() => openDeleteModal(true)}
                raised={true}
            >
                Delete
            </Button>

            <Button
                className="save-deliveryoptions-btn"
                onClick={onSaveClick}
                disabled={!changeMade}
                raised={true}
            >
                Save Changes
            </Button>
        </div>
    </header>
);

export default Header;
