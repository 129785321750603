import { errorConstants } from './types';

export const errorActions = {
    push,
    clear,
};

function push(error) {
    return { type: errorConstants.PUSH, error };
}
function clear() {
    return { type: errorConstants.CLEAR };
}
