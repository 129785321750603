import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

const DangerButton = ({ className = '', children = null, ...props }) => {
    const classes = useStyles();

    return (
        <Button
            className={clsx(className, classes.button, classes.dangerButton)}
            variant="contained"
            disableElevation
            {...props}
        >
            {children}
        </Button>
    );
};

export default DangerButton;
