import { useEffect } from 'react';
import { connect } from 'react-redux';

import { authActions } from '../../redux/actions/auth';

const RoutesContainer = ({ children, loadSignedInUser }) => {
    useEffect(() => {
        loadSignedInUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return children;
};

const mapDispatchToProps = dispatch => {
    return {
        loadSignedInUser: () => dispatch(authActions.loadSignedInUser()),
    };
};

export default connect(null, mapDispatchToProps)(RoutesContainer);
