import './StatusModal.scss';

import { format } from 'date-fns';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { lookupStatus } from '../../../data/statusConfig';
import { capitalizeWords } from '../../../utils/formatString';

class StatusModal extends Component {
    state = {
        showOrderModal: false,
        showMoreInfo: false,
    };

    modalRoot = document.getElementById('statusModal');
    el = document.createElement('div');

    componentDidMount() {
        this.modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.el);
    }

    formatDate = date => format(new Date(date), 'dd/MM/yyyy HH:mm:ss');

    renderItems = () => {
        const { showMoreInfo } = this.state;
        return this.props.orderHistory
            .map(({ status, updated, metapackCarrierCode, metapackConsignmentCode }, index) => {
                const { statusName } = lookupStatus(status);
                return (
                    <div key={index} className="update">
                        <div className="line-wrapper">
                            <div className="title">New Status:</div>
                            <div className="medium">{capitalizeWords(statusName)}</div>
                        </div>
                        <div className="line-wrapper">
                            <div className="title">Update Date:</div>
                            <div>{this.formatDate(updated)}</div>
                        </div>
                        {metapackCarrierCode && (
                            <div className="more-info">
                                <div
                                    className="line-wrapper heading"
                                    onClick={() => this.setState({ showMoreInfo: !showMoreInfo })}
                                >
                                    <div className="medium more-info">More Information</div>
                                    <span className="open-close">{showMoreInfo ? '-' : '+'}</span>
                                </div>
                                {showMoreInfo && (
                                    <>
                                        <div className="line-wrapper">
                                            <div className="title">Metapack Carrier Code:</div>
                                            {metapackCarrierCode}
                                        </div>
                                        <div className="line-wrapper">
                                            <div className="title">Metapack Consignment Code:</div>
                                            {metapackConsignmentCode}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                );
            })
            .reverse();
    };

    render() {
        const { modalActive } = this.props;

        const { showOrderModal } = this.state;

        return ReactDOM.createPortal(
            <>
                <div
                    className={`order-status-background ${showOrderModal && 'modal-open'}`}
                    onClick={() => modalActive()}
                ></div>
                <div className="order-status-modal">
                    <div className="modal-header">
                        <h4 className="title">ORDER UPDATES</h4>
                    </div>
                    <div className="content">{this.renderItems()}</div>
                    <button className="btn cancel-order-btn" onClick={() => modalActive()}>
                        Close
                    </button>
                </div>
            </>,
            this.el,
        );
    }
}

export default StatusModal;
