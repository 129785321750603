import './index.scss';

import React from 'react';
import { connect } from 'react-redux';

import { menuItems } from '../../data';
import history from '../../history';

const Dashboard = ({ currentUser }) => {
    const allowedMenus =
        currentUser !== null
            ? menuItems.filter(({ permission, title }) => {
                  let hasPermissionIfRequired = null;

                  if (permission) {
                      if (currentUser.permissions.includes(permission)) {
                          hasPermissionIfRequired = currentUser.permissions.includes(permission);
                      } else {
                          hasPermissionIfRequired = false;
                      }
                  }

                  return (
                      hasPermissionIfRequired !== false && title !== 'Home' && title !== 'Logout'
                  );
              })
            : [];
    return (
        <div className="dashboard-page">
            <div className="dashboard-grid">
                {allowedMenus.map((menu, index) => (
                    <div
                        className="dashboard-grid__menu"
                        key={index}
                        onClick={() => history.push(menu.url)}
                    >
                        <h4>{menu.title}</h4>
                        {menu.dashboardIcon ? (
                            <img
                                src={menu.dashboardIcon}
                                className="dashboard-icon"
                                style={{ marginTop: 30 }}
                                alt=""
                            />
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        currentUser: state.auth.currentUser,
    };
}

export default connect(mapStateToProps)(Dashboard);
