import './DeliveryOption.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import networkGroups from '../../config/networkGroups';
import ActionConfirmModal from '../../layouts/admin/ActionConfirmModal';
import Loader from '../../layouts/admin/Loader';
import { deliveryOptionsActions } from '../../redux/actions/deliveryOptions';
import { utilsActions } from '../../redux/actions/utils';
import { createLoadingSelector } from '../../redux/selectors/loader';
import DeliveryOptionPage from './DeliveryOptionPage';
import Header from './Header';

const editDeliverySchema = {
    carrierCode: '',
    code: '',
    name: '',
    description: '',
    pdpPromise: '',
    deliveryType: '',
    price: 0,
    cutOffTime: '',
    active: false,
    postcodeRestricted: undefined,
    fascia: '',
};

networkGroups.forEach(networkGroup => {
    editDeliverySchema['anatwine-' + networkGroup.id] = false;
});

const formatPrice = value => {
    let result = value.replace(/[^0-9.]/g, '');
    let dotFound = false;
    result = [...result]
        .map(c => {
            if (c === '.') {
                if (dotFound) {
                    return '';
                } else {
                    dotFound = true;
                }
            }
            return c;
        })
        .join('');
    return result;
};

const validateDeliveryOption = (deliveryOption, fascias) => {
    const errors = [];
    if (!deliveryOption.carrierCode || deliveryOption.carrierCode.includes('_')) {
        errors.push('Carrier code value is not valid');
    }
    if (!deliveryOption.name) {
        errors.push('Name value is not valid');
    }
    if (!deliveryOption.code || !deliveryOption.code.length) {
        errors.push('Code value is not valid');
    }

    if (!deliveryOption.deliveryType) {
        errors.push('Delivery type value is not valid');
    }
    if (!deliveryOption.price && deliveryOption.price !== 0) {
        errors.push('Price value is not valid');
    }
    if (!deliveryOption.description) {
        errors.push('Checkout Delivery Promise value is not valid');
    }

    console.log('deliveryOption', deliveryOption);
    console.log('deliveryOption.fascia', deliveryOption.fascia);
    if (
        !deliveryOption.fascia ||
        !fascias.find(validFascia => validFascia === deliveryOption.fascia)
    ) {
        errors.push('Fascia value is not valid');
    }
    return errors;
};

const isNewDeliveryOption = match => (match?.params?.id ? false : true);

const DeliveryOption = props => {
    const { getFascias, deliveryOption, getDeliveryOption, resetDeliveryOption, fascias, match } =
        props;

    const [editDelivery, setEditDelivery] = useState(editDeliverySchema);
    const [changeMade, setChangeMade] = useState(false);
    const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        getFascias();
    }, [getFascias]);

    useEffect(() => {
        const isCreateDeliveryOption = isNewDeliveryOption(match);
        isCreateDeliveryOption ? resetDeliveryOption() : getDeliveryOption(match.params.id);
        setIsNew(isCreateDeliveryOption);
    }, [getDeliveryOption, match, resetDeliveryOption]);

    useEffect(() => {
        deliveryOption && setEditDelivery(JSON.parse(JSON.stringify(deliveryOption)));
    }, [deliveryOption]);

    useEffect(() => {
        if (!editDelivery || JSON.stringify(deliveryOption) === JSON.stringify(editDelivery)) {
            setErrorMessages([]);
            setChangeMade(false);
            return;
        }

        const errors = validateDeliveryOption(editDelivery, fascias);
        if (errors.length < 3) {
            setErrorMessages(errors);
        } else {
            setErrorMessages([]);
        }
        setChangeMade(errors.length > 0 ? false : true);
    }, [editDelivery, deliveryOption, fascias]);

    const onFieldChange = (name, value) => {
        if (!Object.keys(editDeliverySchema).find(n => n === name)) {
            return;
        }
        let formattedValue = value;
        if (name === 'price') {
            formattedValue = formatPrice(value);
        }
        setEditDelivery({ ...editDelivery, ...{ [name]: formattedValue } });
    };

    const onReturnClick = () => {
        window.location.href = '/delivery';
    };

    const onDuplicateClick = () => {
        const { duplicateDeliveryOption } = props;
        setOpenDuplicateModal(false);
        duplicateDeliveryOption(editDelivery);
    };

    const onCancelClick = () => {
        if (JSON.stringify(deliveryOption) !== JSON.stringify(editDelivery)) {
            if (match?.params?.id) {
                getDeliveryOption(match.params.id);
            }
        }
    };

    const onDeleteClick = () => {
        const { removeDeliveryOption } = props;
        setOpenDeleteModal(false);
        removeDeliveryOption(editDelivery);
    };

    const onSaveClick = () => {
        const { updateDeliveryOption, createDeliveryOption } = props;
        if (match?.params?.id) {
            updateDeliveryOption(editDelivery);
        } else {
            createDeliveryOption({
                ...editDelivery,
                ...{ fascia: editDelivery.fascia },
            });
        }
    };

    const { loading } = props;

    return loading || !editDelivery ? (
        <Loader />
    ) : (
        <div className="container-fluid deliverypage-container">
            <div className="row">
                <Header
                    openDuplicateModal={setOpenDuplicateModal}
                    onReturnClick={onReturnClick}
                    openDeleteModal={setOpenDeleteModal}
                    onCancelClick={onCancelClick}
                    onSaveClick={onSaveClick}
                    changeMade={changeMade}
                    showDuplicate={!isNew}
                />
            </div>

            <div className="row">
                {errorMessages.length > 0 && (
                    <p
                        style={{
                            background: '#b00020',
                            color: '#fff',
                            padding: '5px',
                            marginTop: '5px',
                            borderRadius: '3px',
                        }}
                    >
                        {errorMessages.join(', ')}
                    </p>
                )}
            </div>

            <DeliveryOptionPage
                onFieldChange={onFieldChange}
                onReturnClick={onReturnClick}
                editDelivery={editDelivery}
            />
            {/* modals */}
            <ActionConfirmModal
                onActionConfirmed={onDuplicateClick}
                setModalOpen={setOpenDuplicateModal}
                modalOpen={openDuplicateModal}
                title={'Duplicate Delivery Option'}
            />
            <ActionConfirmModal
                onActionConfirmed={onDeleteClick}
                setModalOpen={setOpenDeleteModal}
                modalOpen={openDeleteModal}
                title={'Delete Delivery Option'}
            />
        </div>
    );
};

function mapStateToProps(state) {
    const loadingSelector = createLoadingSelector([
        '(DeliveryOptions) GET',
        '(DeliveryOptions) CREATE',
        '(DeliveryOptions) RESET_STATE',
    ]);
    return {
        deliveryOption: state.deliveryOptions.deliveryOption,
        currentUser: state.auth.currentUser,
        loading: loadingSelector(state),
        fascias: state.utils.fascias,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryOption: id => dispatch(deliveryOptionsActions.getDeliveryOption(id)),
        createDeliveryOption: deliveryOption => {
            dispatch(deliveryOptionsActions.createDeliveryOption(deliveryOption));
        },
        resetDeliveryOption: () => {
            dispatch(deliveryOptionsActions.resetDeliveryOption());
        },
        updateDeliveryOption: deliveryOption =>
            dispatch(deliveryOptionsActions.updateDeliveryOption(deliveryOption)),
        toggleActive: (deliveryOption, active) =>
            dispatch(deliveryOptionsActions.toggleActive(deliveryOption, active)),
        duplicateDeliveryOption: deliveryOption =>
            dispatch(deliveryOptionsActions.duplicateDeliveryOption(deliveryOption)),
        removeDeliveryOption: deliveryOption =>
            dispatch(deliveryOptionsActions.removeDeliveryOption(deliveryOption)),
        getFascias: () => dispatch(utilsActions.getFascias()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOption);
