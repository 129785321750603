// @ts-check
import {
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import React, { useState } from 'react';

const ROWS_PER_PAGE = 12;

const DevicesTable = ({ devices, onEdit }) => {
    const classes = useTableStyles();

    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => setPage(newPage);

    /**
     @desc The number of empty rows to add to the current page
     @see  https://material-ui.com/components/tables/#CustomPaginationActionsTable.js
     */
    const emptyRows =
        ROWS_PER_PAGE - Math.min(ROWS_PER_PAGE, devices.length - page * ROWS_PER_PAGE);
    const currentPageDevices = devices.slice(
        page * ROWS_PER_PAGE,
        page * ROWS_PER_PAGE + ROWS_PER_PAGE,
    );

    const userCanEditDevices = true; // TODO: currentUser.permissions.includes('devices--edit');

    return (
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} stickyHeader size="small">
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell>STORE</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell>TYPE</TableCell>
                        <TableCell>ENABLED</TableCell>
                        <TableCell>FASCIA</TableCell>
                        <TableCell align="right">{userCanEditDevices ? 'EDIT' : ''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="classes.tableBody">
                    {currentPageDevices.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                No Devices Available
                            </TableCell>
                        </TableRow>
                    )}
                    {currentPageDevices.map(row => (
                        <TableRow key={row.ID} hover className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                                {row.locationID}
                            </TableCell>
                            <TableCell>{row.ID}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell align="left">
                                {/* If enabled is true, use the char code for a tick. If false, use the char code for a cross. If undefined, show neither */}
                                {row.enabled
                                    ? String.fromCharCode(10003)
                                    : row.enabled == false
                                    ? String.fromCharCode(215)
                                    : ''}
                            </TableCell>
                            <TableCell>{row.fascia}</TableCell>
                            <TableCell align="right">
                                {userCanEditDevices && (
                                    <IconButton
                                        onClick={() =>
                                            onEdit({ ID: row.ID, locationID: row.locationID })
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={5} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={devices.length}
                            rowsPerPage={ROWS_PER_PAGE}
                            rowsPerPageOptions={[]}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

export default DevicesTable;

const useTableStyles = makeStyles({
    tableContainer: {
        height: '100%',
        '& th, & td': {
            fontFamily: 'Gotham SSm A',
            fontSize: 14,
            fontWeight: 400,
        },
        '& th, & td:first-child': {
            fontWeight: 500,
        },
    },
    table: {
        minWidth: 650,
    },
    tableHeader: {
        '& th': {
            backgroundColor: 'transparent',
        },
    },
    tableRow: {
        '& td, & th': {
            height: 60,
        },
    },
});
