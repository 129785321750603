import './OrderPageItem.scss';

import getSymbolFromCurrency from 'currency-symbol-map';
import React, { Component, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';

import blacksIcon from '../../../icons/brands/Blacks.png';
import footpatrolIcon from '../../../icons/brands/Footpatrol.png';
import jdIcon from '../../../icons/brands/JD.png';
import scottsIcon from '../../../icons/brands/Scotts2.png';
import sizeIcon from '../../../icons/brands/Size.png';
import tessutiIcon from '../../../icons/brands/Tessuti.png';
import returnIcon from '../../../icons/Returns.png';
import { getOrderStatusBtn, getVendorOrderStatusBtn } from '../statusButton';
import { getOriginalTotal, getTotalFaciaRefunds } from './calculations';
import StatusModal from './StatusModal';

class OrderPageItem extends Component {
    state = {
        showOrderModal: false,
        showMoreInfo: false,
    };

    componentDidMount() {
        this.resizeHandlingEvent();
        window.addEventListener('resize', this.resizeHandlingEvent.bind(this));
    }

    resizeHandlingEvent() {
        if (window.innerWidth < 1469) {
            this.shrinkActionSelectSize(true);
        } else {
            this.shrinkActionSelectSize(false);
        }
    }

    /**
     * Shrink table action select form inputs so that table
     * elements have required spacing on small device screens.
     * @param { boolean } toShrink Whether we shrink elements or not.
     */
    shrinkActionSelectSize(toShrink) {
        if (toShrink === true) {
            this.applyActionSelectStyles('max-width', '20px');
        } else {
            this.applyActionSelectStyles('max-width', 'none');
        }
    }

    /**
     * Apply css styling to action select form inputs.
     * @param { string } name Name of the css attribute to apply.
     * @param { string | number } value Value of the css attribute to apply.
     */
    applyActionSelectStyles(name, value) {
        const action1ClassEls = document.querySelectorAll('.action-1');
        const action2ClassEls = document.querySelectorAll('.action-2');

        if (action1ClassEls.length) {
            action1ClassEls.forEach(el => {
                el.style[name] = value;
            });
        }
        if (action2ClassEls.length) {
            action2ClassEls.forEach(el => {
                el.style[name] = value;
            });
        }
    }

    getItemsLogo = itemBrand => {
        let image = '';

        switch (itemBrand) {
            case 'jdsports':
                image = (
                    <img
                        src={jdIcon}
                        alt="JD Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'jdsportsuk':
                image = (
                    <img
                        src={jdIcon}
                        alt="JD Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'footpatrol':
                image = (
                    <img
                        src={footpatrolIcon}
                        alt="FootPatrol Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'scotts':
                image = (
                    <img
                        src={scottsIcon}
                        alt="Scotts Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'size':
                image = (
                    <img
                        src={sizeIcon}
                        alt="Size Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'tessuti':
                image = (
                    <img
                        src={tessutiIcon}
                        alt="Tessuti Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'blacks':
                image = (
                    <img
                        src={blacksIcon}
                        alt="Blacks Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'millets':
                image = (
                    <img
                        src={blacksIcon}
                        alt="Millets Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            case 'ultimateoutdoors':
                image = (
                    <img
                        src={blacksIcon}
                        alt="Ultimate Outdoors Logo"
                        className="img-responsive order-notes-company-logo"
                    />
                );
                break;
            default:
                break;
        }

        return image;
    };

    getLinesQuantity = lines => {
        let result = 0;

        Object.keys(lines).forEach(lineNumber => {
            const amendItems = lines[lineNumber].amends || [];

            if (amendItems.length > 0) {
                result++;
            }
        });

        return result;
    };

    handleItemLogic = order => {
        let {
            item: { items },
        } = this.props;

        items.forEach(item => {
            item.media[0].location = item.media[0].location.replace('http://', 'https://');
        });

        const { ID, eanCode, lines, originalLines, shogunSku, quantity, updates } = order;
        const skuFromID = ID.split('_').pop();
        let quantityRemaining = quantity;
        let lineStatus;
        if (typeof lines === 'undefined') {
            // have there been any updates for this eanCode or sku? If no, then the status is 'new'
            if (
                !updates
                    .flatMap(({ eanCode, sku }) => [eanCode, sku])
                    .filter(Boolean)
                    .some(eanCodeOrSku => [eanCode, shogunSku, skuFromID].includes(eanCodeOrSku))
            ) {
                lineStatus = 'new';
            } else if (items.length > 1) {
                // if there's more than 1 item on this facia order, use the item line status for the item
                const [{ status }] = Object.values(originalLines);
                if (status) {
                    lineStatus = status;
                }
            }
            return [{ ...order, quantityRemaining, originalQuantity: quantity, lineStatus }];
        }

        let firstRow = order;
        let linesQuantity = this.getLinesQuantity(originalLines);
        quantityRemaining -= linesQuantity;

        const firstRowQuantity = Math.max(0, quantity - linesQuantity);

        firstRow.originalQuantity = firstRow.quantity;
        firstRow.quantity = firstRowQuantity;
        if (Object.keys(lines)[0]) {
            firstRow.lineStatus = lines[Object.keys(lines)[0]].status;
        }

        delete firstRow.lines;

        const itemData = [];
        itemData.push(Object.assign({}, { ...firstRow, quantityRemaining }));
        delete firstRow.quantity;

        let consecutiveRows = Object.assign({}, firstRow);

        Object.keys(lines).forEach(lineNumber => {
            const lineItems = lines[lineNumber].amends;

            if (lineItems && lineItems.length > 0) {
                for (let i = 0; i < lineItems.length; i++) {
                    lineItems[i] = {
                        ...lineItems[i],
                        line: lineNumber,
                    };
                }

                itemData.push({
                    ...consecutiveRows,
                    lines: lineItems,
                    quantity: lineItems[0].quantity,
                });
            }
        });

        return itemData;
    };

    getItemRows = (
        {
            ID,
            eanCode,
            lines,
            brand,
            facia,
            media,
            name,
            optionType,
            optionValue,
            originalLines,
            prices,
            productID,
            shogunSku,
            quantity,
        },
        { orderNumber, updates },
    ) => {
        const { onRefundReturnActionChange, getRefundEdit, getRefundShow, order, itemBrand } =
            this.props;

        const itemData = this.handleItemLogic({
            ID,
            eanCode,
            lines,
            brand,
            facia,
            media,
            name,
            optionType,
            optionValue,
            originalLines,
            prices,
            productID,
            quantity,
            shogunSku,
            updates,
        });

        let rows = [];

        itemData.forEach(
            ({
                ID,
                lines,
                lineStatus,
                media,
                name,
                optionValue,
                prices,
                quantity,
                quantityRemaining,
            }) => {
                rows.push(
                    <Fragment>
                        <td data-orderid={orderNumber} data-faciaid={ID}>
                            {typeof lines !== 'undefined' ? (
                                <img
                                    src={returnIcon}
                                    className="img-responsive return-icon"
                                    alt=""
                                />
                            ) : null}
                            {media && media[0] ? (
                                <img
                                    className="img-responsive order-item-detail__image"
                                    src={media[0].location + '?w=82'}
                                    alt={name}
                                />
                            ) : (
                                <span className="img-responsive order-item-detail__image">
                                    <small>No image available</small>
                                    <br />
                                </span>
                            )}
                            <span className="order-item-name">
                                {name}
                                <br />
                                <span className="order-item-size">
                                    Size:
                                    {'     '}
                                    {optionValue}
                                </span>
                            </span>
                        </td>
                        <td>
                            {prices.price > prices.discountPrice ? (
                                <Fragment>
                                    <CurrencyFormat
                                        className="order-item-detail__original-price"
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        displayType="text"
                                        value={prices.price / 100}
                                        prefix={getSymbolFromCurrency(prices.currency)}
                                    />
                                    &nbsp;
                                    <CurrencyFormat
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        displayType="text"
                                        value={prices.discountPrice / 100}
                                        prefix={getSymbolFromCurrency(prices.currency)}
                                    />
                                </Fragment>
                            ) : (
                                <CurrencyFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType="text"
                                    value={prices.price / 100}
                                    prefix={getSymbolFromCurrency(prices.currency)}
                                />
                            )}
                        </td>
                        <td>{quantity}</td>
                        <td>
                            <CurrencyFormat
                                fixedDecimalScale={true}
                                decimalScale={2}
                                thousandSeparator={true}
                                displayType="text"
                                value={(prices.discountPrice * quantity) / 100}
                                prefix={getSymbolFromCurrency(prices.currency)}
                            />
                        </td>
                        {typeof lines === 'undefined'
                            ? getRefundEdit(ID, quantityRemaining)
                            : getRefundShow(lines)}
                        <td className="status-table-data">
                            {typeof lines === 'undefined'
                                ? /* getOrderStatusBtn(order.items,order.payment,null,2) */
                                  getVendorOrderStatusBtn(order.items[itemBrand].status, lineStatus)
                                : getOrderStatusBtn(
                                      null,
                                      null,
                                      {
                                          lines,
                                          onInputChange: onRefundReturnActionChange,
                                      },
                                      2,
                                  )}
                        </td>
                    </Fragment>,
                );
            },
        );

        return rows;
    };

    toggleStatusModal = () => this.setState({ showOrderModal: !this.state.showOrderModal });

    render() {
        const { faciaDisplayText, itemBrand, item, order } = this.props;

        const totalRefunds = getTotalFaciaRefunds(order, itemBrand, true);

        const originalTotal = getOriginalTotal(order, itemBrand);

        const { showOrderModal } = this.state;

        const orderHistory = Object.values(order.items).find(
            order => order.orderNumber === item.orderNumber,
        ).updates;

        return (
            <Fragment>
                <div className="row order-item-header">
                    <div className="col-md-12">
                        <p>
                            <span>{this.getItemsLogo(itemBrand)}</span>
                            <span className="lead order-title">
                                FULFILLED BY {faciaDisplayText[itemBrand]}
                            </span>
                            <span className="lead order-number">#{item.orderNumber}</span>
                            <span
                                className="lead order-status"
                                onClick={() => this.toggleStatusModal()}
                            >
                                {getVendorOrderStatusBtn(item.status)}
                            </span>
                        </p>
                    </div>
                </div>
                {showOrderModal && (
                    <StatusModal orderHistory={orderHistory} modalActive={this.toggleStatusModal} />
                )}
                <div className="row order-item-table">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>ITEM (S)</td>
                                    <td>PRICE</td>
                                    <td>QTY</td>
                                    <td>TOTAL</td>
                                    <td>REFUND</td>
                                    <td />
                                    <td>STATUS</td>
                                </tr>
                            </thead>
                            <tbody>
                                {item.items.map(
                                    ({
                                        ID,
                                        eanCode,
                                        lines,
                                        brand,
                                        facia,
                                        media,
                                        name,
                                        optionType,
                                        optionValue,
                                        originalLines,
                                        prices,
                                        productID,
                                        shogunSku,
                                        quantity,
                                    }) => {
                                        let rows = this.getItemRows(
                                            {
                                                ID,
                                                eanCode,
                                                lines,
                                                brand,
                                                facia,
                                                media,
                                                name,
                                                optionType,
                                                optionValue,
                                                originalLines,
                                                prices,
                                                productID,
                                                shogunSku,
                                                quantity,
                                            },
                                            item,
                                        );

                                        return (
                                            <Fragment key={ID}>
                                                {rows.map((row, index) => (
                                                    <tr key={index}>{row}</tr>
                                                ))}
                                            </Fragment>
                                        );
                                    },
                                )}
                                <tr className="order-totals-row">
                                    <td colSpan={4}>TOTALS</td>
                                    <td>
                                        <div className="float-left">
                                            <span style={{ fontWeight: 'bold' }}>
                                                Original Total:
                                            </span>
                                        </div>
                                        <div className="float-right">
                                            <span style={{ fontWeight: 'bold' }}>
                                                <CurrencyFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    displayType="text"
                                                    value={originalTotal / 100}
                                                    prefix={getSymbolFromCurrency('GBP')}
                                                />
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="float-left">
                                            Total Refund:
                                            <br />
                                            <span style={{ fontWeight: 'bold' }}>
                                                Remaining Total:
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="float-right">
                                            <CurrencyFormat
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                displayType="text"
                                                value={totalRefunds / 100}
                                                prefix={getSymbolFromCurrency('GBP')}
                                            />
                                            <br />
                                            <span style={{ fontWeight: 'bold' }}>
                                                <CurrencyFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    thousandSeparator={true}
                                                    displayType="text"
                                                    value={
                                                        totalRefunds
                                                            ? order.items[itemBrand].totals.total /
                                                              100
                                                            : originalTotal / 100
                                                    }
                                                    prefix={getSymbolFromCurrency('GBP')}
                                                />
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default OrderPageItem;
