const networkGroups = [
    {
        id: 'UK_DS1_ANA_Nike',
        label: 'Nike',
    },
    {
        id: 'UK_DS1_ANA_Adidas',
        label: 'adidas',
    },
    {
        id: 'UK_DS1_ANA_Reebok',
        label: 'Reebok',
    },
    {
        id: 'UK_DS1_ANA_UNDERARMOUR',
        label: 'Under Armour',
    },
];

export default networkGroups;
