import './UserForm.scss';

import { Checkbox } from '@rmwc/checkbox';
import { Grid, GridCell as Cell, GridRow as Row } from '@rmwc/grid';
import { TextField, TextFieldHelperText as HelperText } from '@rmwc/textfield';
import React from 'react';

import permissions from './permissions.json';

const UserForm = ({ user, emailDisabled, loading, userChanged, cannotEditSelf }) => {
    return (
        <form noValidate autoComplete="off">
            <Grid>
                <Row>
                    <Cell desktop={6} phone={4} tablet={8}>
                        <TextField
                            label="First Name"
                            helpText={<HelperText>Enter users first name</HelperText>}
                            outlined={true}
                            className="full-width"
                            name="firstName"
                            onChange={handleInputChange}
                            value={user.firstName}
                            disabled={loading || cannotEditSelf}
                        />
                    </Cell>
                    <Cell desktop={6} phone={4} tablet={8}>
                        <TextField
                            label="Last Name"
                            helpText={<HelperText>Enter users last name</HelperText>}
                            outlined={true}
                            className="full-width"
                            name="lastName"
                            onChange={handleInputChange}
                            value={user.lastName}
                            disabled={loading || cannotEditSelf}
                        />
                    </Cell>
                </Row>
                <Row className="top-margin">
                    <Cell columns={12}>
                        <TextField
                            label="Email"
                            helpText={<HelperText>Enter users email</HelperText>}
                            outlined={true}
                            className="full-width"
                            name="email"
                            onChange={handleInputChange}
                            value={user.email}
                            disabled={loading || emailDisabled || cannotEditSelf}
                        />
                    </Cell>
                </Row>
                <Row className="top-margin">
                    <Cell columns={6} id="permissions">
                        <label>Permissions</label>
                        {user.permissions === undefined ? (
                            ''
                        ) : (
                            <div className="brand-wrapper">
                                <Checkbox
                                    id="all-permissions"
                                    onChange={toggleAllPermissions}
                                    checked={user.permissions.length === permissions.length}
                                    indeterminate={
                                        user.permissions.length > 0 &&
                                        user.permissions.length < permissions.length
                                    }
                                    disabled={cannotEditSelf}
                                />
                                <label htmlFor="all-permissions">All Permissions</label>
                            </div>
                        )}
                        {user.permissions === undefined
                            ? ''
                            : permissions.map(permission => (
                                  <div
                                      className="brand-wrapper"
                                      key={permission.id}
                                      style={{ marginLeft: 30 }}
                                  >
                                      <Checkbox
                                          value={permission.id}
                                          onChange={handlePermissionsChange}
                                          checked={user.permissions.includes(permission.id)}
                                          disabled={cannotEditSelf}
                                      />
                                      <label htmlFor={permission.id}>{permission.name}</label>
                                  </div>
                              ))}
                    </Cell>
                </Row>
            </Grid>
        </form>
    );

    function handleInputChange(event) {
        let { name, value } = event.target;

        if (name === 'email') {
            value = value.toLowerCase();
        }

        userChanged(name, value);
    }

    function handlePermissionsChange(event) {
        const { checked, value } = event.target;
        const permissions = user.permissions === undefined ? [] : user.permissions;
        let newPermissions = [...permissions];
        if (checked) {
            newPermissions.push(value);
        } else {
            newPermissions = newPermissions.filter(permission => {
                return permission !== value;
            });
        }
        userChanged('permissions', newPermissions);
    }

    function toggleAllPermissions(event) {
        const { checked } = event.currentTarget;
        const allPermissions = [...permissions].map(({ id }) => id);
        const newPermissions = checked ? allPermissions : [];
        userChanged('permissions', newPermissions);
    }
};

export default UserForm;
