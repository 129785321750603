import './ActionConfirmModal.scss';

import { Button } from '@rmwc/button';
import React from 'react';
import Modal from 'react-responsive-modal';

const ActionConfirmModal = ({ onActionConfirmed, setModalOpen, modalOpen, title }) => (
    <Modal
        open={modalOpen}
        onClose={() => {
            setModalOpen(false);
        }}
        classNames={{
            modal: 'action-confirm-modal-container',
            closeButton: 'action-confirm-close-btn',
        }}
        center
    >
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="lead font-weight-bold">{title}</h2>
                    <hr />
                    <p>Are you sure you want to continue?</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="float-right">
                        <Button
                            className="left-spacing-btn duplicate-deliveryoptions-btn"
                            onClick={e => onActionConfirmed(e)}
                            raised={true}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
);

export default ActionConfirmModal;
