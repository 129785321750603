import { handleNotFoundError, handleUnauthorizedError } from '../utils';
import microservices from './microservices';

export async function getDevices() {
    try {
        const { data } = await microservices.store.get('/locations/devices');
        return data.data;
    } catch (e) {
        handleUnauthorizedError(e);
        return [];
    }
}

export async function getDevice({ locationID, deviceID }) {
    try {
        const { data } = await microservices.store.get(
            `/locations/${locationID}/devices/${deviceID}`,
        );
        return data;
    } catch (e) {
        handleUnauthorizedError(e);
        return null;
    }
}

export async function addDevice(device) {
    device.fascia = device.fascia ?? 'jdsportsuk';
    try {
        const { data } = await microservices.store.post(
            `/locations/${device.locationID}/devices`,
            device,
        );

        return data;
    } catch (e) {
        handleUnauthorizedError(e);
        throw e;
    }
}

export async function updateDevice(device, params) {
    // Create a new object with non-empty values from the object
    const formattedDevice = Object.fromEntries(
        Object.entries(device).filter(v => {
            return v != null && v !== '';
        }),
    );

    try {
        const { data } = await microservices.store.put(
            `/locations/${params.locationID}/devices/${params.id}`,
            formattedDevice,
        );

        return data;
    } catch (e) {
        handleUnauthorizedError(e);
        return null;
    }
}

export async function deleteDevice(deviceID) {
    try {
        const { data } = await microservices.store.delete(`/devices/${deviceID}`);

        return data;
    } catch (e) {
        handleNotFoundError(e);
    }
}
