import { createTheme, ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import React from 'react';

export const theme = createTheme({
    palette: {
        primary: { main: '#00a88e', contrastText: '#ffffff' },
        secondary: { main: '#f7f7f7', dark: '#e7e7e7' },
        text: {
            primary: '#3e4555',
        },
        tonalOffset: 0.1,
    },
});

export const ThemeProvider = ({ children }) => (
    <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
);
