import './index.scss';

import { Button } from '@rmwc/button';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../layouts/admin/Loader';
import { deliveryOptionsActions } from '../../redux/actions/deliveryOptions';
import { createLoadingSelector } from '../../redux/selectors/loader';
import DeliveryOptionsTable from './DeliveryOptionsTable';

class DeliveryOptions extends Component {
    componentDidMount() {
        this.props.getDeliveryOptions();
    }

    onReturnClick = () => {
        this.props.history.goBack();
    };

    render() {
        const { deliveryOptions, toggleActive, currentUser, loading, history } = this.props;

        if (true === loading) {
            return <Loader />;
        } else {
            return (
                <Fragment>
                    <div className="container-fluid deliveryoptions-list-header">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="float-left">
                                    <button
                                        className="btn btn-default return-btn"
                                        onClick={e => this.onReturnClick(e)}
                                    >
                                        Go back
                                    </button>

                                    <Link to="/delivery/new">
                                        <Button
                                            className="left-spacing-btn create-deliveryoptions-btn"
                                            raised={true}
                                        >
                                            Create delivery option
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid deliveryoptions-list-table">
                        <div className="row">
                            <div className="col-md-12 deliveryoptions-list-core">
                                <DeliveryOptionsTable
                                    deliveryOptions={deliveryOptions}
                                    toggleActive={toggleActive}
                                    currentUser={currentUser}
                                    loading={loading}
                                    history={history}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    const loadingSelector = createLoadingSelector([
        '(DeliveryOptions) LIST',
        'GET_DELIVERY_OPTIONS',
    ]);
    return {
        deliveryOptions: state.deliveryOptions.deliveryOptions,
        currentUser: state.auth.currentUser,
        loading: loadingSelector(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getDeliveryOptions: () => dispatch(deliveryOptionsActions.getDeliveryOptions()),
        toggleActive: (deliveryOption, active) =>
            dispatch(deliveryOptionsActions.toggleActive(deliveryOption, active)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryOptions);
