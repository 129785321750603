import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import history from './history';
import searchIcon from './icons/WhiteSearch.png';
import AdminLayout from './layouts/admin';
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import RequireNewPassword from './pages/auth/RequireNewPassword';
import Dashboard from './pages/dashboard';
import DeliveryOptionPage from './pages/delivery-option';
import DeliveryOptions from './pages/delivery-options';
import DevicePage from './pages/devices/Device';
import DevicesPage from './pages/devices/Devices';
import Page403 from './pages/errors/403';
import Order from './pages/orders/order';
import OrderListPage from './pages/orders/order-list';
import OrderSearch from './pages/orders/order-search';
import PaymentOption from './pages/payment-option';
import PaymentOptionsPage from './pages/payment-options';
import NewPaymentOptionPage from './pages/payment-options/NewPaymentOptionPage';
import Users from './pages/users';
import EditUser from './pages/users/EditUser';
import NewUser from './pages/users/NewUser';

const Routes = ({ store }) => {
    const [initials, setInitials] = useState(null);

    useEffect(() => {
        if (!initials) {
            const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                if (!state || !state.auth || !state.auth.currentUser) {
                    return;
                }

                const { firstName, lastName } = state.auth.currentUser;
                if (!firstName || !lastName) {
                    return;
                }
                setInitials(firstName.slice(0, 1) + lastName.slice(0, 1));

                // We don't need to listen to state changes any more
                unsubscribe();
            });
        }
    }, [initials, store]);

    const headerAction = [
        <div className="header-search-name-wrapper" key="headeraction1">
            <Link to="/orders/search">
                <img src={searchIcon} alt="search-icon" className="header-search" />
            </Link>
            <span className="separator" />
            <div className="header-initials">{initials}</div>
        </div>,
    ];

    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route
                    exact
                    path="/login"
                    component={Login}
                    title="LOGIN"
                    actionItems={headerAction}
                />
                <Route
                    exact
                    path="/forgotPassword"
                    component={ForgotPassword}
                    title="FORGOT PASSWORD"
                    actionItems={headerAction}
                />
                <Route
                    exact
                    path="/requireNewPassword"
                    component={RequireNewPassword}
                    title="REQUIRE NEW PASSWORD"
                    actionItems={headerAction}
                />
                {/* Dashboard */}
                <AdminLayout
                    exact
                    path="/"
                    component={Dashboard}
                    title="HOME"
                    actionItems={headerAction}
                />
                {/* Delivery Options */}
                <AdminLayout
                    exact
                    path="/delivery"
                    permission="deliveryoptions--list"
                    component={DeliveryOptions}
                    title="DELIVERY OPTIONS"
                    actionItems={headerAction}
                />

                <AdminLayout
                    exact
                    path="/payment"
                    permission="paymentoptions--list"
                    component={PaymentOptionsPage}
                    title="PAYMENT OPTIONS"
                    actionItems={headerAction}
                />

                <AdminLayout
                    exact
                    path="/payment/new"
                    permission="paymentoptions--add"
                    component={NewPaymentOptionPage}
                    title="PAYMENT OPTIONS"
                    actionItems={headerAction}
                />

                <AdminLayout
                    exact
                    path="/payment/:paymentType"
                    permission="paymentoptions--list"
                    component={PaymentOption}
                    title="PAYMENT OPTION"
                    actionItems={headerAction}
                />

                <AdminLayout
                    exact
                    path="/delivery/new"
                    permission="deliveryoptions--create"
                    component={DeliveryOptionPage}
                    title="NEW DELIVERY OPTION"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/delivery/:id"
                    permission="deliveryoptions--list"
                    component={DeliveryOptionPage}
                    title="DELIVERY OPTIONS"
                    actionItems={headerAction}
                />
                {/* Users */}
                <AdminLayout
                    exact
                    path="/users"
                    permission="users--list"
                    component={Users}
                    title="USER LIST"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/users/:id/edit"
                    component={EditUser}
                    title="EDIT USER"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/users/new"
                    // permission="users--create"
                    component={NewUser}
                    title="NEW USER"
                    actionItems={headerAction}
                />
                {/* Orders */}
                <AdminLayout
                    exact
                    path="/orders"
                    permission="orders--list"
                    component={OrderListPage}
                    title="ORDERS"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/orders/search"
                    component={OrderSearch}
                    title="SEARCH ORDERS"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/orders/:id"
                    component={Order}
                    title="VIEW / MANAGE ORDER"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/logout"
                    component={Logout}
                    title="Logout"
                    actionItems={[]}
                />
                {/* Devices */}
                <AdminLayout
                    exact
                    path="/devices"
                    component={DevicesPage}
                    title="DEVICES"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/devices/new"
                    permission="devices--add"
                    component={DevicePage}
                    title="DEVICES"
                    actionItems={headerAction}
                />
                <AdminLayout
                    exact
                    path="/devices/:locationID/:id"
                    permission="devices--edit"
                    component={DevicePage}
                    title="DEVICES"
                    actionItems={headerAction}
                />

                {/* Errors */}
                <AdminLayout
                    exact
                    path="/403"
                    component={Page403}
                    title="403 ERROR"
                    actionItems={headerAction}
                />
            </Switch>
        </ConnectedRouter>
    );
};

export default Routes;
