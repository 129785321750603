import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv6';
import React from 'react';

const schema = {
    title: '',
    type: 'object',
    required: ['name'],
    properties: {
        name: {
            title: 'Name',
            type: 'string',
            minLength: 1,
            maxLength: 200,
        },
        isEnabled: {
            title: 'Is Active',
            type: 'boolean',
        },
        isAnatwine: {
            title: 'Is Anatwine',
            type: 'boolean',
        },
    },
};

const PaymentOptionEdit = props => {
    const { editPaymentOption, onEntityChange } = props;
    schema.title = `Edit Payment Option ${editPaymentOption.paymentType}`;

    return (
        <>
            <div className="row">
                <Form
                    schema={schema}
                    validator={validator}
                    onChange={onEntityChange}
                    onSubmit={event => props.createPaymentOption(event.formData)}
                    formData={editPaymentOption}
                >
                    <></>
                </Form>
            </div>
        </>
    );
};

export default PaymentOptionEdit;
