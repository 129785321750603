//@ts-check
import TextField from '@material-ui/core/TextField';
import React from 'react';

const SearchBox = ({ className = '', children = null, ...props }) => (
    <TextField
        className={className}
        variant="outlined"
        placeholder="Search Devices"
        size="small"
        style={{ backgroundColor: '#f7f7f7' }}
        InputProps={{
            style: {
                fontFamily: 'Gotham SSm A',
                fontSize: 14,
                paddingLeft: 20,
                paddingRight: 20,
                border: 'none',
            },
        }}
        {...props}
    >
        {children}
    </TextField>
);

export default SearchBox;
