import { utilConstants } from '../actions/types';

const initialState = {
    channels: [],
    fascias: [],
};

export function utilsReducer(state = initialState, action) {
    switch (action.type) {
        case utilConstants.GET_CHANNELS_SUCCESS:
            return {
                ...state,
                channels: action.channels,
            };
        case utilConstants.GET_FASCIAS_SUCCESS:
            return {
                ...state,
                fascias: action.fascias,
            };
        default:
            return state;
    }
}
