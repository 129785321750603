import { layoutConstants } from '../actions/types';

const initialState = {
    drawerOpen: false,
};

export function layoutsReducer(state = initialState, action) {
    switch (action.type) {
        case layoutConstants.TOGGLE_DRAWER:
            if (action.open !== undefined) {
                return {
                    drawerOpen: action.open,
                };
            } else {
                return {
                    drawerOpen: !state.drawerOpen,
                };
            }

        default:
            return state;
    }
}
