import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './index.scss';

import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';

import AdvancedSearchPage from './AdvancedSearchPage';
import SearchPage from './SearchPage';

class OrderSearch extends React.Component {
    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    render() {
        return (
            <Grid fluid className="order-search-form">
                <Row>
                    <SearchPage />
                    <AdvancedSearchPage />
                </Row>
            </Grid>
        );
    }
}

export default withRouter(OrderSearch);
