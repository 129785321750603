import './index.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Loader from '../../../layouts/admin/Loader';
import { orderActions } from '../../../redux/actions/order';
import { ordersActions } from '../../../redux/actions/orders';
import { createLoadingSelector } from '../../../redux/selectors/loader';
import OrderPage from './OrderPage';
import OrderSidebar from './orderSidebar/OrderSidebar';

const Order = ({
    getOrder,
    // currentVendor,
    // vendorOrder,
    // changeVendor,
    match,
    history,
    order,
    loading,
    processOrderAmendment,
    cancelOrder,
}) => {
    const [mainScreenOnly, setMainScreenOnly] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 1469) {
            setMainScreenOnly(true);
        } else {
            setMainScreenOnly(false);
        }
    }, []);

    useEffect(() => {
        getOrder(match.params.id);
    }, [getOrder, match.params.id]);

    if (loading === true || order === null) {
        return <Loader />;
    }

    return (
        <div className="order-view">
            <div className="order-scrollable-panel">
                <OrderSidebar history={history} />
            </div>
            <div className="order-page-wrapper">
                <OrderPage
                    processOrderAmendment={processOrderAmendment}
                    showMainScreenOnly={mainScreenOnly}
                    cancelOrder={cancelOrder}
                />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const loadingSelector = createLoadingSelector(['(Order) GET_ORDER']);
    return {
        order: state.order.order,
        currentVendor: state.order.currentVendor,
        vendorOrder: state.order.vendorOrder,
        loading: loadingSelector(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOrder: id => dispatch(orderActions.getOrder(id)),
        // changeVendor: vendor => dispatch(orderActions.changeVendor(vendor)),
        processOrderAmendment: data => dispatch(ordersActions.processOrderAmendment(data)),
        cancelOrder: data => dispatch(orderActions.cancelOrder(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
