import { boolean, object, string } from 'yup';

export const DeviceSchema = object().shape({
    name: string().required('A device name is required'),
    locationID: string()
        .required('A store number is required')
        .matches(/^\d+$/, 'Store number can only contain numbers')
        .min(3, 'Store number must be at least 3 digits'),
    type: string().required('A device type is required'),
    poiID: string().when('enableCardPayments', {
        is: true,
        then: string().required('A payment device serial number is required'),
    }),
    currency: string().required('Device currency is required'),
    enableCashPayments: boolean().required(),
    enableCardPayments: boolean().required(),
    enabled: boolean().required(),
    mposEnabled: boolean().required(),
});

export const emptyDevice = {
    name: '',
    locationID: '',
    type: '',
    poiID: '',
    currency: 'GBP',
    enableCashPayments: false,
    enableCardPayments: false,
    enabled: true,
    mposEnabled: false,
};
