const getLineItemRefundTotal = (lines, prices) => {
    let result = 0;

    Object.keys(lines).forEach(lineNumber => {
        const amendItems = lines[lineNumber].amends || [];
        amendItems.forEach(amendItem => {
            if (amendItem && amendItem.type === 'refund') {
                result += amendItem.quantity * prices.discountPrice;
            }
        });
    });

    return result;
};

const getFaciaDeliveryCost = (order, itemBrand) => {
    let result = null;

    if (
        order.items[itemBrand].delivery !== undefined &&
        order.items[itemBrand].delivery.refund !== undefined
    ) {
        result =
            order.items[itemBrand].totals.deliverySplit -
            order.items[itemBrand].delivery.refund.price;
    } else {
        result = order.items[itemBrand].totals.deliverySplit;
    }

    return result;
};

const getOriginalTotal = (order, itemBrand = null) => {
    let result = 0;

    if (itemBrand) {
        for (const orderItem of order.items[itemBrand].items) {
            const { quantity, prices } = orderItem;
            result += quantity * prices.discountPrice;
        }
    } else {
        const brandKeys = Object.keys(order.items);
        for (const brandGroup of brandKeys) {
            for (const orderItem of order.items[brandGroup].items) {
                const { quantity, prices } = orderItem;
                result += quantity * prices.discountPrice;
            }
        }
    }

    return result;
};

const getTotalFaciaRefunds = (order, itemBrand = null, excludeDelivery = false) => {
    let result = 0;

    if (itemBrand) {
        for (const orderItem of order.items[itemBrand].items) {
            const { lines, prices } = orderItem;

            if (order.refunds && !excludeDelivery) {
                for (const { type, price } of order.refunds) {
                    if (type !== 'partialdelivery') {
                        return;
                    }

                    result += getDeliverySplitByPrice(order, price)[itemBrand].deliverySplit;
                }
            }

            if (typeof lines === 'undefined') {
                continue;
            }

            result += getLineItemRefundTotal(lines, prices);

            if (
                order.items[itemBrand].delivery !== undefined &&
                order.items[itemBrand].delivery.refund &&
                !excludeDelivery
            ) {
                result += order.items[itemBrand].delivery.refund.price;
            }
        }
    } else {
        const brandKeys = Object.keys(order.items);
        for (const facia of brandKeys) {
            for (const orderItem of order.items[facia].items) {
                const { lines, prices } = orderItem;

                if (typeof lines === 'undefined') {
                    continue;
                }

                result += getLineItemRefundTotal(lines, prices);
            }
        }
    }

    return result;
};

const getTotalItemQuantity = orderItems => {
    let quantity = 0;

    Object.keys(orderItems).forEach(facia => {
        Object.keys(orderItems[facia].items).forEach(
            faciaItemIndex => (quantity += orderItems[facia].items[faciaItemIndex].quantity),
        );
    });

    return quantity;
};

const getFaciaQuantity = faciaItems => {
    let quantity = 0;

    faciaItems.forEach(faciaItem => (quantity += faciaItem.quantity));

    return quantity;
};

const getFullRefundAmount = order => {
    let amount = 0;
    if (typeof order.delivery.refund === 'undefined') {
        return amount;
    }

    amount += order.delivery.refund.price;

    return amount;
};

const getDeliverySplitByPrice = (order, price) => {
    const result = {};

    const totalItemQty = getTotalItemQuantity(order.items);

    const deliveryPerItem = price / totalItemQty;

    Object.keys(order.items).forEach(facia => {
        const faciaQTY = getFaciaQuantity(order.items[facia].items);

        let faciaDeliverySplit = deliveryPerItem * faciaQTY;

        faciaDeliverySplit -= getFullRefundAmount(order) / faciaQTY;

        const roundedFaciaDeliverySplit = Math.floor(faciaDeliverySplit);
        if (undefined === result[facia]) {
            result[facia] = { deliverySplit: roundedFaciaDeliverySplit };
        }
    });

    return result;
};

const calculateRemainingDeliveryRefundTotal = (index, order) => {
    const { delivery, items, refunds } = order;
    let result = delivery.price || 0;
    if (refunds) {
        for (let k = 0; k <= index; k++) {
            if (!refunds[k] || refunds[k].type !== 'partialdelivery') {
                continue;
            }

            result -= refunds[k].price;
        }
    }
    for (const facia in items) {
        if (!items[facia].delivery || !items[facia].delivery.refund) {
            continue;
        }
        const {
            refund: { createdAt, price },
        } = items[facia].delivery;

        if (!refunds.some(({ created }) => created === createdAt)) {
            result -= price;
        }
    }
    return result;
};

export {
    calculateRemainingDeliveryRefundTotal,
    getDeliverySplitByPrice,
    getLineItemRefundTotal,
    getFaciaDeliveryCost,
    getTotalFaciaRefunds,
    getOriginalTotal,
};
