const reasonCodes = [
    {
        code: '01',
        title: 'NO LONGER WANTED',
        description: 'No longer wanted/Unsuitable',
    },
    {
        code: '02',
        title: 'ARRIVED TOO LATE',
        description: 'Arrived too late',
    },
    {
        code: '03',
        title: 'DID NOT MATCH',
        description: 'Did not match description/image',
    },
    {
        code: '04',
        title: 'MULTIPLE SIZES',
        description: 'More than one size/colour',
    },
    {
        code: '05',
        title: 'INCORRECT ITEM',
        description: 'Incorrect item sent',
    },
    {
        code: '06',
        title: 'NO REASON STATED',
        description: 'No reason stated/Other',
    },
    {
        code: '07',
        title: 'SOILED',
        description: 'Soiled',
    },
    {
        code: '08',
        title: 'DAMAGED',
        description: 'Faulty/damaged',
    },
    {
        code: '09',
        title: 'ODD SIZE SENT',
        description: 'Odd size sent',
    },
    {
        code: '10',
        title: 'TEST ORDER',
        description: 'Test Order',
    },
    {
        code: '13',
        title: 'TOO LONG',
        description: 'Too Long',
    },
    {
        code: '14',
        title: 'TOO SHORT',
        description: 'Too Short',
    },
    {
        code: '15',
        title: 'TOO BIG',
        description: 'Too Big',
    },
    {
        code: '16',
        title: 'TOO SMALL',
        description: 'Too Small',
    },
    {
        code: '20',
        title: 'SECURITY ISSUE',
        description: 'Security Issue',
    },
    {
        code: '21',
        title: 'PRINTING ISSUE',
        description: 'Printing Issue',
    },
    {
        code: '22',
        title: 'DTS NOT COLLECT',
        description: 'DTS Not Collected',
    },
    {
        code: '23',
        title: 'FAILED DELIVERY',
        description: 'Failed Delivery',
    },
    {
        code: '24',
        title: 'PART/ODD SUIT',
        description: 'Part/Odd Suit',
    },
    {
        code: '51',
        title: 'FAULTY',
        description: 'Faulty',
    },
];

export default reasonCodes;

export function reasonCodeLookup(description) {
    if (!description) {
        return undefined;
    }
    return reasonCodes.find(reason => reason.description === description);
}

export function reasonCodeDescription(code) {
    const foundCode = reasonCodes.find(reason => reason.code === code);
    return undefined !== foundCode ? foundCode.description : '';
}
