import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    searchInput: {
        width: '400px',
        backgroundColor: '#f7f7f7',
        borderRadius: 5,
        paddingTop: 1, // Add top and bottom padding since we remove the border
        paddingBottom: 1,
        '& fieldset': {
            border: 'none',
        },
    },
}));
