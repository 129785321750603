import networkGroups from '../../config/networkGroups';
import { deliveryOptionsConstants } from '../actions/types';

const initialState = {
    deliveryOptions: [],
    deliveryOption: {
        carrierCode: '',
        code: '',
        name: '',
        description: '',
        pdpPromise: '',
        deliveryType: '',
        price: 0,
        cutOffTime: '',
        active: false,
        fascia: '',
    },
};
networkGroups.forEach(networkGroup => {
    initialState.deliveryOption['anatwine-' + networkGroup.id] = false;
});

export function deliveryOptionsReducer(state = initialState, action) {
    switch (action.type) {
        case deliveryOptionsConstants.RESET_DELIVERY_OPTION:
            return {
                deliveryOption: initialState.deliveryOption,
            };

        case deliveryOptionsConstants.LIST_SUCCESS:
            return {
                ...state,
                deliveryOptions: action.deliveryOptions,
            };

        case deliveryOptionsConstants.DUPLICATE_SUCCESS:
        case deliveryOptionsConstants.SAVE_SUCCESS:
        case deliveryOptionsConstants.GET_SUCCESS:
            delete action.deliveryOption.created;
            delete action.deliveryOption.updated;
            return {
                ...state,
                deliveryOption: action.deliveryOption,
            };

        case deliveryOptionsConstants.TOGGLE_ACTIVE_SUCCESS: {
            const deliveryOptions = [...state.deliveryOptions];
            deliveryOptions.map(deliveryOption => {
                if (deliveryOption.ID === action.deliveryOption.ID) {
                    deliveryOption.active = action.active;
                }
                return deliveryOption;
            });
            return {
                ...state,
                deliveryOptions,
            };
        }
        case deliveryOptionsConstants.UPDATE_STATE: {
            return {
                ...state,
                deliveryOption: {
                    ...state.deliveryOption,
                    [action.name]: action.value,
                },
            };
        }
        default: {
            return state;
        }
    }
}
