import './OrderSidebar.scss';

import { Card } from '@rmwc/card';
import React, { Fragment, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { capitalizeWords } from '../../../../utils/formatString';
import { getDeliveryRefund } from '../../../../utils/prices';
import { formatDateDefault } from '../../../../utils/time';
import { getOriginalTotal, getTotalFaciaRefunds } from '../calculations';
import { mapStateToProps } from './orderSidebarFunctions.js';

const excludeFromDelivery = (value, key) => {
    if (typeof value === 'object') {
        return true;
    }

    switch (key) {
        case 'deliveryOptionID':
        case 'optionName':
        case 'firstName':
        case 'lastName':
        case 'price':
        case 'ID':
            return true;
        default:
            return false;
    }
};

const formatFieldValue = (values, key) => {
    const value = values[key];

    if (!value || typeof value === 'object') {
        return;
    }

    const ignoredFields = [
        'id',
        'default',
        'paywithgoogle.token',
        'psp',
        'token',
        'transaction',
        'updated',
    ];
    if (ignoredFields.includes(key.toLowerCase())) {
        return;
    }

    if (key === 'lastFour') {
        return `xxxx-xxx-xxx-${value}`;
    }
    if (key === 'created') {
        return formatDateDefault(value);
    }
    if (typeof value === 'string') {
        return capitalizeWords(value);
    }

    return value;
};

const displayPayment = payment => {
    const values = { ...payment };
    let result = [];

    Object.keys(values).forEach(key => {
        const value = formatFieldValue(values, key);

        if (!value) {
            return;
        }
        if (key === 'lastFour') {
            key = 'card number';
        }

        const row = (
            <Fragment>
                <th>{capitalizeWords(key)}:</th>
                <td>{value}</td>
            </Fragment>
        );
        result.push(row);
    });

    return result.map((row, index) => <tr key={index}>{row}</tr>);
};

const displayDeliveryAddress = delivery => {
    const values = { ...delivery };
    let result = [];

    if (values.firstName && values.firstName.length && values.lastName && values.lastName.length) {
        const row = (
            <Fragment>
                <th>Name:</th>
                <td>{capitalizeWords(`${values.firstName} ${values.lastName}`)}</td>
            </Fragment>
        );
        result.push(row);
    }

    Object.keys(values).forEach(key => {
        const formattedKey = key.charAt(0).toUpperCase() + key.slice(1);

        if (true === excludeFromDelivery(values[key], key)) {
            return;
        }

        const row = (
            <Fragment>
                <th>{formattedKey}:</th>
                <td>{values[key]}</td>
            </Fragment>
        );
        result.push(row);
    });

    return result.map((row, index) => <tr key={index}>{row}</tr>);
};

function getOrderChannel(order) {
    let result = '';
    if (!order.channel) {
        return result;
    }
    if (order.channel.length < 4) {
        result = order.channel.toUpperCase();
    } else if (order.channel == 'ipad') {
        result = 'iPad';
    } else {
        result = capitalizeWords(order.channel);
    }
    return result;
}

function OrderSidebar({ order }) {
    const [shouldReturn] = useState(false);

    let discountPercentage = 0;

    if (order.totals.discount.items > 0) {
        discountPercentage = Math.round(
            (order.totals.discount.items / order.totals.subtotal) * 100,
        );
    }

    const savings = order.totals.discount.items || 0;
    const subtotal = getOriginalTotal(order) + savings;
    const deliveryCost = order.delivery.price || 0;
    const deliveryDiscount = order.totals.discount.delivery || 0;
    const totalRefund = getTotalFaciaRefunds(order);
    const deliveryRefund = getDeliveryRefund(order);

    const total =
        subtotal - savings + deliveryCost - deliveryDiscount - totalRefund - deliveryRefund;

    const orderChannel = getOrderChannel(order);

    return shouldReturn ? (
        <Redirect
            to={{
                pathname: '/orders',
                state: {},
            }}
        />
    ) : (
        <div className="order-detail">
            <div className="order-detail-header d-block mx-auto">
                <div className="right-header">
                    <span className="order-header">ORDER:</span>
                    <br />
                    <span className="order-number">{order.ID}</span>
                </div>
                <div className="clearfix" />
            </div>
            <Card className="order-detail-card d-block mx-auto">
                <h3>ORDER DETAILS</h3>
                <table className="table order-details-table">
                    <tbody>
                        <tr>
                            <th>Group Order:</th>
                            <td>{order.ID}</td>
                        </tr>
                        <tr>
                            <th>Created:</th>
                            <td>{formatDateDefault(order.created)}</td>
                        </tr>
                        <tr>
                            <th>Updated:</th>
                            <td>{formatDateDefault(order.updated)}</td>
                        </tr>
                        <tr>
                            <th>Email:</th>
                            <td>{order.email}</td>
                        </tr>
                        <tr>
                            <th>Channel:</th>
                            <td>{orderChannel}</td>
                        </tr>
                        {order.locationName ? (
                            <tr>
                                <th>Store:</th>
                                <td>{order.locationName}</td>
                            </tr>
                        ) : null}
                        {order.locationID ? (
                            <tr>
                                <th>Store ID:</th>
                                <td>{order.locationID}</td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </Card>
            <Card className="order-detail-card d-block mx-auto">
                <h3>BILLING ADDRESS</h3>
                <table className="table billing-address-table">
                    <tbody>
                        <tr>
                            <th>Name:</th>
                            <td>
                                {capitalizeWords(
                                    `${order.billing.firstName} ${order.billing.lastName}`,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th>Address:</th>
                            <td>{order.billing.address}</td>
                        </tr>
                        <tr>
                            <th />
                            <td>{order.billing.address2}</td>
                        </tr>
                        <tr>
                            <th>City:</th>
                            <td>{order.billing.city}</td>
                        </tr>
                        <tr>
                            <th>Postcode:</th>
                            <td>{order.billing.postcode}</td>
                        </tr>
                    </tbody>
                </table>
            </Card>
            <Card className="order-detail-card d-block mx-auto">
                <h3>PAYMENT DETAILS</h3>
                <table className="table payment-details-table">
                    <tbody>{displayPayment(order.payment)}</tbody>
                </table>
            </Card>
            <Card className="order-detail-card d-block mx-auto">
                <h3>DELIVERY ADDRESS</h3>
                <table className="table delivery-address-table">
                    <tbody>{displayDeliveryAddress(order.delivery)}</tbody>
                </table>
            </Card>
            <Card className="order-detail-card d-block mx-auto">
                <h3>ORDER TOTAL</h3>
                <table className="table order-total-table">
                    <tbody>
                        <tr>
                            <th>Subtotal:</th>
                            <td>
                                <CurrencyFormat
                                    prefix="£"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType="text"
                                    value={subtotal / 100}
                                />
                            </td>
                        </tr>
                        {order.coupon !== undefined ? (
                            <tr className="order-sidebar-discount-tr">
                                <th>Discount:</th>
                                <td>{order.coupon}</td>
                            </tr>
                        ) : null}
                        <tr className="order-sidebar-discount-tr">
                            <th>Saving ({`${discountPercentage || 0}%`}):</th>
                            <td>
                                <CurrencyFormat
                                    prefix="£"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType="text"
                                    value={savings / 100}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Delivery Cost:</th>
                            <td>
                                <CurrencyFormat
                                    prefix="£"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType="text"
                                    value={deliveryCost / 100}
                                />
                            </td>
                        </tr>
                        {order.totals.discount.delivery > 0 && (
                            <tr className="order-sidebar-discount-tr">
                                <th>Delivery Discount:</th>
                                <td>
                                    <CurrencyFormat
                                        prefix="£"
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        displayType="text"
                                        value={deliveryDiscount / 100}
                                    />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <th>Total Refund:</th>
                            <td>
                                <CurrencyFormat
                                    prefix="£"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType="text"
                                    value={totalRefund / 100}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Delivery Refund:</th>
                            <td>
                                <CurrencyFormat
                                    prefix="£"
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    displayType="text"
                                    value={deliveryRefund / 100}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <strong>Total:</strong>
                            </th>
                            <td>
                                <strong>
                                    <CurrencyFormat
                                        prefix="£"
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        displayType="text"
                                        value={total / 100}
                                    />
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        </div>
    );
}

export default connect(mapStateToProps, null)(OrderSidebar);
