import authInstance from '../../services/Auth/authorization';
import microservices from '../../services/microservices';
import { handleUnauthorizedError } from '../../utils';
import { hackOrderReturn } from './order';
import { ordersConstants } from './types';

export const ordersActions = {
    getOrders,
};

function getOrders(
    page = 1,
    per_page = 20,
    channel = '',
    search = '',
    sort_by = '',
    advanced_search = {},
) {
    return dispatch => {
        dispatch(request());

        let advancedSearchParams = null;
        if (Object.keys(advanced_search).length) {
            advancedSearchParams = objectToGETParams(advanced_search);
        }

        const isTokenValid = authInstance.isTokenValid();

        if (!isTokenValid) {
            handleUnauthorizedError('Invalid Token');
            dispatch(failure('Invalid Token'));
        }

        let url = `/admin?page=${page}&per_page=${per_page}`;
        if (channel !== '') {
            url += `&channel=${channel}`;
        }

        if (search.length > 0) {
            url += `&search=${search.toLocaleLowerCase()}`;
        }
        if (sort_by.length > 0) {
            url += `&sort_by=${sort_by}`;
        }
        if (advancedSearchParams !== null && advancedSearchParams.length) {
            url += `&advanced=1&${advancedSearchParams}`;
        }

        try {
            microservices.order
                .get(`${url}`, { optionalHeaders: { 'X-Cache-Enabled': 'false' } })
                .then(response => {
                    const orderData = response.data;
                    orderData.data.forEach(order => {
                        hackOrderReturn(order);
                    });
                    return orderData;
                })
                .then(orders => dispatch(success(orders)));
        } catch (error) {
            console.error('error on orders', error);
            handleUnauthorizedError(error);
            dispatch(failure(error));
        }
    };

    function request() {
        return { type: ordersConstants.GET_ORDERS_REQUEST };
    }
    function success(orders) {
        return { type: ordersConstants.GET_ORDERS_SUCCESS, orders };
    }
    function failure(error) {
        return { type: ordersConstants.GET_ORDERS_FAIL, error };
    }
}

const objectToGETParams = obj => {
    let GETParams = '',
        GETValue = '';

    for (let GETKey in obj) {
        GETValue = obj[GETKey];
        if (GETValue.length) {
            GETParams += `&${GETKey}=${GETValue.toLocaleLowerCase()}`;
        } else if (GETValue.toString().length) {
            GETParams += `&${GETKey}=${GETValue}`;
        }
    }

    if (GETParams.length > 0) {
        GETParams = GETParams.slice(1); // remove first `&`
    }

    return GETParams;
};
