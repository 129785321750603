import './OrdersTable.scss';

import Radium from 'radium';
import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link, withRouter } from 'react-router-dom';

import stepBack from '../../../icons/Left.png';
import refreshIcon from '../../../icons/Refresh.png';
import stepForward from '../../../icons/Right.png';
import searchIcon from '../../../icons/Search.png';
import skipBack from '../../../icons/SkipBack.png';
import skipForward from '../../../icons/SkipForward.png';
import { getPaymentMethodType } from '../../../utils/getPaymentMethodType';
import { selectPageNumber } from '../../../utils/selectPageNumber';
import { formatDateDefault } from '../../../utils/time';
import { getOrderStatusBtn, getOrderStatusLine } from '../statusButton';

export class OrdersTable extends Component {
    constructor() {
        super();
        this.state = { checkedRadio: ' ' };
    }
    async onChange(e) {
        const { name, value } = e.target;
        const { updateSearch, reloadOrders, updateSortBy, updateRowsPerPage } = this.props;

        switch (name) {
            case 'rowsPerPage':
                await updateRowsPerPage(value);
                reloadOrders();
                break;
            case 'search':
                await updateSearch(value);
                break;
            case 'sortBy':
                await updateSortBy(value);
                reloadOrders();
                break;
            default:
                break;
        }
    }

    async onKeyDown(e) {
        const { name } = e.target;
        const { reloadOrders } = this.props;
        switch (name) {
            case 'rowsPerPage':
                break;
            case 'search':
                if (e.key === 'Enter' || e.keyCode === '13') {
                    reloadOrders();
                    this.onPaginationBtnClick((e = { target: { id: 'firstPage' } }));
                }
                break;
            case 'sortBy':
                break;
            default:
                break;
        }
    }

    async onPaginationBtnClick(e) {
        const { page, orders, updatePage, reloadOrders } = this.props;

        const numericPage = Number(page);
        const pageId = e.target.id;

        const newPageResult = selectPageNumber(pageId, numericPage, orders.pageCount);

        if (newPageResult.changeMade) {
            await updatePage(newPageResult.newPage);
            reloadOrders();
        }
    }

    async onRefreshClick() {
        const { updateAdvancedSearch, updateSearch, reloadOrders } = this.props;

        await updateSearch('');
        await updateAdvancedSearch({});
        reloadOrders();
    }

    onReturnClick() {
        const { prevpath, search, advancedSearch } = this.props;
        const { goBack, replace } = this.props.history;

        if (1 > prevpath.length) {
            goBack();
        } else {
            // push to prevpath

            let advancedSearchGiven = false;
            // eslint-disable-next-line no-unused-vars
            for (const key in advancedSearch) {
                let advancedSearchItem = advancedSearch[key];

                if (0 < advancedSearchItem.length) {
                    advancedSearchGiven = true;
                    break;
                }
            }

            if (advancedSearchGiven) {
                replace({
                    pathname: prevpath,
                    state: {
                        advancedSearch,
                    },
                });
            } else {
                replace({
                    pathname: prevpath,
                    state: {
                        search,
                    },
                });
            }
        }
    }

    async updateChannelFunc(e) {
        const { updateChannel, reloadOrders } = this.props;

        await this.setState({ checkedRadio: e.target.value });
        await updateChannel(this.state.checkedRadio);
        reloadOrders();
    }

    render() {
        const {
            container,
            header,
            table,
            tableHeader,
            viewBtn,
            standardBtn,
            footer,
            rowsPerPageSelect,
            rowsPerPageLabel,
            paginationIcon,
            inlineBlock,
            returnBtn,
            searchInput,
            searchIconContainer,
            searchGroupContainer,
            refreshIconContainer,
            sortByField,
            searchWrapper,
            searchIconImage,
            IDStyling,
            faciaStyling,
        } = styles;

        const { orders, page, rowsPerPage, search, sortBy, sortByOptions } = this.props;

        const noData = !orders.data;

        return (
            <div style={container} className="container-fluid">
                <div className="row">
                    <header className="col-md-12" style={header}>
                        <div style={searchWrapper} className="float-left">
                            <button
                                style={{ ...inlineBlock, ...returnBtn }}
                                className="btn btn-default"
                                onClick={e => this.onReturnClick(e)}
                            >
                                Go back
                            </button>

                            <div
                                style={{
                                    ...inlineBlock,
                                    ...searchGroupContainer,
                                }}
                                className="form-inline orders-search-bar"
                            >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div style={searchIconContainer}>
                                            <img src={searchIcon} style={searchIconImage} alt="" />
                                        </div>
                                    </div>
                                    <input
                                        style={searchInput}
                                        type="text"
                                        className="form-control"
                                        name="search"
                                        onChange={e => this.onChange(e)}
                                        onKeyDown={e => this.onKeyDown(e)}
                                        value={search}
                                        id="search"
                                        placeholder="Search"
                                    />
                                </div>
                            </div>

                            <button
                                style={refreshIconContainer}
                                className="btn btn-default"
                                onClick={e => this.onRefreshClick(e)}
                            >
                                <img src={refreshIcon} alt="Clear search" />
                            </button>
                            <form className="radio-button-container">
                                <input
                                    type="radio"
                                    value=" "
                                    name="myRadioBtn"
                                    checked={this.state.checkedRadio === ' '}
                                    onChange={e => this.updateChannelFunc(e)}
                                />
                                <label>ALL</label>
                                <input
                                    type="radio"
                                    value="KIOSK"
                                    name="myRadioBtn"
                                    checked={this.state.checkedRadio === 'KIOSK'}
                                    onChange={e => this.updateChannelFunc(e)}
                                />
                                <label>KIOSK</label>
                                <input
                                    type="radio"
                                    value="WEBTILL"
                                    name="myRadioBtn"
                                    checked={this.state.checkedRadio === 'WEBTILL'}
                                    onChange={e => this.updateChannelFunc(e)}
                                />
                                <label>WEBTILL</label>
                                <input
                                    type="radio"
                                    value="IPAD"
                                    name="myRadioBtn"
                                    checked={this.state.checkedRadio === 'IPAD'}
                                    onChange={e => this.updateChannelFunc(e)}
                                />
                                <label>IPAD</label>
                                <input
                                    type="radio"
                                    value="MOBILE"
                                    name="myRadioBtn"
                                    checked={this.state.checkedRadio === 'MOBILE'}
                                    onChange={e => this.updateChannelFunc(e)}
                                />
                                <label>MOBILE</label>
                            </form>
                        </div>

                        <div className="float-right">
                            <div className="form-group">
                                <select
                                    className="form-control"
                                    name="sortBy"
                                    value={sortBy}
                                    style={sortByField}
                                    onChange={e => this.onChange(e)}
                                >
                                    <option value="">SORT BY:</option>
                                    {sortByOptions.map((sort, index) => (
                                        <option key={index}>{sort}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </header>
                </div>
                <div className="row orders-table">
                    <div className="col-md-12">
                        <table className="table" style={table}>
                            <thead>
                                <tr>
                                    <th style={tableHeader}>ID</th>
                                    <th style={tableHeader}>NAME</th>
                                    <th style={tableHeader}>STORE</th>
                                    <th style={tableHeader}>CREATED</th>
                                    <th style={tableHeader}>TOTAL</th>
                                    <th style={tableHeader}>PAYMENT</th>
                                    <th style={tableHeader}>STATUS</th>
                                    <th style={tableHeader}>METHOD</th>
                                    <th style={tableHeader} />
                                </tr>
                            </thead>
                            <tbody>
                                {!noData &&
                                    orders.data.map(
                                        ({ ID, billing, created, totals, payment, items }) => (
                                            <tr key={ID}>
                                                <td style={IDStyling}>{ID}</td>
                                                <td>{`${billing.firstName} ${billing.lastName}`}</td>
                                                <td style={faciaStyling}>
                                                    {Object.keys(items)[0]}
                                                </td>
                                                <td>{formatDateDefault(created)}</td>
                                                <td>
                                                    {
                                                        <CurrencyFormat
                                                            prefix="£"
                                                            thousandSeparator={true}
                                                            displayType="text"
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            value={totals.total / 100}
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    {getOrderStatusBtn(items, payment, null, 2)}
                                                </td>
                                                <td> {getOrderStatusLine(items)} </td>
                                                <td>{getPaymentMethodType(payment)}</td>
                                                <td>
                                                    <Link
                                                        to={`/orders/${ID}`}
                                                        style={{
                                                            ...viewBtn,
                                                            ...standardBtn,
                                                        }}
                                                    >
                                                        View
                                                    </Link>
                                                </td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <footer className="col-md-12" style={footer}>
                        <form className="form-inline float-right">
                            <span style={rowsPerPageLabel}>Rows per page:</span>
                            <span>
                                <div className="form-group">
                                    <select
                                        style={rowsPerPageSelect}
                                        name="rowsPerPage"
                                        className="form-control-sm"
                                        value={rowsPerPage}
                                        onChange={e => this.onChange(e)}
                                    >
                                        <option>5</option>
                                        <option>10</option>
                                        <option>15</option>
                                        <option>20</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </select>
                                </div>
                            </span>
                            {!noData ? (
                                <span>{`${page} of ${orders.pageCount}`}</span>
                            ) : (
                                <span>{'0 of 0'}</span>
                            )}
                            <span>
                                <img
                                    id="firstPage"
                                    onClick={e => this.onPaginationBtnClick(e)}
                                    style={{
                                        ...paginationIcon,
                                        opacity: page === 1 ? 0.5 : 1,
                                    }}
                                    src={skipBack}
                                    alt=""
                                />

                                <img
                                    id="prevPage"
                                    onClick={e => this.onPaginationBtnClick(e)}
                                    style={{
                                        ...paginationIcon,
                                        opacity: page === 1 ? 0.5 : 1,
                                    }}
                                    src={stepBack}
                                    alt=""
                                />

                                <img
                                    id="nextPage"
                                    onClick={e => this.onPaginationBtnClick(e)}
                                    style={{
                                        ...paginationIcon,
                                        opacity: page === orders.pageCount ? 0.5 : 1,
                                    }}
                                    src={stepForward}
                                    alt=""
                                />

                                <img
                                    id="lastPage"
                                    onClick={e => this.onPaginationBtnClick(e)}
                                    style={{
                                        ...paginationIcon,
                                        opacity: page === orders.pageCount ? 0.5 : 1,
                                    }}
                                    src={skipForward}
                                    alt=""
                                />
                            </span>
                        </form>
                    </footer>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        backgroundColor: 'white',
        letterSpacing: 2,
        paddingLeft: 30,
        paddingRight: 30,
    },
    header: {
        paddingTop: 30,
        paddingBottom: 30,
    },
    table: {
        backgroundColor: 'white',
        margin: '0px auto',
        color: '#3e4555',
        fontSize: 14,
    },
    tableHeader: {
        fontWeight: 'bold',
        color: '#3e4555',
        letterSpacing: 2.8,
        border: 'none',
    },
    IDStyling: {
        fontWeight: 'bold',
    },
    faciaStyling: {
        textTransform: 'capitalize',
    },
    standardBtn: {
        border: 'none',
        background: 'none',
    },
    viewBtn: {
        fontWeight: 700,
        color: '#3e4555',
        textDecoration: 'none',
    },
    paginationIcon: {
        color: '#535a6b',
        marginLeft: 30,
        cursor: 'pointer',
        maxHeight: 25,
    },
    footer: {
        backgroundColor: 'white',
        padding: 50,
    },
    rowsPerPageLabel: {
        paddingRight: 20,
    },
    rowsPerPageSelect: {
        marginRight: 50,
    },
    inlineBlock: {
        display: 'inline-block',
    },
    returnBtn: {
        height: 38,
        border: 'solid 1.5px #e7e8e9',
        color: '#999ca4',
    },
    searchWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    searchIconContainer: {
        backgroundColor: '#f7f7f7',
        paddingLeft: -20,
        paddingRight: -20,
        maxHeight: 38.25,
        border: 'none',
    },
    searchIconImage: {
        height: 32,
        paddingLeft: 10,
        paddingTop: 7,
    },
    searchInput: {
        backgroundColor: '#f7f7f7',
        border: 'none',
        minWidth: 500,
        ':focus': {
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
        },
    },
    searchGroupContainer: {
        marginLeft: 10,
    },
    refreshIconContainer: {
        border: 'solid 1.5px #e7e8e9',
        marginLeft: 10,
        maxHeight: 38,
    },
    sortByField: {
        fontWeight: 'bold',
        fontSize: 10,
        height: 30,
    },
};

export default withRouter(Radium(OrdersTable));
