import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
        textTransform: 'none',
        color: '#90929a',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Gotham SSm A',
        height: 40,
    },
    primaryButton: {
        color: '#fff',
    },
    dangerButton: {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
}));
