export const getDeliveryRefund = order => {
    let result = 0;
    console.log('getDeliveryRefund');

    const { delivery, items, refunds } = order;
    const { discount, refund } = delivery;

    let deliveryRefunded = false;

    // if buyer had a full delivery discount and paid 0,
    // exit as following calculations are not required.
    if (discount && discount === delivery.price) {
        console.log('delivery discount', discount);
        return result;
        // else, increment the delivery refund by the partial
        // refund amount paid and continue to following calculations
    } else if (discount && discount !== delivery.price) {
        console.log('delivery discount', discount);
        result += discount;
    }

    if (refund) {
        result += refund.price;
        deliveryRefunded = true;
        console.log('delivery.refund.price', refund.price);
    }

    if (refunds) {
        console.log('refunds', refunds);
        refunds.forEach(({ type, price }) => {
            if (type !== 'partialdelivery') {
                return;
            }
            console.log('adding partial refund from refunds', price);
            deliveryRefunded = true;
            return (result += price);
        });
    }
    console.log({ deliveryRefunded });
    // get delivery refunds from facia orders
    Object.values(items).forEach(({ delivery }) => {
        if (!deliveryRefunded && delivery && delivery.refund) {
            const { createdAt, price } = delivery.refund;
            // filter out duplicates from order.refunds
            if (refunds && refunds.some(({ created }) => created === createdAt)) {
                return;
            }
            console.log('items.delivery.refund.price', delivery.refund);
            result += price;
        }
    });
    console.log({ deliveryRefunded });

    console.log('result', result);
    return result;
};
