// @ts-check
import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import PrimaryButton from '../../../components/button/Primary';

const useStyles = makeStyles(theme => ({
    notFoundText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        fontFamily: 'Gotham',
    },
    notFoundButton: {
        width: 225,
    },
}));

const NoDevices = props => {
    const classes = useStyles();

    return (
        <Box
            flex={1}
            m={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            fontSize={24}
            color="#3e4555"
        >
            <img src="images/rocket.png" alt="" />
            <Typography className={classes.notFoundText}>No Devices Found</Typography>
            <PrimaryButton className={classes.notFoundButton} onClick={props.onAddDevice}>
                Add Device
            </PrimaryButton>
        </Box>
    );
};

export default NoDevices;
