import './index.scss';

import { Button } from '@rmwc/button';
import React, { Fragment, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../layouts/admin/Loader';
import { usersActions } from '../../redux/actions/users';
import { createLoadingSelector } from '../../redux/selectors/loader';
import UsersTable from './UsersTable';

const Users = ({
    currentUser,
    users,
    loading,
    getUsers,
    toggleEnabled,
    resetPassword,
    history,
    active,
}) => {
    active = localStorage.getItem('activeFilter') ?? 'ALL';
    const [tableData, setTableData] = useState([]);
    const [checkedRadio, setCheckedRadio] = useState(active);
    useEffect(() => {
        async function fetchData() {
            await getUsers();
        }
        fetchData();
    }, [getUsers]);

    useEffect(() => updateTable(users, active), [users, active]);

    const resetPasswordConfirm = user => {
        confirmAlert({
            title: 'Reset password',
            message: `Are you sure you want to reset ${user.firstName} ${user.lastName}'s password`,
            buttons: [
                {
                    label: 'Reset',
                    onClick: () => resetPassword(user),
                },
                {
                    label: 'Cancel',
                },
            ],
        });
    };

    const onReturnClick = () => {
        const { goBack } = history;
        goBack();
    };

    function activeStatusRadioClick(e) {
        setCheckedRadio(e.target.value);
        localStorage.setItem('activeFilter', e.target.value);
        updateTable(users, e.target.value);
    }

    function updateTable(users, active) {
        if (!users) {
            return;
        }
        const inactive = active == 'INACTIVE' ? false : undefined;
        active = active == 'ACTIVE' ? true : inactive;

        const filteredUsers = users.filter(user => user.enabled == active);
        users = active == undefined ? users : filteredUsers;
        setTableData(users);
    }

    return true === loading ? (
        <Loader />
    ) : (
        <Fragment>
            <div className="container-fluid user-list-header">
                <div className="row">
                    <div className="col-md-12">
                        <div className="float-left">
                            <button
                                className="btn btn-default return-btn"
                                onClick={e => onReturnClick(e)}
                            >
                                Go back
                            </button>

                            {currentUser.permissions.includes('users--create') ? (
                                <Link to="/users/new">
                                    <Button className="create-user-btn" raised={true}>
                                        Create user
                                    </Button>
                                </Link>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="float-right">
                            <form className="radio-button-container">
                                <input
                                    type="radio"
                                    value="ALL"
                                    checked={checkedRadio === 'ALL'}
                                    onChange={e => activeStatusRadioClick(e)}
                                />
                                <label>ALL</label>
                                <input
                                    type="radio"
                                    value="ACTIVE"
                                    checked={checkedRadio === 'ACTIVE'}
                                    onChange={e => activeStatusRadioClick(e)}
                                />
                                <label>ACTIVE</label>
                                <input
                                    type="radio"
                                    value="INACTIVE"
                                    checked={checkedRadio === 'INACTIVE'}
                                    onChange={e => activeStatusRadioClick(e)}
                                />
                                <label>INACTIVE</label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid user-list-table">
                <div className="row">
                    <div className="col-md-12 user-list-core">
                        <UsersTable
                            currentUser={currentUser}
                            users={tableData}
                            loading={loading}
                            toggleEnabled={toggleEnabled}
                            resetPassword={resetPasswordConfirm}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

function mapStateToProps(state) {
    const loadingSelector = createLoadingSelector(['(Users) LIST', 'GET_USER']);
    return {
        users: state.users.users,
        currentUser: state.auth.currentUser,
        loading: loadingSelector(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(usersActions.getUsers()),
        toggleEnabled: (user, checked) => dispatch(usersActions.toggleEnabled(user, checked)),
        resetPassword: user => dispatch(usersActions.resetPassword(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
