import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@rmwc/button';
import { TextField, TextFieldHelperText as HelperText } from '@rmwc/textfield';
import axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import env from '../../config/env';
import { authActions } from '../../redux/actions/auth';
import { getMicroservicesDomain } from '../../utils';

const ForgotPassword = props => {
    const [loginDetails, setLoginDetails] = useState({
        username: '',
        code: '',
        password: '',
        emailDelivery: null,
    });

    const [errorMessage, setErrorMessage] = useState(null);

    const { authData = {} } = props;

    const handleInputChange = event => {
        const { name, value } = event.target;
        setLoginDetails({ ...loginDetails, [name]: value });
    };

    const send = async event => {
        const { authData = {} } = props;
        let username = loginDetails.username || authData.username;

        username = username.toLowerCase();
        event.preventDefault();

        try {
            const url = getMicroservicesDomain();
            const defaultHeaders = {
                'X-Api-Key': env.API_KEY,
            };
            await axios({
                method: 'post',
                url: `${url}/user/reset`,
                data: {
                    username,
                },
                headers: defaultHeaders,
            });
            setLoginDetails({ ...loginDetails, emailDelivery: true });
        } catch (error) {
            const errorMessage = handleAxiosError(error);
            return setErrorMessage(errorMessage);
        }
    };

    const submit = async event => {
        const { authData = {} } = props;
        const { code, password } = loginDetails;
        const username = loginDetails.username || authData.username;

        event.preventDefault();

        try {
            const url = getMicroservicesDomain();
            const defaultHeaders = {
                'X-Api-Key': env.API_KEY,
            };
            await axios({
                method: 'post',
                url: `${url}/user/reset`,
                data: {
                    username,
                    code,
                    password,
                },
                headers: defaultHeaders,
            });

            setLoginDetails({ ...loginDetails, emailDelivery: null });
            props.setStateNotSignedIn();
        } catch (error) {
            const errorMessage = handleAxiosError(error);
            return setErrorMessage(errorMessage);
        }
    };

    const sendView = () => {
        return (
            <form className="login-form" onSubmit={send}>
                <TextField
                    label="Email"
                    helpText={<HelperText>Enter your email address</HelperText>}
                    outlined={true}
                    name="username"
                    key="username"
                    autoFocus
                    onChange={handleInputChange}
                    value={loginDetails.username}
                />
                <Button
                    type="submit"
                    raised
                    className="login-button mdc-theme--primary-bg mdc-theme--on-primary"
                >
                    Send Code
                </Button>
                <div className="forgot-wrapper">
                    <button className="link-button" onClick={() => props.setStateNotSignedIn()}>
                        Back to Sign In
                    </button>
                </div>
            </form>
        );
    };

    const submitView = () => {
        return (
            <form className="login-form" onSubmit={submit}>
                <TextField
                    label="Code"
                    helpText={<HelperText>Code sent via your email</HelperText>}
                    outlined={true}
                    name="code"
                    key="code"
                    onChange={handleInputChange}
                    autoComplete="off"
                    value={loginDetails.code}
                />
                <TextField
                    label="New Password"
                    helpText={<HelperText>Enter your new password</HelperText>}
                    outlined={true}
                    name="password"
                    key="password"
                    type="password"
                    onChange={handleInputChange}
                    autoComplete="off"
                    value={loginDetails.password}
                    pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?&quot;!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?&quot;!@#%&/\\,><':;|_~`=+\- ]{10,256}$"
                    title="Your new password should be minimum 16 characters long and contain at least one: lowercase letter, uppercase letter, number, and symbol"
                />
                <div>
                    <label>Password Rules</label>
                    <ul>
                        <li>Password minimum length - 16 characters</li>
                        <li>Contains at least 1 number</li>
                        <li>Contains at least 1 special character</li>
                        <li>Contains at least 1 uppercase letter</li>
                        <li>Contains at least 1 lowercase letter</li>
                    </ul>
                </div>
                <Button
                    type="submit"
                    raised
                    className="login-button mdc-theme--primary-bg mdc-theme--on-primary"
                >
                    Save
                </Button>
                <div className="forgot-wrapper">
                    <button className="link-button" onClick={() => props.setStateNotSignedIn()}>
                        Back to Sign In
                    </button>
                </div>
            </form>
        );
    };

    return (
        <>
            <div>
                {errorMessage && <MuiAlert severity="error">{errorMessage}</MuiAlert>}
                <div className="login-wrapper">
                    <img src="/images/logo-large-en.png" alt="JD Marketplace" />
                    <div className="login-paper">
                        <p>Reset your password</p>
                        {loginDetails.emailDelivery || authData.username
                            ? submitView()
                            : sendView()}
                    </div>
                </div>
            </div>
        </>
    );
};

const handleAxiosError = error => {
    if (error.response) {
        const message = error.response.data.message
            ? error.response.data.message
            : error.response.data.error.message;
        return message;
    } else if (error.request) {
        return error.request;
    } else if (error.message) {
        return error.message;
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setStateNotSignedIn: () => dispatch(authActions.setStateNotSignedIn()),
    };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
