import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import history from '../history';
import rootReducer from './reducers';

const initalState = {};

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          })
        : compose;

const appReducer = (state, action) => {
    return rootReducer(state, action);
};

const store = createStore(
    appReducer(history),
    initalState,
    composeEnhancers(applyMiddleware(thunkMiddleware, routerMiddleware(history))),
);

export default store;
