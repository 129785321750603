import './NewPaymentOptionPage.scss';

import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv6';
import { Button } from '@rmwc/button';
import { Card, CardActionButtons, CardActions } from '@rmwc/card';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Loader from '../../layouts/admin/Loader';
import { paymentOptionsActions } from '../../redux/actions/paymentOptions';
import { createLoadingSelector } from '../../redux/selectors/loader';

const schema = {
    title: 'Create New Payment Method',
    type: 'object',
    properties: {
        name: {
            title: 'Name',
            type: 'string',
        },
        paymentType: {
            title: 'Payment Type',
            type: 'string',
        },
        isEnabled: {
            title: 'Is Enabled?',
            type: 'boolean',
        },
        isAnatwine: {
            title: 'Is Anatwine?',
            type: 'boolean',
        },
    },
};

const initialFormValue = {
    name: '',
    paymentType: '',
    isEnabled: false,
    isAnatwine: false,
};

const NewPaymentOptionPage = props => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [newPaymentMethod, setNewPaymentMethod] = useState({ ...initialFormValue });

    if (props.loading === true) {
        return <Loader />;
    }

    const paymentOptionFormChangeHandler = event => {
        console.log(event);
        setNewPaymentMethod(event.formData);
        setIsSubmitDisabled(!(event.formData.name && event.formData.paymentType));
    };

    return (
        <Card className="padded newPaymentOption">
            {props.errors.map((error, index) => {
                return (
                    <span key={'error-' + index} className="error-message">
                        {error}
                    </span>
                );
            })}
            <Form
                schema={schema}
                validator={validator}
                onChange={paymentOptionFormChangeHandler}
                onSubmit={event => props.createPaymentOption(event.formData)}
                formData={newPaymentMethod}
            >
                <></>
            </Form>
            {/* <p>(Edit ticket: DJJ-4299 - *notification*) Changing the Anatwine status will require a change to the Anatwine payment message on the Front End</p> */}
            {/* TODO: DJJ-4359 - The text above should be styled according to the new reusable form component. Or might not be needed at all (only makes sense on edit, because there is no previous value on the create form) */}
            <CardActions>
                <CardActionButtons
                    style={{
                        paddingRight: 15,
                        marginLeft: 'auto',
                    }}
                >
                    <Link to="/payment">
                        <Button>Cancel</Button>
                    </Link>
                    <Button
                        raised={true}
                        onClick={() => props.createPaymentOption(newPaymentMethod)}
                        disabled={isSubmitDisabled}
                    >
                        Create
                    </Button>
                </CardActionButtons>
            </CardActions>
        </Card>
    );
};

const mapStateToProps = state => {
    const loadingSelector = createLoadingSelector([
        '(PaymentOptions) CREATE',
        '(PaymentOptions) RESET_STATE',
    ]);
    return {
        loading: loadingSelector(state),
        // channels: state.utils.channels, // Will be used in user-experience implementation in later ticket
        errors: state.errors.errors,
        // stores: state.utils.stores, // Will be used in user-experience implementation in later ticket
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createPaymentOption: paymentOption => {
            dispatch(paymentOptionsActions.createPaymentOption(paymentOption));
        },
        // getChannels: () => dispatch(utilsActions.getChannels()), // Will be used in user-experience implementation in later ticket
        // getStores: () => dispatch(utilsActions.getStores()), // Will be used in user-experience implementation in later ticket
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPaymentOptionPage);
