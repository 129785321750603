import './AdvancedSearchPage.scss';

import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Redirect, withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    input: {
        color: 'white',
        fontFamily: 'Gotham SSm A',
        fontSize: 16,
    },
}));

const AdvancedSearchPage = ({ location }) => {
    const [advancedSearchClicked, setAdvancedSearchClicked] = useState(false);
    const [sku, setSku] = useState('');
    const [plu, setPlu] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [postcode, setPostcode] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [fullWidthFields, setFullWidthFields] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (
            typeof location.state !== 'undefined' &&
            typeof location.state.advancedSearch !== 'undefined'
        ) {
            const { sku, plu, firstName, lastName, email, postcode, dateFrom, dateTo } =
                location.state.advancedSearch;

            if (0 < sku.length) {
                setSku(sku);
            }
            if (0 < plu.length) {
                setPlu(plu);
            }
            if (0 < firstName.length) {
                setFirstName(firstName);
            }
            if (0 < lastName.length) {
                setLastName(lastName);
            }
            if (0 < email.length) {
                setEmail(email);
            }
            if (0 < postcode.length) {
                setPostcode(postcode);
            }
            if (0 < dateFrom.length) {
                setDateFrom(dateFrom);
            }
            if (0 < dateTo.length) {
                setDateTo(dateTo);
            }
        }

        let windowWidth = window.innerWidth;

        if (windowWidth < 1320) {
            setFullWidthFields(true);
        } else {
            setFullWidthFields(false);
        }

        const resizeHandlingEvent = () => {
            if (window.innerWidth < 1320) {
                setFullWidthFields(true);
            } else {
                setFullWidthFields(false);
            }
        };

        window.addEventListener('resize', resizeHandlingEvent);
        return () => window.removeEventListener('resize', resizeHandlingEvent);
    }, [location.state]);

    const onChange = e => {
        const { name, value } = e.target;
        const noWhiteSpaceVal = value.replace(/\s/g, '');

        switch (name) {
            case 'sku':
                setSku(noWhiteSpaceVal);
                break;
            case 'plu':
                setPlu(noWhiteSpaceVal);
                break;
            case 'first_name':
                setFirstName(value);
                break;
            case 'last_name':
                setLastName(value);
                break;
            case 'email':
                setEmail(noWhiteSpaceVal);
                break;
            case 'postcode':
                setPostcode(value);
                break;
            case 'date_from':
                setDateFrom(noWhiteSpaceVal);
                break;
            case 'date_to':
                setDateTo(noWhiteSpaceVal);
                break;
            default:
                break;
        }
    };

    const onAdvancedSearch = () => {
        setAdvancedSearchClicked(true);
    };

    return advancedSearchClicked ? (
        <Redirect
            to={{
                pathname: '/orders',
                state: {
                    advancedSearch: {
                        sku,
                        plu,
                        firstName,
                        lastName,
                        email,
                        postcode,
                        dateFrom,
                        dateTo,
                    },
                    prevpath: location.pathname,
                },
            }}
        />
    ) : (
        <Col className="advanced-search-page" md={4}>
            <div className="container">
                <Col md={12} className="search-form">
                    <h3 className="advanced-search-header">Search by:</h3>

                    <Row>
                        <Col md={fullWidthFields ? 12 : 6}>
                            <div className="input-group">
                                <label htmlFor="sku">SKU</label>
                                <input
                                    type="text"
                                    className="advanced-search-field"
                                    name="sku"
                                    value={sku}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </Col>

                        <Col md={fullWidthFields ? 12 : 6}>
                            <div className="input-group">
                                <label htmlFor="plu">PLU</label>
                                <input
                                    type="text"
                                    className="advanced-search-field"
                                    name="plu"
                                    value={plu}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </Col>

                        <Col md={fullWidthFields ? 12 : 6}>
                            <div className="input-group">
                                <label htmlFor="first_name">FIRST NAME</label>
                                <input
                                    type="text"
                                    className="advanced-search-field"
                                    name="first_name"
                                    value={firstName}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </Col>

                        <Col md={fullWidthFields ? 12 : 6}>
                            <div className="input-group">
                                <label htmlFor="last_name">LAST NAME</label>
                                <input
                                    type="text"
                                    className="advanced-search-field"
                                    name="last_name"
                                    value={lastName}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className="input-group">
                                <label htmlFor="email">EMAIL ADDRESS</label>
                                <input
                                    type="text"
                                    className="advanced-search-field-long"
                                    name="email"
                                    value={email}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className="input-group">
                                <label htmlFor="postcode">POSTCODE</label>
                                <input
                                    type="text"
                                    className="advanced-search-field-long"
                                    name="postcode"
                                    value={postcode}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="search-button">
                                <button
                                    className="btn btn-primary advanced-search-submit"
                                    onClick={e => onAdvancedSearch(e)}
                                >
                                    Search
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <br />

                    <h3 className="advanced-search-header">Time period:</h3>

                    <Row>
                        <Col md={12}>
                            <div className="input-group date-input">
                                <label htmlFor="date_from">DATE FROM:</label>
                                <KeyboardDatePicker
                                    value={dateFrom}
                                    onChange={date => setDateFrom(Date.parse(date))}
                                    format="dd/MM/yyyy"
                                    className={'date-picker-range'}
                                    InputProps={{ className: classes.input }}
                                />
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className="input-group date-input">
                                <label htmlFor="date_to">DATE TO:</label>
                                <KeyboardDatePicker
                                    value={dateTo}
                                    onChange={date => setDateTo(Date.parse(date))}
                                    format="dd/MM/yyyy"
                                    className={'date-picker-range'}
                                    InputProps={{ className: classes.input }}
                                    minDate={new Date(dateFrom)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {/* <div className="float-left">
                                <button
                                    className="btn btn-primary advanced-search-export"
                                    onClick={e => onExportOrders(e)}
                                >
                                    Export Orders
                                </button>
                            </div> */}
                            <div className="search-button">
                                <button
                                    className="btn btn-primary advanced-search-submit"
                                    onClick={e => onAdvancedSearch(e)}
                                >
                                    Search
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Col>
    );
};

export default withRouter(AdvancedSearchPage);
