import MuiAlert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
const Alert = ({ severity, onClose, children }) => {
    return (
        <MuiAlert elevation={6} variant="filled" severity={severity} onClose={onClose}>
            {children}
        </MuiAlert>
    );
};
export default Alert;
export function useAlert() {
    const [isOpen, setOpen] = useState(false);
    const [message, setMessage] = useState([]);
    const open = (...messages) => {
        setMessage(messages);
        setOpen(true);
    };
    const close = () => {
        setOpen(false);
        setMessage([]);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        close();
    };
    return {
        isOpen,
        message,
        open,
        close,
        onClick: close,
        onClose: handleClose,
    };
}
