import MaterialTable from '@material-table/core';
import moment from 'moment';
import React from 'react';

import history from '../../history';

const UsersTable = ({ currentUser, users, toggleEnabled }) => {
    // As we don't have access to the Lambda which returns the users array,
    // sorting has to be done client-side which is not ideal!
    users = users.sort((a, b) => {
        if (!a.lastLogin) {
            a.lastLogin = 0;
        }

        if (!b.lastLogin) {
            b.lastLogin = 0;
        }

        return a.lastLogin > b.lastLogin ? -1 : 1;
    });

    // Moment.js config

    moment.locale('en');

    const actions = [];

    if (currentUser.permissions.includes('users--update')) {
        actions.push({
            icon: 'edit',
            tooltip: 'Edit User',
            onClick: (event, user) => history.push(`/users/${user.ID}/edit`),
        });
    }

    const getUserByID = id =>
        users.filter(({ ID }) => {
            return ID === id;
        });

    const toggleUserEnabledStatus = e => {
        if (
            e.target.children.length < 1 ||
            typeof e.target.children.enabled === 'undefined' ||
            typeof e.target.children.id === 'undefined'
        ) {
            return;
        }

        const { value: enableVal } = e.target.children.enabled;
        let toEnable = null;

        switch (enableVal) {
            case '0':
                toEnable = !false;
                break;
            case '1':
                toEnable = !true;
                break;
            default:
                break;
        }

        const { value: idVal } = e.target.children.id;
        let user = getUserByID(idVal);

        if (user.length < 1) {
            return;
        }

        toggleEnabled(user[0], toEnable);
    };

    const renderStatusBtn = ({ ID: id, enabled }) => {
        let classNames = [],
            displayText = '';

        if (enabled === undefined || enabled === false) {
            // render inactive
            classNames.push('inactive-btn');
            displayText = 'inactive';
        } else {
            // render inactive
            classNames.push('active-btn');
            displayText = 'active';
        }

        classNames.push('status-btn');

        return (
            <button
                className={`btn btn-default ${classNames.join(' ')}`}
                onClick={e => toggleUserEnabledStatus(e)}
            >
                <input name="enabled" type="hidden" value={enabled ? 1 : 0} />
                <input name="id" type="hidden" value={id} />
                {displayText.toUpperCase()}
            </button>
        );
    };

    return (
        <div className="table-wrapper">
            <MaterialTable
                title=""
                columns={[
                    {
                        title: 'NAME',
                        field: 'firstName',
                        render: user => user.firstName + ' ' + user.lastName,
                        searchable: true,
                        cellStyle: {
                            fontWeight: 'bold',
                        },
                    },
                    {
                        title: 'Last Name',
                        field: 'lastName',
                        searchable: true,
                        hidden: true,
                    },
                    {
                        title: 'EMAIL',
                        field: 'email',
                        render: user => <a href={'mailto:' + user.email}>{user.email}</a>,
                    },
                    {
                        title: 'STATUS',
                        field: 'status',
                        render: user => renderStatusBtn(user),
                        sorting: false,
                    },
                    {
                        title: 'LAST LOGIN',
                        field: 'lastLogin',
                        render: user => (user.lastLogin ? moment(user.lastLogin).calendar() : '-'),
                        sorting: true,
                    },
                ]}
                data={users}
                actions={actions}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    maxColumnSort: 1,
                }}
            />
        </div>
    );
};

export default UsersTable;
