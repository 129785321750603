import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import React, { useCallback, useMemo, useReducer } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DefaultButton from '../../../components/button/Default';
import PrimaryButton from '../../../components/button/Primary';
import SecondaryButton from '../../../components/button/Secondary';
import { alphabeticalSort, numericalSort, uniqueArray } from '../../../utils';
import { useStyles as useCommonStyles } from '../Devices.style';
import DevicesHeader from './DevicesHeader';
import DevicesTable from './DevicesTable';
import { useStyles } from './DevicesView.style';
import NoDevices from './NoDevices';
import SearchBox from './SearchBox';

const defaultState = {
    searchTerm: '',
    filters: {},
};

const devicesViewReducer = (state = defaultState, { type, payload }) => {
    switch (type) {
        case 'UPDATE_SEARCH':
            return { ...state, searchTerm: payload };
        case 'CLEAR_SEARCH':
            return { ...state, searchTerm: '' };
        case 'APPLY_FILTER':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [payload.filter]: payload.value,
                },
            };
        default:
            return state;
    }
};

const DevicesView = ({ devices, onRefresh, currentUser }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const [state, dispatch] = useReducer(devicesViewReducer, defaultState);
    const { searchTerm } = state;

    const history = useHistory();

    const addDevice = () => history.push('/devices/new');
    const editDevice = ({ ID, locationID }) => history.push(`/devices/${locationID}/${ID}`);

    const handleSearchChange = e => {
        dispatch({
            type: 'UPDATE_SEARCH',
            payload: e.target.value,
        });
    };

    const deviceTypes = useMemo(() => {
        const types = devices.map(d => d.type.toLowerCase());
        return uniqueArray(types).sort(alphabeticalSort);
    }, [devices]);

    const stores = useMemo(() => {
        const locationIDs = devices.map(d => d.locationID.toLowerCase());
        return uniqueArray(locationIDs).sort(numericalSort);
    }, [devices]);

    const fascias = useMemo(() => {
        const fascias = devices.map(d => d.fascia);
        return uniqueArray(fascias).sort(numericalSort);
    }, [devices]);

    const deviceMatchesFilters = useCallback(
        device => {
            for (let [field, value] of Object.entries(state.filters)) {
                if (value && device[field] !== value) {
                    return false;
                }
            }
            return true;
        },
        [state.filters],
    );

    const deviceMatchesSearch = useCallback(
        device => {
            if (!searchTerm) {
                return true;
            }
            const searchFields = [device.locationID, device.name, device.ID];
            return searchFields.some(field => field.includes(searchTerm));
        },
        [searchTerm],
    );

    const filteredDevices = useMemo(() => {
        return devices
            .filter(device => deviceMatchesFilters(device) && deviceMatchesSearch(device))
            .sort((a, b) => {
                const fieldA = `${a.locationID} ${a.ID}`;
                const fieldB = `${b.locationID} ${b.ID}`;
                // Sort the devices by store first, then by ID
                return alphabeticalSort(fieldA, fieldB);
            });
    }, [devices, deviceMatchesFilters, deviceMatchesSearch]);

    const applyFilter = ({ filter, value }) => {
        dispatch({ type: 'APPLY_FILTER', payload: { filter, value } });
    };

    const getPageActions = () => {
        let actions = [];

        const dropdowns = [
            { filter: 'locationID', name: 'Store', values: stores },
            { filter: 'type', name: 'Device Type', values: deviceTypes },
            { filter: 'fascia', name: 'Fascia', values: fascias },
        ];

        for (let { name, filter, values } of dropdowns) {
            actions.push(
                <FormControl
                    key={name}
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                >
                    <InputLabel id="device-type-label">{name}</InputLabel>
                    <Select
                        labelId={`device-${name}-label`}
                        id={`device-${name}`}
                        value={state.filters[filter] || ''}
                        onChange={({ target }) => applyFilter({ filter, value: target.value })}
                        label={name}
                    >
                        <MenuItem value="">All</MenuItem>
                        {values.map(value => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>,
            );
        }

        if (currentUser.permissions.includes('devices--add')) {
            actions.push(
                <PrimaryButton
                    key="add-device"
                    onClick={addDevice}
                    className={classes.headerButton}
                >
                    Add Device
                </PrimaryButton>,
            );
        }

        return actions;
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="spaceBetween" height="100%">
            <DevicesHeader
                leftItems={[
                    <DefaultButton onClick={history.goBack} key="back">
                        Go Back
                    </DefaultButton>,
                    <SecondaryButton onClick={onRefresh} key="refresh">
                        <SyncIcon />
                    </SecondaryButton>,
                    <SearchBox
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className={commonClasses.searchInput}
                        key="searchbox"
                    />,
                ]}
                rightItems={getPageActions()}
            />
            <Box
                flex={1}
                mx={6}
                display="flex"
                flexDirection="column"
                justifyContent="baseline"
                alignItems="center"
            >
                {filteredDevices.length === 0 ? (
                    <NoDevices onAddDevice={addDevice} />
                ) : (
                    <DevicesTable
                        devices={filteredDevices}
                        onEdit={editDevice}
                        currentUser={currentUser}
                    />
                )}
            </Box>
        </Box>
    );
};

function mapStateToProps(state) {
    return {
        currentUser: state.auth.currentUser,
    };
}

export default connect(mapStateToProps)(DevicesView);
