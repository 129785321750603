import React, { Fragment } from 'react';

import collapseDown from '../../../icons/Down.png';
import stepBack from '../../../icons/Left.png';
import stepForward from '../../../icons/Right.png';
import skipBack from '../../../icons/SkipBack.png';
import skipForward from '../../../icons/SkipForward.png';
import collapseUp from '../../../icons/Up.png';
import { formatDateDefault } from '../../../utils/time';

const getNoteText = ({ text, user, createdAt }) => {
    if (!text) {
        return { __html: 'error' };
    }

    let displayHTML = text.replace(/\n/g, '<br/>');
    displayHTML += "<br /><div class='subtext'><span class='user'>";
    displayHTML += user.email ? user.email : user.firstName + ' ' + user.lastName;
    displayHTML += "</span><span class='time'>";
    displayHTML += formatDateDefault(createdAt || Date.now());
    displayHTML += '</span></div>';

    return { __html: displayHTML };
};

const DisplayNotes = ({ orderNotes }) => {
    return orderNotes.notes.map((note, index) => (
        <div key={index} className="card">
            <div className="card-body">
                <div className="card-text">
                    <div className="row">
                        <div className="col-md-11" dangerouslySetInnerHTML={getNoteText(note)} />
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
    ));
};

const NotePerPage = ({ page, pageCount, onNotesRowChange }) => (
    <Fragment>
        Notes per page:
        <select
            name="notes_per_page"
            className="form-control notes-per-page"
            defaultValue={2}
            onChange={e => onNotesRowChange(e)}
        >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>5</option>
        </select>
        <span>{`${page} of ${pageCount}`}</span>
    </Fragment>
);

const NotesPagination = ({ onPaginationBtnClick, page, orderNotes }) => (
    <span>
        <img
            id="firstPage"
            onClick={e => onPaginationBtnClick(e)}
            style={{
                opacity: page === 1 ? 0.5 : 1,
            }}
            className="notes-pagination-icon"
            src={skipBack}
            alt=""
        />

        <img
            id="prevPage"
            onClick={e => onPaginationBtnClick(e)}
            style={{
                opacity: page === 1 ? 0.5 : 1,
            }}
            className="notes-pagination-icon"
            src={stepBack}
            alt=""
        />

        <img
            id="nextPage"
            onClick={e => onPaginationBtnClick(e)}
            style={{
                opacity: page === orderNotes.pageCount ? 0.5 : 1,
            }}
            className="notes-pagination-icon"
            src={stepForward}
            alt=""
        />

        <img
            id="lastPage"
            onClick={e => onPaginationBtnClick(e)}
            style={{
                opacity: page === orderNotes.pageCount ? 0.5 : 1,
            }}
            className="notes-pagination-icon"
            src={skipForward}
            alt=""
        />
    </span>
);

const OrderPageCollapse = ({
    onPaginationBtnClick,
    onNotesRowChange,
    notesCollapseOpen,
    onCollapseClick,
    onAddNoteClick,
    onTextChange,
    orderNotes,
    noteText,
}) => (
    <div id="order-accordion">
        <div className="card">
            <div
                className="card-header"
                id="accordion-order-notes-header"
                data-toggle="collapse"
                data-target="#accordion-order-notes-text"
                aria-expanded="true"
                aria-controls="accordion-order-notes-text"
                onClick={e => onCollapseClick(e)}
            >
                <div className="float-left">
                    <h5 className="title">ORDER NOTES ({orderNotes.total})</h5>
                </div>
                <div className="float-right">
                    {notesCollapseOpen === false ? (
                        <img
                            className="img-responsive collapse-icon"
                            src={collapseDown}
                            alt="Collapse down"
                        />
                    ) : (
                        <img
                            className="img-responsive collapse-icon"
                            src={collapseUp}
                            alt="Collapse up"
                        />
                    )}
                </div>
            </div>
            <div
                id="accordion-order-notes-text"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#order-accordion"
                aria-expanded="true"
            >
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <textarea
                                    name="order-note"
                                    id=""
                                    cols="30"
                                    rows="6"
                                    className="form-control add-note-textarea"
                                    onChange={e => onTextChange(e)}
                                    value={noteText}
                                />
                            </div>
                            <div className="float-right">
                                {noteText.length ? (
                                    <button
                                        className="btn add-note-btn add-note-btn-success"
                                        onClick={e => onAddNoteClick(e)}
                                    >
                                        Save Note
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-default add-note-btn add-note-btn-disabled"
                                        disabled
                                    >
                                        Save Note
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {orderNotes.notes.length > 0 ? (
                        <div className="row">
                            <div className="col-md-12 notes-tools-container">
                                <hr />
                                <div className="float-left">
                                    <NotePerPage
                                        onNotesRowChange={onNotesRowChange}
                                        page={orderNotes.page}
                                        pageCount={orderNotes.pageCount}
                                    />
                                </div>
                                <div className="float-right">
                                    <NotesPagination
                                        onPaginationBtnClick={onPaginationBtnClick}
                                        page={orderNotes.page}
                                        orderNotes={orderNotes}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div id="order-notes-table">
                                    <DisplayNotes orderNotes={orderNotes} />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    </div>
);

export default OrderPageCollapse;
