import './index.scss';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/snackbar/dist/mdc.snackbar.css';

import { DialogQueue } from '@rmwc/dialog';
import { Drawer, DrawerAppContent, DrawerContent, DrawerHeader } from '@rmwc/drawer';
import { List, ListItem, ListItemPrimaryText, ListItemText } from '@rmwc/list';
import { SnackbarQueue } from '@rmwc/snackbar';
import {
    TopAppBar,
    TopAppBarFixedAdjust,
    TopAppBarNavigationIcon,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle,
} from '@rmwc/top-app-bar';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ProtectedRoute from '../../auth/components/ProtectedRoute';
import { menuItems } from '../../data';
import history from '../../history';
import { layoutActions } from '../../redux/actions/layouts';
import { createLoadingSelector } from '../../redux/selectors/loader';
import Loader from './Loader';
import messages from './messages';

const AdminLayout = props => {
    const { location, drawerOpen, currentUser, title, actionItems, toggleMenu, loading } = props;

    const allowedMenus =
        currentUser !== null
            ? menuItems.filter(({ title: menuTitle, permission }) => {
                  let result = true;

                  if (permission) {
                      result =
                          currentUser.permissions.includes(permission) || menuTitle === 'Logout';
                  }
                  return result;
              })
            : [];
    const selectedIndex = allowedMenus.findIndex(menu => menu.url === location.pathname);

    return true === loading ? (
        <Loader />
    ) : (
        <div className="drawer-container">
            <Drawer dismissible open={drawerOpen}>
                <DrawerHeader>
                    <Link to="/">
                        <img src="/images/jd-group.png" alt="JD Sports Logo" />
                    </Link>
                </DrawerHeader>

                <DrawerContent>
                    <List className="sidenav-list" onAction={handleListSelect}>
                        {allowedMenus.map((menu, index) => (
                            <ListItem
                                key={index}
                                className={selectedIndex === index && 'active-item'}
                            >
                                <ListItemText>
                                    <ListItemPrimaryText disabled={false}>
                                        {menu.title}
                                    </ListItemPrimaryText>
                                </ListItemText>
                                {selectedIndex === index ? (
                                    menu.menuIcon && (
                                        <img
                                            src={menu.menuIcon}
                                            alt={`menu-icon-${index}`}
                                            className="menu-icon"
                                        />
                                    )
                                ) : menu.title === 'Logout' ? null : (
                                    <div>&#62;</div>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </DrawerContent>
            </Drawer>
            <DrawerAppContent className="drawer-app-content">
                <TopAppBar title={title} className={'nav-top-bar'}>
                    <TopAppBarRow>
                        <TopAppBarSection alignStart>
                            <TopAppBarNavigationIcon
                                icon="menu"
                                className="top-menu"
                                onClick={() => toggleMenu()}
                            />
                        </TopAppBarSection>
                        <TopAppBarSection>
                            <TopAppBarTitle>{title}</TopAppBarTitle>
                        </TopAppBarSection>
                        <TopAppBarSection alignEnd>{actionItems}</TopAppBarSection>
                    </TopAppBarRow>
                </TopAppBar>
                <TopAppBarFixedAdjust>
                    <ProtectedRoute {...props} />
                </TopAppBarFixedAdjust>
            </DrawerAppContent>

            <div id="statusModal" />

            <SnackbarQueue messages={messages.snackbarQueue.messages} />
            <DialogQueue dialogs={messages.dialogQueue.dialogs} />
        </div>
    );

    function handleListSelect(event) {
        const { index } = event.detail;
        if ('Logout' === allowedMenus[index].title) {
            handleLogoutRedirect(allowedMenus[index].url);
        } else {
            history.push(allowedMenus[index].url);
        }
        toggleMenu(false);
    }

    function handleLogoutRedirect(url) {
        history.push({
            pathname: url,
            state: { logout: true },
        });
    }
};

function mapStateToProps(state) {
    const loadingSelector = createLoadingSelector(['GET_USER']);
    return {
        drawerOpen: state.layouts.drawerOpen,
        currentUser: state.auth.currentUser,
        loading: loadingSelector(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        toggleMenu: open => dispatch(layoutActions.toggleMenu(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
