export const layoutConstants = {
    TOGGLE_DRAWER: '(Layout) TOGGLE_DRAWER',
    DISPLAY_ERROR_DIALOG: '(Layout) DISPLAY_ERROR_DIALOG',
    DISPLAY_SNACKBAR: '(Layout) DISPLAY_SNACKBAR',
};

export const errorConstants = {
    PUSH: '(Error) PUSH',
    CLEAR: '(Error) CLEAR',
};

export const authConstants = {
    LOAD_SIGNED_IN_USER: '(Auth) LOAD_SIGNED_IN_USER',
    SET_USER_NOT_SIGNED_IN_SUCCESS: '(Auth) SET_USER_NOT_SIGNED_IN_SUCCESS',
    SET_USER_REQUIRES_NEW_PASSWORD: '(Auth) SET_USER_REQUIRES_NEW_PASSWORD',
    SET_USER_FORGOT_PASSWORD: '(Auth) SET_USER_FORGOT_PASSWORD',
    SET_CURRENT_USER_SIGNED_IN_SUCCESS: '(Auth) SET_CURRENT_USER_SIGNED_IN_SUCCESS',
    GET_CURRENT_USER_REQUEST: '(Auth) GET_CURRENT_USER_REQUEST',
    GET_CURRENT_USER_SUCCESS: '(Auth) GET_CURRENT_USER_SUCCESS',
    GET_CURRENT_USER_FAIL: '(Auth) GET_CURRENT_USER_FAIL',
    DELETE_CURRENT_USER_REQUEST: '(Auth) DELETE_CURRENT_USER_REQUEST',
    DELETE_CURRENT_USER_SUCCESS: '(Auth) DELETE_CURRENT_USER_SUCCESS',
    DELETE_CURRENT_USER_FAIL: '(Auth) DELETE_CURRENT_USER_FAIL',
};

export const utilConstants = {
    GET_CHANNELS_REQUEST: '(Util) GET_CHANNELS_REQUEST',
    GET_CHANNELS_SUCCESS: '(Util) GET_CHANNELS_SUCCESS',
    GET_CHANNELS_FAIL: '(Util) GET_CHANNELS_FAIL',
    GET_FASCIAS_REQUEST: '(Util) GET_FASCIAS_REQUEST',
    GET_FASCIAS_SUCCESS: '(Util) GET_FASCIAS_SUCCESS',
    GET_FASCIAS_FAIL: '(Util) GET_FASCIAS_FAIL',
};

export const ordersConstants = {
    GET_ORDERS_REQUEST: '(Orders) GET_ORDERS_REQUEST',
    GET_ORDERS_SUCCESS: '(Orders) GET_ORDERS_SUCCESS',
    GET_ORDERS_FAIL: '(Orders) GET_ORDERS_FAIL',
    GET_ORDER_REQUEST: '(Orders) GET_ORDER_REQUEST',
    GET_ORDER_SUCCESS: '(Orders) GET_ORDER_SUCCESS',
    GET_ORDER_FAIL: '(Orders) GET_ORDER_FAIL',
    SEARCH_ORDERS_REQUEST: '(Orders) SEARCH_ORDERS_REQUEST',
    SEARCH_ORDERS_SUCCESS: '(Orders) SEARCH_ORDERS_SUCCESS',
    SEARCH_ORDERS_FAIL: '(Orders) SEARCH_ORDERS_FAIL',
    START_REFUND: '(Orders) START_REFUND',
    CANCEL_REFUND: '(Orders) CANCEL_REFUND',
    CHANGE_REFUND_REASON: '(Orders) CHANGE_REFUND_REASON',
    CHANGE_REFUND_ITEM: '(Orders) CHANGE_REFUND_ITEM',
    CANCEL_ORDER_REQUEST: '(Orders) CANCEL_ORDER_REQUEST',
    CANCEL_ORDER_SUCCESS: '(Orders) CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAIL: '(Orders) CANCEL_ORDER_FAIL',
};

export const orderConstants = {
    GET_ORDER_REQUEST: '(Order) GET_ORDER_REQUEST',
    GET_ORDER_SUCCESS: '(Order) GET_ORDER_SUCCESS',
    GET_ORDER_FAIL: '(Order) GET_ORDER_FAIL',
    CHANGE_VENDOR: '(Order) CHANGE_VENDOR',
    SET_AMENDING_ORDER: '(Order) SET_AMENDING_ORDER',
    SET_AMENDING_ORDER_ITEM: '(Order) SET_AMENDING_ORDER_ITEM',
    UPDATE_ORDER_ITEM_AMENDMENT: '(Order) UPDATE_ORDER_ITEM_AMENDMENT',
    PROCESS_ORDER_AMENDMENT_REQUEST: '(Order) PROCESS_ORDER_AMENDMENT_REQUEST',
    PROCESS_ORDER_AMENDMENT_SUCCESS: '(Order) PROCESS_ORDER_AMENDMENT_SUCCESS',
    PROCESS_ORDER_AMENDMENT_FAIL: '(Order) PROCESS_ORDER_AMENDMENT_FAIL',
    CANCEL_ORDER_REQUEST: '(Order) CANCEL_ORDER_REQUEST',
    CANCEL_ORDER_SUCCESS: '(Order) CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAIL: '(Order) CANCEL_ORDER_FAIL',
    ADD_ORDER_NOTE_REQUEST: '(Order) ADD_ORDER_NOTE_REQUEST',
    ADD_ORDER_NOTE_SUCCESS: '(Order) ADD_ORDER_NOTE_SUCCESS',
    ADD_ORDER_NOTE_FAIL: '(Order) ADD_ORDER_NOTE_FAIL',
    REFUND_ORDER_DELIVERY_METHOD_REQUEST: '(Order) REFUND_ORDER_DELIVERY_METHOD_REQUEST',
    REFUND_ORDER_DELIVERY_METHOD_SUCCESS: '(Order) REFUND_ORDER_DELIVERY_METHOD_SUCCESS',
    REFUND_ORDER_DELIVERY_METHOD_FAIL: '(Order) REFUND_ORDER_DELIVERY_METHOD_FAIL',
    GET_ORDER_DELIVERY_OPTION_REQUEST: '(Order) GET_ORDER_DELIVERY_OPTION_REQUEST',
    GET_ORDER_DELIVERY_OPTION_SUCCESS: '(Order) GET_ORDER_DELIVERY_OPTION_SUCCESS',
    GET_ORDER_DELIVERY_OPTION_FAIL: '(Order) GET_ORDER_DELIVERY_OPTION_FAIL',
    ORDER_ACTION_REQUEST: '(Order) ORDER_ACTION_REQUEST',
    ORDER_ACTION_SUCCESS: '(Order) ORDER_ACTION_SUCCESS',
    ORDER_ACTION_FAIL: '(Order) ORDER_ACTION_FAIL',
};

export const usersConstants = {
    UPDATE_STATE: '(Users) UPDATE_STATE',
    RESET_USER: '(Users) RESET_USER',
    LIST_REQUEST: '(Users) LIST_REQUEST',
    LIST_SUCCESS: '(Users) LIST_SUCCESS',
    LIST_FAIL: '(Users) LIST_FAIL',
    CREATE_REQUEST: '(Users) CREATE_REQUEST',
    CREATE_SUCCESS: '(Users) CREATE_SUCCESS',
    CREATE_FAIL: '(Users) CREATE_FAIL',
    GET_REQUEST: '(Users) GET_REQUEST',
    GET_SUCCESS: '(Users) GET_SUCCESS',
    GET_FAIL: '(Users) GET_FAIL',
    SAVE_REQUEST: '(Users) SAVE_REQUEST',
    SAVE_SUCCESS: '(Users) SAVE_SUCCESS',
    SAVE_FAIL: '(Users) SAVE_FAIL',
    DELETE_REQUEST: '(Users) DELETE_REQUEST',
    DELETE_SUCCESS: '(Users) DELETE_SUCCESS',
    DELETE_FAIL: '(Users) DELETE_FAIL',
    TOGGLE_ENABLED_REQUEST: '(Users) TOGGLE_ENABLED_REQUEST',
    TOGGLE_ENABLED_SUCCESS: '(Users) TOGGLE_ENABLED_SUCCESS',
    TOGGLE_ENABLED_FAIL: '(Users) TOGGLE_ENABLED_FAIL',
    RESET_PASSWORD_REQUEST: '(Users) RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: '(Users) RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAIL: '(Users) RESET_PASSWORD_FAIL',
};

export const deliveryOptionsConstants = {
    UPDATE_STATE: '(DeliveryOptions) UPDATE_STATE',
    RESET_DELIVERY_OPTION: '(DeliveryOptions) RESET_DELIVERY_OPTION',
    LIST_REQUEST: '(DeliveryOptions) LIST_REQUEST',
    LIST_SUCCESS: '(DeliveryOptions) LIST_SUCCESS',
    LIST_FAIL: '(DeliveryOptions) LIST_FAIL',
    CREATE_REQUEST: '(DeliveryOptions) CREATE_REQUEST',
    CREATE_SUCCESS: '(DeliveryOptions) CREATE_SUCCESS',
    CREATE_FAIL: '(DeliveryOptions) CREATE_FAIL',
    GET_REQUEST: '(DeliveryOptions) GET_REQUEST',
    GET_SUCCESS: '(DeliveryOptions) GET_SUCCESS',
    GET_FAIL: '(DeliveryOptions) GET_FAIL',
    SAVE_REQUEST: '(DeliveryOptions) SAVE_REQUEST',
    SAVE_SUCCESS: '(DeliveryOptions) SAVE_SUCCESS',
    SAVE_FAIL: '(DeliveryOptions) SAVE_FAIL',
    DELETE_REQUEST: '(DeliveryOptions) DELETE_REQUEST',
    DELETE_SUCCESS: '(DeliveryOptions) DELETE_SUCCESS',
    DELETE_FAIL: '(DeliveryOptions) DELETE_FAIL',
    TOGGLE_ACTIVE_REQUEST: '(DeliveryOptions) TOGGLE_ACTIVE_REQUEST',
    TOGGLE_ACTIVE_SUCCESS: '(DeliveryOptions) TOGGLE_ACTIVE_SUCCESS',
    TOGGLE_ACTIVE_FAIL: '(DeliveryOptions) TOGGLE_ENABLED_FAIL',
    RESET_PASSWORD_REQUEST: '(DeliveryOptions) RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: '(DeliveryOptions) RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAIL: '(DeliveryOptions) RESET_PASSWORD_FAIL',
    DUPLICATE_REQUEST: '(DeliveryOptions) DUPLICATE_REQUEST',
    DUPLICATE_SUCCESS: '(DeliveryOptions) DUPLICATE_SUCCESS',
    DUPLICATE_FAIL: '(DeliveryOptions) DUPLICATE_FAIL',
};

export const paymentOptionsConstants = {
    UPDATE_STATE: '(PaymentOptions) UPDATE_STATE',
    RESET_PAYMENT_OPTION: '(PaymentOptions) RESET_PAYMENT_OPTION',
    LIST_REQUEST: '(PaymentOptions) LIST_REQUEST',
    LIST_SUCCESS: '(PaymentOptions) LIST_SUCCESS',
    LIST_FAIL: '(PaymentOptions) LIST_FAIL',
    CREATE_REQUEST: '(PaymentOptions) CREATE_REQUEST',
    CREATE_SUCCESS: '(PaymentOptions) CREATE_SUCCESS',
    CREATE_FAIL: '(PaymentOptions) CREATE_FAIL',
    GET_REQUEST: '(PaymentOptions) GET_REQUEST',
    GET_SUCCESS: '(PaymentOptions) GET_SUCCESS',
    GET_FAIL: '(PaymentOptions) GET_FAIL',
    SAVE_REQUEST: '(PaymentOptions) SAVE_REQUEST',
    SAVE_SUCCESS: '(PaymentOptions) SAVE_SUCCESS',
    SAVE_FAIL: '(PaymentOptions) SAVE_FAIL',
    DELETE_REQUEST: '(PaymentOptions) DELETE_REQUEST',
    DELETE_SUCCESS: '(PaymentOptions) DELETE_SUCCESS',
    DELETE_FAIL: '(PaymentOptions) DELETE_FAIL',
    TOGGLE_ACTIVE_REQUEST: '(PaymentOptions) TOGGLE_ACTIVE_REQUEST',
    TOGGLE_ACTIVE_SUCCESS: '(PaymentOptions) TOGGLE_ACTIVE_SUCCESS',
    TOGGLE_ACTIVE_FAIL: '(PaymentOptions) TOGGLE_ENABLED_FAIL',
    RESET_PASSWORD_REQUEST: '(PaymentOptions) RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: '(PaymentOptions) RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAIL: '(PaymentOptions) RESET_PASSWORD_FAIL',
    DUPLICATE_REQUEST: '(PaymentOptions) DUPLICATE_REQUEST',
    DUPLICATE_SUCCESS: '(PaymentOptions) DUPLICATE_SUCCESS',
    DUPLICATE_FAIL: '(PaymentOptions) DUPLICATE_FAIL',
};
