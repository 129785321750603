import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    headerButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));
