export const filterItems = (orderItems, filterText) => {
    let itemCount = 0;

    switch (filterText) {
        case 'return':
            for (let itemKey in orderItems) {
                for (let itemIndex in orderItems[itemKey].items) {
                    let { lines } = orderItems[itemKey].items[itemIndex];

                    if (typeof lines === 'undefined') {
                        continue;
                    }

                    itemCount += getLineItemActionCount(lines, 'return');
                }
            }
            break;
        case 'refund':
        case 'refunded':
            for (let itemKey in orderItems) {
                for (let itemIndex in orderItems[itemKey].items) {
                    let { lines } = orderItems[itemKey].items[itemIndex];

                    if (typeof lines === 'undefined') {
                        continue;
                    }

                    itemCount += getLineItemActionCount(lines, 'refund');
                }
            }
            break;
        default:
            break;
    }
    return itemCount;
};

const getLineItemActionCount = (lines, action) => {
    let result = 0;

    Object.keys(lines).forEach(lineNumber => {
        const amendItems = lines[lineNumber].amends || [];
        amendItems.forEach(amendItem => {
            if (amendItem && amendItem.type === action) {
                result++;
            }
        });
    });

    return result;
};
