import './IssueFullRefund.scss';

import React from 'react';
import Modal from 'react-responsive-modal';

import reasonCodes from '../../../data/reasonCodes';

const IssueFullRefund = ({
    refundModalCleanup,
    refundModalTypes,
    refundModalType,
    setRefundReason,
    issueFullRefund,
    setModalOpen,
    refundReason,
    modalOpen,
    submitted,
}) => {
    const submitClass =
        refundReason.length === 0
            ? 'btn btn-block btn-success disabled'
            : 'btn btn-block btn-success';

    const submitDisabled = !refundReason.length;
    const inputDisabled = submitted ? true : false;

    let modalType = '';

    switch (refundModalType) {
        case refundModalTypes.REFUND:
            modalType = 'Refund';
            break;
        case refundModalTypes.REFUNDRETURN:
            modalType = 'Refund & Return';
            break;
        default:
            break;
    }

    return (
        <Modal
            open={modalOpen}
            onClose={() => {
                setModalOpen(false);
                refundModalCleanup();
            }}
            classNames={{
                modal: 'refund-products-search-modal',
                closeButton: 'refund-products-search-modal-close',
            }}
            center
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="lead header-text">{`ISSUE FULL ${modalType.toUpperCase()}`}</h3>
                        <hr />
                        <div className="form-group">
                            <select
                                name="refund_reason"
                                className="form-control"
                                onChange={e => setRefundReason(e)}
                                disabled={inputDisabled}
                            >
                                <option value="">{`Reason for ${modalType.toLowerCase()}`}</option>
                                {reasonCodes.map(({ code, description }) => {
                                    if (code === '06') {
                                        return null;
                                    }

                                    return <option key={code}>{description}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button
                            onClick={e => issueFullRefund(e)}
                            style={{
                                backgroundColor: '#4bd4b0',
                                border: 'none',
                            }}
                            disabled={submitDisabled}
                            className={submitClass}
                        >
                            Process
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default IssueFullRefund;
