import MaterialTable from '@material-table/core';
import React from 'react';

const PaymentOptionsTable = ({ currentUser, paymentOptions, history }) => {
    const actions = [];
    if (currentUser.permissions.includes('paymentoptions--update')) {
        actions.push({
            icon: 'edit',
            tooltip: 'Edit Payment Option',
            onClick: (e, paymentOption) => history.replace(`/payment/${paymentOption.paymentType}`),
        });
    }

    const columns = [
        {
            field: 'name',
            title: 'NAME',
            type: 'string',
            filtering: true,
        },
        {
            field: 'paymentType',
            title: 'PAYMENT TYPE',
            type: 'string',
            filtering: true,
        },
        {
            field: 'isAnatwine',
            title: 'AVAILABLE FOR ANATWINE',
            type: 'boolean',
            align: 'center',
            render: ({ isAnatwine }) => (isAnatwine ? 'YES' : 'NO'),
        },
        {
            field: 'isEnabled',
            title: 'STATUS',
            type: 'boolean',
            align: 'center',
            render: ({ isEnabled }) => (isEnabled ? 'ON' : 'OFF'),
        },
        {
            field: 'updated',
            title: 'LAST UPDATED',
            type: 'datetime',
            render: ({ updated }) => new Date(updated).toLocaleString(),
        },
    ];

    return (
        <div className="table-wrapper">
            <MaterialTable
                title=""
                data={paymentOptions}
                actions={actions}
                style={{ boxShadow: 'none' }}
                columns={columns}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    maxColumnSort: 1,
                    idSynonym: 'paymentType',
                    headerStyle: {
                        fontWeight: 'bold',
                        color: '#3e4555',
                        fontSize: 14,
                        borderBottom: '1px solid #e7e8e9',
                        position: 'unset',
                    },
                }}
            />
        </div>
    );
};

export default PaymentOptionsTable;
