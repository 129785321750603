import { usersConstants } from '../actions/types';

const initialState = {
    users: [],
    user: {
        firstName: '',
        lastName: '',
        email: '',
        permissions: [],
    },
};

export function usersReducer(state = initialState, action) {
    switch (action.type) {
        case usersConstants.RESET_USER:
            return {
                user: initialState.user,
            };

        case usersConstants.LIST_SUCCESS:
            return {
                ...state,
                users: action.users,
            };

        case usersConstants.GET_SUCCESS:
            return {
                ...state,
                user: action.user,
            };

        case usersConstants.UPDATE_STATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.name]: action.value,
                },
            };

        case usersConstants.TOGGLE_ENABLED_SUCCESS: {
            const users = [...state.users];
            users.map(user => {
                if (user.ID === action.user.ID) {
                    user.enabled = action.enabled;
                }
                return user;
            });
            return {
                ...state,
                users: users,
            };
        }
        default: {
            return state;
        }
    }
}
