import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@rmwc/button';
import { TextField, TextFieldHelperText as HelperText } from '@rmwc/textfield';
import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { authActions } from '../../redux/actions/auth';
import authInstance from '../../services/Auth/authorization';

const Login = props => {
    const [loginDetails, setLoginDetails] = useState({
        username: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState(false);

    const handleChange = event => {
        const { name, value } = event.target;
        setLoginDetails({ ...loginDetails, [name]: value });
    };

    const signIn = () => {
        let { username, password } = loginDetails;
        username = username.toLowerCase();

        authInstance
            .login(username, password)
            .then(async user => {
                if (user.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                    props.setUserRequiresNewPassword(user);
                } else {
                    props.setStateSignedIn(user);
                }
            })
            .catch(err => {
                setErrorMessage(err.message);
            });
    };

    const handleSubmit = event => {
        event.preventDefault();
        signIn();
    };

    const handleForgotPassword = () => {
        props.push('/forgotPassword');
    };

    return (
        <>
            {/* <p>New Login Component</p> */}
            <div>
                {errorMessage && <MuiAlert severity="error">{errorMessage}</MuiAlert>}

                <div className="login-wrapper">
                    <img src="/images/logo-large-en.png" alt="SmartAgent" />
                    <div className="login-paper">
                        <form className="login-form" onSubmit={handleSubmit}>
                            <TextField
                                label="Email"
                                helpText={<HelperText>Enter your email</HelperText>}
                                outlined={true}
                                name="username"
                                id="username"
                                autoComplete="username"
                                onChange={handleChange}
                                value={loginDetails.username}
                            />
                            <TextField
                                label="Password"
                                helpText={<HelperText>Enter your password</HelperText>}
                                outlined={true}
                                name="password"
                                id="password"
                                type="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                value={loginDetails.password}
                            />
                            <Button
                                type="submit"
                                raised
                                className="login-button mdc-theme--primary-bg mdc-theme--on-primary"
                            >
                                Sign in
                            </Button>
                        </form>
                        <div className="forgot-wrapper">
                            Forgot your password?
                            <button className="link-button" onClick={handleForgotPassword}>
                                Reset password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        push: route => dispatch(push(route)),
        setStateSignedIn: user => dispatch(authActions.setStateSignedIn(user)),
        setUserForgotPassword: () => dispatch(authActions.setUserForgotPassword()),
        setUserRequiresNewPassword: (user, history) =>
            dispatch(authActions.setUserRequiresNewPassword(user, history)),
    };
};

export default connect(null, mapDispatchToProps)(Login);
