import './SearchPage.scss';

import React, { Component } from 'react';
import { Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';

import whiteSearchIcon from '../../../icons/WhiteSearch.png';

class SearchPage extends Component {
    state = {
        searchClicked: false,
        search: '',
    };

    componentDidMount() {
        const { location } = this.props;

        if (typeof location.state !== 'undefined' && typeof location.state.search !== 'undefined') {
            this.setSearchState(location.state.search);
        }

        document.addEventListener('keydown', this.searchFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.searchFunction, false);
    }

    searchFunction = event => {
        if (event.keyCode === 13 && this.state.search) {
            this.onSearch();
        }
    };

    componentDidUpdate() {
        const { searchClicked, search } = this.state;
        const { push } = this.props.history;
        const { location } = this.props;

        if (searchClicked) {
            push({
                pathname: '/orders',
                state: {
                    search,
                    prevpath: location.pathname,
                },
            });
        }
    }

    setSearchState = search => this.setState({ search });

    setSearchClickedState = searchClicked => this.setState({ searchClicked });

    onSearchChange = e => {
        const { value } = e.target;
        // Only allow alphanumeric characters, hypen and single quotes
        const isValid = /^[a-zA-Z0-9'-]+$/.test(value);

        if (!isValid && value.length > 1) {
            return;
        } else {
            this.setSearchState(value);
        }
    };

    onSearch = () => {
        this.setSearchClickedState(true);
    };

    render = () => {
        const { search } = this.state;

        return (
            <Col className="search-page" md={8}>
                <div className="container">
                    <Col md={10} mdOffset={1}>
                        <h3 className="search-header">Find an Order</h3>

                        <div className="input-group">
                            <div className="input-group-prepend search-fields-container">
                                <div className="search-icon-container">
                                    <img
                                        src={whiteSearchIcon}
                                        className="search-icon-image"
                                        alt=""
                                    />
                                </div>
                                <input
                                    type="text"
                                    className="search-page-order-number"
                                    name="search"
                                    value={search}
                                    onChange={e => this.onSearchChange(e)}
                                    placeholder="Search"
                                />
                            </div>
                            <button
                                className="btn btn-primary search-submit"
                                onClick={e => this.onSearch(e)}
                            >
                                Search
                            </button>
                        </div>
                    </Col>
                </div>
            </Col>
        );
    };
}

export default withRouter(SearchPage);
